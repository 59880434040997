import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { EntityType, LineItemType, Locale, MarketCode, TipScheme } from '../../../../types/__generated-graphQL__';
import { ReduxExtra } from '../../../../types/redux';
import APP_SETTINGS from '../../../config';
import { LOCALES } from '../../config/locales';
import { PREFERENCES_SERVICE } from '../../config/preferences';
import { useAppDispatch } from '../../hooks/reduxHooks';
import { mapTipSchemeToLinkServiceFormat } from '../../utils/utilityMapperFunctions';
import { useFetchCheckoutQuery } from '../checkoutApiSlice/queryCheckoutApi';
import {
  getGQLCharityName,
  getGQLConsentLocationId,
  getGQLPageOwnerOrCharityName,
  getGQLRef,
} from '../checkoutApiSlice/selectors/complex.selectors';
import {
  getGQLCauseTipScheme,
  getGQLMarketCode,
  getGQLOrderType,
} from '../checkoutApiSlice/selectors/lineItem.selectors';
import { getGQLUserLocale } from '../checkoutApiSlice/selectors/root.selectors';

type PreferenceServiceConsent = {
  type: string;
  id: number;
  text: string;
  entityType: EntityType;
};

export type ResponseConsent = {
  consentStatementId?: number;
  entityType: EntityType;
  consentStatementText?: string;
  consentGiven?: boolean | null;
  directMarketingType: boolean;
  hidden: boolean;
};

type FetchPreferencesArgs = {
  userLocale: Locale;
  collectionLocationId: number;
  orderType: LineItemType;
  causeSourceRef: string;
  causeTipScheme: string;
  marketCode: MarketCode;
  donationTarget: string;
  causeName: string;
};

type FetchPreferencesResponse = {
  collectionLocationId?: number;
  isConsentRequired?: boolean;
  donationCauseSourceRef?: string;
  consents: ResponseConsent[];
};

export const preferencesApiSlice = createApi({
  reducerPath: 'preferences',
  tagTypes: ['Preferences'],
  baseQuery: fetchBaseQuery({
    baseUrl: APP_SETTINGS.PREFERENCE_SERVICE_CONSENT_URL,
    prepareHeaders: (headers, { extra }) => {
      headers.set('Content-Type', 'application/json');
      headers.set('Accept', 'application/json');

      const accessToken = (extra as ReduxExtra)?.auth?.getParsedAuthCookie()?.accessToken;
      if (accessToken) {
        headers.set('Authorization', `Bearer ${accessToken}`);
      }
      return headers;
    },
  }),
  endpoints: builder => ({
    fetchPreferences: builder.query<FetchPreferencesResponse, FetchPreferencesArgs>({
      providesTags: ['Preferences'],
      query(args) {
        const { userLocale, collectionLocationId, orderType, causeSourceRef, causeTipScheme } = args;
        const locale = userLocale && LOCALES[userLocale];
        const userCountryCode = !locale && userLocale ? userLocale : 'GB';
        const userLocation = `en_${userCountryCode.toUpperCase()}`;

        let url = `/${collectionLocationId}/${userLocation}`;
        switch (orderType) {
          case LineItemType.DonationCharityDirect:
          case LineItemType.DonationCharityFrp:
          case LineItemType.DonationCampaignFrp:
            url += `?DonationCauseSourceRef=${causeSourceRef}&tipScheme=${causeTipScheme}`;
            break;
          case LineItemType.DonationCampaignDirect:
            url += `?CharityId=${causeSourceRef}&tipScheme=${causeTipScheme}`;
            break;
          default:
            break;
        }

        return {
          url,
          mode: 'cors',
          method: 'GET',
        };
      },
      transformResponse: async (
        response: PreferenceServiceConsent[] | Promise<PreferenceServiceConsent[]>,
        meta,
        args: FetchPreferencesArgs,
      ) => {
        const consents = await response;
        const { marketCode, causeName, donationTarget } = args;
        const regionCode = meta?.response?.headers.get('X-Region-Code') ?? '';
        const defaultConsentToFalse = marketCode === MarketCode.Gb;

        const mappedConsents = consents.map(({ id, entityType, type, text }) => {
          const hidden = type === PREFERENCES_SERVICE.DONATION_RELATED_EMAIL;

          const isConsentGiven = (() => {
            if (!hidden && entityType !== EntityType.Charity) {
              return false;
            }
            if (
              regionCode === 'US' &&
              marketCode === MarketCode.Us &&
              type === PREFERENCES_SERVICE.DIRECT_MARKETING_EMAIL &&
              entityType === EntityType.Charity
            ) {
              return true;
            }

            if (
              entityType === EntityType.Charity &&
              type === PREFERENCES_SERVICE.DIRECT_MARKETING_EMAIL &&
              ['AU', 'NZ'].includes(regionCode)
            ) {
              return true;
            }

            if (entityType === EntityType.Charity && defaultConsentToFalse) {
              return false;
            }
            return hidden || null;
          })();

          return {
            consentStatementId: id,
            entityType,
            consentStatementText: text
              .replace(/{charityName}/g, causeName)
              .replace(/{fundraiserName}/g, donationTarget),
            consentGiven: isConsentGiven,
            directMarketingType: type === PREFERENCES_SERVICE.DIRECT_MARKETING_EMAIL,
            hidden,
          };
        });

        return {
          donationCauseSourceRef: args.causeSourceRef,
          collectionLocationId: args.collectionLocationId,
          consents: mappedConsents,
          isConsentRequired: consents.some(
            ({ type, entityType }) =>
              type === PREFERENCES_SERVICE.DIRECT_MARKETING_EMAIL && entityType === EntityType.Charity,
          ),
        };
      },
    }),
  }),
});

const { useFetchPreferencesQuery } = preferencesApiSlice;

export const useFetchPreferences = (skip = false) => {
  const dispatch = useAppDispatch();

  const {
    isError,
    userLocale,
    collectionLocationId,
    orderType,
    refOrLegacyId,
    causeTipScheme,
    marketCode,
    donationTarget,
    causeName,
  } = useFetchCheckoutQuery(undefined, {
    selectFromResult: ({ data, isError }) => {
      return {
        isError: isError,
        userLocale: getGQLUserLocale(data),
        collectionLocationId: getGQLConsentLocationId(data),
        orderType: getGQLOrderType(data),
        refOrLegacyId: getGQLRef(data),
        causeTipScheme: mapTipSchemeToLinkServiceFormat(getGQLCauseTipScheme(data)) ?? TipScheme.Disabled,
        marketCode: getGQLMarketCode(data),
        donationTarget: getGQLPageOwnerOrCharityName(data) ?? '',
        causeName: getGQLCharityName(data) ?? '',
      };
    },
  });

  const preferenceArgs = {
    userLocale: userLocale!,
    collectionLocationId: collectionLocationId!,
    orderType: orderType!,
    causeSourceRef: refOrLegacyId ?? '',
    causeTipScheme: causeTipScheme,
    marketCode: marketCode!,
    donationTarget: donationTarget,
    causeName: causeName,
  };

  const response = useFetchPreferencesQuery(preferenceArgs, { skip: skip || isError });

  const updatePreferences = (newPreference: ResponseConsent) => {
    dispatch(
      preferencesApiSlice.util.updateQueryData('fetchPreferences', preferenceArgs, draftPreferences => {
        draftPreferences.consents = draftPreferences.consents.map(consent => {
          return {
            ...consent,
            consentGiven:
              consent.consentStatementId === newPreference.consentStatementId
                ? newPreference.consentGiven
                : consent.consentGiven,
          };
        });
      }),
    );
  };

  return { ...response, updatePreferences };
};
