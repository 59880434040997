import Cookies from 'js-cookie';

import APP_SETTINGS from '../../config';

export const getCheckoutId = (): string => {
  const checkoutSessionIdFromCookie = Cookies.get(APP_SETTINGS.CHECKOUT_ID_COOKIE_NAME);
  // NOTE: checkoutSessionIdFromQueryString is used only for temporary performance testing in Pingdom, during 2024
  const checkoutSessionIdFromQueryString = new URLSearchParams(window.location.search).get('checkoutSessionId');

  if (!checkoutSessionIdFromCookie && !checkoutSessionIdFromQueryString) {
    const errorMessage = 'Failed to access checkout session ID';

    window.Sentry?.addBreadcrumb({
      category: 'Initialize',
      message: errorMessage,
      level: 'error',
      data: {
        href: window.location.href,
      },
    });

    throw new Error(errorMessage);
  }

  return (checkoutSessionIdFromCookie || checkoutSessionIdFromQueryString)!;
};
