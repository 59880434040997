import {
  VoluntaryContributionConfig,
  VoluntaryContributionConfigValueType,
} from '../../../../types/__generated-graphQL__';

const lowTipUpperLimitInPennies = 999;
export const lowTipAboveUpperLimitInPennies = lowTipUpperLimitInPennies + 1;

export const lowTipDefault: VoluntaryContributionConfig = {
  from: 0,
  to: lowTipUpperLimitInPennies,
  valueType: VoluntaryContributionConfigValueType.Amount,
  values: [100, 200],
  defaultValue: 100,
};
