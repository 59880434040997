import React, { FunctionComponent } from 'react';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMatch } from 'react-router';

import { AcceptedCurrencyCode } from '../../../../../types/__generated-graphQL__';
import { AmountPageFormValues } from '../../../../../types/formValues';
import { sprinkles } from '../../../../styles/sprinkles.css';
import { PAGES } from '../../../config/pages';
import { useFetchCheckoutQuery } from '../../../redux/checkoutApiSlice/queryCheckoutApi';
import { getGQLDisplayGiftAid } from '../../../redux/checkoutApiSlice/selectors/complex.selectors';
import {
  getGQLGiftAidIsClaimed,
  getGQLGiftAidValue,
} from '../../../redux/checkoutApiSlice/selectors/giftAid.selectors';
import {
  getGQLLineItemValueInPounds,
  getGQLSupportsGiftAid,
} from '../../../redux/checkoutApiSlice/selectors/lineItem.selectors';
import { getGQLCurrencyCode } from '../../../redux/checkoutApiSlice/selectors/order.selectors';
import { getGQLSelectedPaymentType } from '../../../redux/checkoutApiSlice/selectors/payment.selectors';
import { formatCurrency } from '../../../utils/value';
import { AccordionPageCurrentStep } from '../AccordionPageCurrentStep';
import { AccordionPageFilledStep } from '../AccordionPageFilledStep';
import { AccordionPageLoadingStep } from '../AccordionPageLoadingStep';
import { AccordionPageNewStep } from '../AccordionPageNewStep';
import { AccordionPagePreviewEmerge } from '../AccordionPagePreviewEmerge';
import { AccordionPageSectionWrapper } from '../AccordionPageSectionWrapper';
import type { PageSectionProps } from './AccordionSection';

const GiftAidStepPreview: FunctionComponent = () => {
  const { t } = useTranslation();

  const { selectedPaymentType, giftAidValue, giftAidIsClaimed, lineItemValue, currency } = useFetchCheckoutQuery(
    undefined,
    {
      selectFromResult: ({ data }) => {
        return {
          selectedPaymentType: getGQLSelectedPaymentType(data),
          giftAidValue: getGQLGiftAidValue(data),
          giftAidIsClaimed: getGQLGiftAidIsClaimed(data),
          lineItemValue: getGQLLineItemValueInPounds(data),
          currency: getGQLCurrencyCode(data),
        };
      },
    },
  );

  if (selectedPaymentType && lineItemValue && currency) {
    return (
      <AccordionPageFilledStep
        title={t('giftaid|title', { ns: 'ab' })}
        routeTo={'GiftAidDeclarationPage'}
        a11yRouteText={t('summary|giftAidEditScreenReader', { ns: 'giftAid' })}
      >
        {giftAidIsClaimed ? (
          <>
            {t('giftaid|claimed', { ns: 'ab', amount: formatCurrency(currency, giftAidValue) })}
            <div className={sprinkles({ color: 'light' })}>{t('giftaid|claimed|nocharge', { ns: 'ab' })}</div>
          </>
        ) : (
          <div className={sprinkles({ color: 'light' })}>{t('giftaid|unclaimed', { ns: 'ab' })}</div>
        )}
      </AccordionPageFilledStep>
    );
  }

  return <AccordionPageNewStep>{t('giftaid|title', { ns: 'ab' })}</AccordionPageNewStep>;
};

const GiftAidPageSection: FunctionComponent<PageSectionProps> = ({ children, currentPageCollapsed, isLoading }) => {
  const isGiftAidDeclarationPage = useMatch(`/:pageType?/:pageSlug?/${PAGES.GiftAidDeclarationPage}`);
  const isGiftAidDetailsPage = useMatch(`/:pageType?/:pageSlug?/${PAGES.GiftAidDetailsPage}`);
  const isCurrentPage = isGiftAidDeclarationPage || isGiftAidDetailsPage;

  const { supportsGiftAid, displayGiftAid } = useFetchCheckoutQuery(undefined, {
    selectFromResult: ({ data }) => {
      return {
        supportsGiftAid: getGQLSupportsGiftAid(data),
        displayGiftAid: getGQLDisplayGiftAid(data),
      };
    },
  });

  const formDonationCurrencyCode = useWatch<AmountPageFormValues, 'donationCurrencyCode'>({
    name: 'donationCurrencyCode',
  });

  if (!supportsGiftAid) return null;

  const isGiftAidSupported = formDonationCurrencyCode
    ? formDonationCurrencyCode === AcceptedCurrencyCode.Gbp
    : displayGiftAid;

  if (!isGiftAidSupported) return null;

  if (isLoading) return <AccordionPageLoadingStep />;

  if (isCurrentPage) {
    return (
      <li>
        <AccordionPageSectionWrapper>
          {currentPageCollapsed && (
            <AccordionPagePreviewEmerge>
              <GiftAidStepPreview />
            </AccordionPagePreviewEmerge>
          )}
          <AccordionPageCurrentStep collapsed={currentPageCollapsed}>{children}</AccordionPageCurrentStep>
        </AccordionPageSectionWrapper>
      </li>
    );
  }

  return (
    <li>
      <GiftAidStepPreview />
    </li>
  );
};

export default GiftAidPageSection;
