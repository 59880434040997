import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import VisuallyHidden from '../../components/VisuallyHidden/VisuallyHidden';
import { useFetchCheckoutQuery } from '../../redux/checkoutApiSlice/queryCheckoutApi';
import { getGQLDirectDebitMandateReference } from '../../redux/checkoutApiSlice/selectors/payment.selectors';
import { getGQLTransactionReference } from '../../redux/checkoutApiSlice/selectors/transaction.selectors';
import { separateCharacters } from '../../utils/separateCharacters';
import * as styles from './DonationReference.css';

type Props = { donationStatus: DonationStatus };

const DonationReference: FunctionComponent<Props> = ({ donationStatus }) => {
  const { directDebitMandateReference, transactionReference } = useFetchCheckoutQuery(undefined, {
    selectFromResult: ({ data }) => {
      return {
        directDebitMandateReference: getGQLDirectDebitMandateReference(data),
        transactionReference: getGQLTransactionReference(data),
      };
    },
  });
  const { t } = useTranslation();

  const isDirectDebit = donationStatus === 'directdebit_succeeded';

  const reference = isDirectDebit ? directDebitMandateReference : `D${transactionReference}`;

  if (!directDebitMandateReference && !transactionReference) return null;

  return (
    <p className={styles.wrapper}>
      <span aria-hidden="true" className={styles.reference} data-qa="donation-reference">
        {t(`donationReferenceLabel`, {
          ns: isDirectDebit ? 'recurring' : 'common',
          reference: reference,
        })}
      </span>
      <VisuallyHidden data-qa="donation-reference-screen-reader">
        {t(`donationReferenceLabel`, {
          ns: isDirectDebit ? 'recurring' : 'common',
          reference: separateCharacters(reference),
        })}
      </VisuallyHidden>
    </p>
  );
};

export default DonationReference;
