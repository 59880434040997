import React, { FunctionComponent } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AmountPageFormValues, TransactionTypes } from '../../../../types/formValues';
import APP_SETTINGS from '../../../config';
import { sprinkles } from '../../../styles/sprinkles.css';
import { FormGroup } from '../../components/FormGroup/FormGroup';
import { RadioButton, RadioButtonGroup } from '../../components/RadioButtonGroup/RadioButtonGroup';
import { useFetchCheckoutQuery } from '../../redux/checkoutApiSlice/queryCheckoutApi';
import {
  getGQLSavedOrDefaultCurrencyCode,
  getGQLSavedOrDefaultDonationValue,
} from '../../redux/checkoutApiSlice/selectors/complex.selectors';
import { getGQLIsCryptoSupported } from '../../redux/checkoutApiSlice/selectors/order.selectors';
import { DEFAULT_FIAT_AMOUNT } from '../Crypto/CryptoWrapper';
import { useIsRecurringPaymentAvailable } from './useRecurringAvailable';

const TransactionTypeTabs: FunctionComponent = () => {
  const { t } = useTranslation();
  const { setValue } = useFormContext<AmountPageFormValues>();
  const transactionType = useWatch<AmountPageFormValues, 'transactionType'>({
    name: 'transactionType',
  });

  const { isRecurringCardAvailable, isDirectDebitAvailable } = useIsRecurringPaymentAvailable();
  const isRecurringAvailable = isRecurringCardAvailable || isDirectDebitAvailable;
  const { isCryptoSupported, savedOrDefaultCurrencyCode, savedOrDefaultDonationValue } = useFetchCheckoutQuery(
    undefined,
    {
      selectFromResult: ({ data }) => {
        return {
          isCryptoSupported: getGQLIsCryptoSupported(data),
          savedOrDefaultCurrencyCode: getGQLSavedOrDefaultCurrencyCode(data),
          savedOrDefaultDonationValue: getGQLSavedOrDefaultDonationValue(data),
        };
      },
    },
  );
  const isCryptoAvailable = APP_SETTINGS.ENABLE_CRYPTO && isCryptoSupported;

  if (!isRecurringAvailable && !isCryptoAvailable) return null;

  const ID_ONE_OFF = 'transactionType-oneOff';
  const ID_MONTHLY = 'transactionType-monthly';
  const ID_CRYPTO = 'transactionType-crypto';

  const setValueAndCurrency = (eventTargetId: string) => {
    switch (eventTargetId) {
      case ID_ONE_OFF:
      case ID_MONTHLY:
        if (transactionType === TransactionTypes.CRYPTO) {
          setValue('donationValue', savedOrDefaultDonationValue ?? '');
          setValue('donationCurrencyCode', savedOrDefaultCurrencyCode);
        }
        break;
      case ID_CRYPTO:
        setValue('donationValue', DEFAULT_FIAT_AMOUNT.toString(), { shouldValidate: true });
        break;
      default:
        break;
    }
  };

  return (
    <>
      <FormGroup
        legendId="transactionTypesLegend"
        legendText={t('amount|transactionTypesLegend', { ns: 'recurring' })}
        groupClassName={sprinkles({ marginTop: { desktop: 'sizeSpacing05', mobile: 'sizeSpacing04' } })}
      >
        <RadioButtonGroup>
          <RadioButton
            name="transactionType"
            id={ID_ONE_OFF}
            key={ID_ONE_OFF}
            value={TransactionTypes.ONE_OFF}
            checked={transactionType === TransactionTypes.ONE_OFF}
            labelDataQa={`${ID_ONE_OFF}-label`}
            shouldRegister
            registerMethods={{
              // Note - registerMethods are set at field level - so only need to be specified once for the transactionType field (and not for each of the RadioButtons)
              onChange: event => {
                setValueAndCurrency(event.target.id);
              },
            }}
          >
            {t('oneOff', { ns: 'recurring' })}
          </RadioButton>
          {isRecurringAvailable && (
            <RadioButton
              name="transactionType"
              id={ID_MONTHLY}
              key={ID_MONTHLY}
              value={TransactionTypes.RECURRING}
              checked={transactionType === TransactionTypes.RECURRING}
              labelDataQa={`${ID_MONTHLY}-label`}
              shouldRegister
            >
              {t('monthly', { ns: 'recurring' })}
            </RadioButton>
          )}
          {isCryptoAvailable && (
            <RadioButton
              name="transactionType"
              id={ID_CRYPTO}
              key={ID_CRYPTO}
              value={TransactionTypes.CRYPTO}
              checked={transactionType === TransactionTypes.CRYPTO}
              labelDataQa={`${ID_CRYPTO}-label`}
              shouldRegister
            >
              {t('cryptoTabLabel', { ns: 'crypto' })}
            </RadioButton>
          )}
        </RadioButtonGroup>
      </FormGroup>
    </>
  );
};

export default TransactionTypeTabs;
