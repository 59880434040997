import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { ThankYouMessageType } from '../../../../types/__generated-graphQL__';
import { sprinkles } from '../../../styles/sprinkles.css';
import { Image } from '../../components/Image/Image';
import { useFetchPageQuery } from '../../redux/additionalPageDataApiSlice/additionalPageDataApiSlice';
import { getGQLAdditionalPageDataParentCampaignThankYouMessage } from '../../redux/additionalPageDataApiSlice/selectors/parents.selectors';
import { useFetchCheckoutQuery } from '../../redux/checkoutApiSlice/queryCheckoutApi';
import { getGQLAdditionalDataCause } from '../../redux/checkoutApiSlice/selectors/additionalData.selectors';
import { getGQLSourceUrl } from '../../redux/checkoutApiSlice/selectors/lineItem.selectors';
import buildAvatarUrl from '../../utils/buildAvatarUrl';
import { isCharityBeneficiary } from '../../utils/typeGuards';
import * as styles from './ThankyouMessage.css';

const ThankyouMessage: FunctionComponent = () => {
  const { additionalDataCauseThankyouMessage, additionalDataCauseImage, sourceUrl } = useFetchCheckoutQuery(undefined, {
    selectFromResult: ({ data }) => {
      return {
        additionalDataCauseThankyouMessage: getGQLAdditionalDataCause(data)?.thankYouMessage,
        additionalDataCauseImage: getGQLAdditionalDataCause(data)?.image,
        sourceUrl: getGQLSourceUrl(data),
      };
    },
  });
  const { data: additionalPageData, isFetching: isFetchingThankyouMessages } = useFetchPageQuery();
  const { t } = useTranslation();

  if (isFetchingThankyouMessages) {
    return null;
  }

  const campaignThankyouMessage = additionalPageData?.fundraisingSettings?.thankYouMessages.filter(
    message => message?.type === ThankYouMessageType.Donation,
  )[0]?.message;

  const campaignFrpThankyouMessage = getGQLAdditionalPageDataParentCampaignThankYouMessage(additionalPageData);
  const beneficiary = additionalPageData?.relationships?.beneficiaries?.nodes?.[0];
  const beneficiaryThankyouMessage = isCharityBeneficiary(beneficiary) ? beneficiary?.thankYouMessage : null;

  const thankyouMessage =
    campaignThankyouMessage ??
    campaignFrpThankyouMessage ??
    beneficiaryThankyouMessage ??
    additionalDataCauseThankyouMessage;

  if (!thankyouMessage) {
    return null;
  }

  return (
    <section className={styles.wrapper} data-qa="personalised-message">
      <div className={styles.message}>
        <p data-qa="personalised-message-text">{thankyouMessage}</p>
        {sourceUrl && (
          <p className={sprinkles({ marginTop: 'sizeSpacing04' })}>
            <a href={sourceUrl} target="_parent">
              {t('findMore')}
            </a>
          </p>
        )}
      </div>
      {additionalDataCauseImage && (
        <Image
          data-qa="personalised-message-image"
          className={styles.logo}
          src={buildAvatarUrl(additionalDataCauseImage)}
        />
      )}
    </section>
  );
};

export default ThankyouMessage;
