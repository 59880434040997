import { Auth } from '@justgiving/auth';
import type { BaseQueryFn } from '@reduxjs/toolkit/query';
import type { DocumentNode } from 'graphql';
import { ClientError, GraphQLClient } from 'graphql-request';
import { Kind } from 'graphql/language';

import { ReduxExtra } from '../../../types/redux';
import APP_SETTINGS from '../../config';
import { httpErrorMessage } from '../config/httpErrorMessage';
import { getSessionCheckoutId } from '../redux/session/session.selectors';
import { filterGqlInputForLogging } from './filterGqlInputForLogging';
import { getAuthHeader } from './getAuthHeader';
import { captureSentryException } from './logError';
import { isGqlDocumentKind } from './typeGuards';

// TODO Currently having to duplicate this because we are not using fetch with retry anymore
const createGraphQlClient = (auth: Auth): GraphQLClient => {
  const authHeader = getAuthHeader(auth);

  const headers = authHeader
    ? {
        Authorization: authHeader,
      }
    : undefined;

  return new GraphQLClient(APP_SETTINGS.GRAPHQL_BASE_URL, {
    credentials: 'include',
    mode: 'cors',
    headers: {
      ...headers,
      'apollographql-client-name': 'GG.FrontEnd.App.DonationCollection',
    },
  });
};

export const graphqlRequestBaseQuery: BaseQueryFn<
  { document: string | DocumentNode | undefined; variables?: any },
  any,
  {
    message: string;
    status: number;
    stack?: string;
  },
  Partial<Pick<ClientError, 'request' | 'response'>> & { useCheckoutId?: boolean }
> = async ({ document, variables }, { signal, extra, getState }, extraOptions) => {
  if (!document) {
    return {
      error: {
        message: 'No document provided for query',
        status: 400,
      },
    };
  }

  const auth = (extra as ReduxExtra).auth;
  const client = createGraphQlClient(auth);
  const checkoutId = getSessionCheckoutId(getState() as State);

  try {
    if (auth.isUserLoggedIn() && !auth.isGuest()) {
      await auth.refreshAccessTokenIfExpired();
    }

    if (extraOptions?.useCheckoutId) {
      variables.input = variables.input || {};
      variables.input.checkoutId = checkoutId;
    }

    try {
      if (document && typeof document !== 'string') {
        if (isGqlDocumentKind(document.definitions[0], Kind.OPERATION_DEFINITION)) {
          const documentDefinition = document.definitions[0];

          window.Sentry?.addBreadcrumb({
            category: 'GraphQL',
            message: `${documentDefinition.operation} - ${documentDefinition?.name?.value}`,
            level: 'info',
            data: filterGqlInputForLogging(variables),
          });
        }
      }
    } catch (e) {
      // Do nothing
    }

    // TODO Remove this once graphql-request version 5.1.x is out containing updates to the latest version of typescript that correctly types AbortSignal
    // @ts-ignore
    const response = await client.request({
      document,
      variables,
      signal,
    });

    return {
      data: response,
      meta: {},
    };
  } catch (error) {
    captureSentryException(error.response, checkoutId);
    const exception = error.response?.errors?.[0]?.extensions?.exception;

    // If data is returned by GQL, it should always have a child object which is in the name of the query/mutation.
    // If that child object is truthy, assume it contains sufficient to render a usable app. Log error and return data.
    const data = error?.response?.data;
    if (data) {
      const dataKeys = Object.keys(data);
      if (dataKeys.length > 0 && data[dataKeys[0]]) {
        return {
          data,
          meta: {},
        };
      }
    }

    if (exception?.responseBody?.includes(httpErrorMessage.UNAUTHORIZED_RECOVERABLE)) {
      return {
        error: {
          message: httpErrorMessage.UNAUTHORIZED_RECOVERABLE,
          status: 401,
          data: exception,
        },
      };
    }

    if (exception?.responseBody?.includes(httpErrorMessage.UNAUTHORIZED_NOT_RECOVERABLE)) {
      return {
        error: {
          message: httpErrorMessage.UNAUTHORIZED_NOT_RECOVERABLE,
          status: 401,
          data: exception,
        },
      };
    }

    return {
      error: {
        message: error.message,
        status: exception?.responseStatus ?? 400,
        data: exception,
      },
    };
  }
};
