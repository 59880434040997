import { useDecision } from '@optimizely/react-sdk';
import bankersRounding from 'bankers-rounding';

import { PaymentType } from '../../../../types/__generated-graphQL__';
import { getAbTestKey } from '../../config/abTests';
import { useAppSelector } from '../../hooks/reduxHooks';
import { useFetchCheckoutQuery } from '../../redux/checkoutApiSlice/queryCheckoutApi';
import { getGQLLineItemValueInPounds } from '../../redux/checkoutApiSlice/selectors/lineItem.selectors';
import { getGQLCurrencyCode } from '../../redux/checkoutApiSlice/selectors/order.selectors';
import { getGQLSelectedPaymentType } from '../../redux/checkoutApiSlice/selectors/payment.selectors';
import { getTipValues, getTipValueType } from '../../redux/tip/tip.selectors';
import { formatCurrency } from '../../utils/value';
import { LabelAndValue } from './useInitTipjar';

const useGetTipOptions = () => {
  const { donationCurrencyCode, donationValue, paymentType } = useFetchCheckoutQuery(undefined, {
    selectFromResult: ({ data }) => {
      return {
        donationCurrencyCode: getGQLCurrencyCode(data),
        donationValue: getGQLLineItemValueInPounds(data),
        paymentType: getGQLSelectedPaymentType(data),
      };
    },
  });

  const tipValues = useAppSelector(getTipValues);
  const valueType = useAppSelector(getTipValueType);

  const donationAmount = Number(donationValue);

  const [{ enabled: isSwapValuesEnabled }] = useDecision(
    getAbTestKey({ abTest: 'TIP_JAR_SWAP_VALUES', shouldEnrol: donationAmount >= 200 }),
  );

  let tipOptions: LabelAndValue[] = (tipValues ?? []).map((value: number) => {
    let label = '';
    if (valueType === 'Amount') {
      label = formatCurrency(donationCurrencyCode, value, false);
    } else if (valueType === 'Percentage') {
      if (paymentType === PaymentType.Crypto) {
        label = `${value}%`;
      } else {
        label = isSwapValuesEnabled
          ? `${formatCurrency(donationCurrencyCode, bankersRounding(donationAmount * (value / 100), 2))} (${value}%)`
          : `${value}% (${formatCurrency(donationCurrencyCode, bankersRounding(donationAmount * (value / 100), 2))})`;
      }
    }
    return { label, value } as LabelAndValue;
  });

  return tipOptions;
};

export default useGetTipOptions;
