import React, { FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import classNames from 'classnames';

import { sprinkles } from '../../../styles/sprinkles.css';
import { FormGroup } from '../../components/FormGroup/FormGroup';
import VisuallyHidden from '../../components/VisuallyHidden/VisuallyHidden';
import * as visuallyHiddenStyles from '../../components/VisuallyHidden/VisuallyHidden.css';
import ButtonTextLink from '../../components/button/ButtonTextLink';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { getTipInputMode, getTipSelectionValue, getTipValueType } from '../../redux/tip/tip.selectors';
import { openTipModal, setTipSelectionValue } from '../../redux/tip/tip.slice';
import * as styles from './TipJarRadioGroup.css';
import useGetTipOptions from './useGetTipOptions';
import useSetTipAmount from './useSetTipAmount';

const TipJarRadioGroup: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { setTipAmount } = useSetTipAmount();

  const tipSelectionValue = useAppSelector(getTipSelectionValue);
  const tipInputMode = useAppSelector(getTipInputMode);
  const tipOptions = useGetTipOptions();
  const tipValueType = useAppSelector(getTipValueType);

  useEffect(() => {
    if (tipInputMode !== 'Other') {
      setTipAmount({ valueType: tipValueType, value: tipSelectionValue! });
    }
  }, [tipSelectionValue, setTipAmount, tipValueType, tipInputMode]);

  const getButtons = () => {
    return tipOptions.map(({ label, value }) => {
      if (label === 'Other') return null;
      const checked = tipSelectionValue === value;
      const labelParts = label.replace('(', '').replace(')', '').split(' ');
      return (
        <label className={classNames(styles.item, { checked: checked })} key={`tipjar${value}`}>
          <input
            type="radio"
            name="tipjar-value"
            value={value}
            checked={checked}
            onChange={() => {
              dispatch(setTipSelectionValue({ value }));
            }}
            className={visuallyHiddenStyles.visuallyHidden}
          />
          <span className={styles.percentage} aria-hidden>
            {labelParts[0]}
          </span>
          {labelParts[1] && (
            <span className={styles.value} aria-hidden>
              {labelParts[1]}
            </span>
          )}
          <VisuallyHidden>{label}</VisuallyHidden>
        </label>
      );
    });
  };

  return (
    <>
      <FormGroup
        legendText={t('pleaseSelectATip', { ns: 'tipJar' })}
        legendId="tipJarRadios"
        groupClassName={styles.wrapper}
      >
        {getButtons()}
      </FormGroup>
      <div className={sprinkles({ textAlign: 'center', marginTop: 'sizeSpacing04' })}>
        <ButtonTextLink
          alwaysUnderline={false}
          onClick={() => {
            dispatch(openTipModal());
          }}
        >
          {t('enterCustomAmount', { ns: 'tipJar' })}
        </ButtonTextLink>
      </div>
    </>
  );
};

export default TipJarRadioGroup;
