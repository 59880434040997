import { useContext } from 'react';

import { AuthContext } from '@justgiving/auth-react';
import { AccessToken } from '@justgiving/auth/es/types/AccessToken';

import APP_SETTINGS from '../../config';
import { getIsGoCardlessInstantBankPaymentOutcome } from '../modules/PayWithBank/payWithBank.utils';
import { updateIsLoggedIn, updateUserDetails } from '../redux/auth/auth.actions';
import { useLazyFetchCheckoutQuery } from '../redux/checkoutApiSlice/queryCheckoutApi';
import { getGQLIsRecurringOrDirectDebit } from '../redux/checkoutApiSlice/selectors/complex.selectors';
import {
  getGQLLineItemValueInPounds,
  getGQLSupportsGuest,
} from '../redux/checkoutApiSlice/selectors/lineItem.selectors';
import { getGQLCurrencyCode } from '../redux/checkoutApiSlice/selectors/order.selectors';
import { getIsEmbedded } from '../redux/session/session.selectors';
import fetchWithTimeout from '../utils/fetchWithTimeout';
import { getLoginURL } from '../utils/getLoginURL';
import { logError } from '../utils/logError';
import { logInfo } from '../utils/logInfo';
import { useAppDispatch, useAppSelector } from './reduxHooks';
import { useInitialiseBraintree } from './useInitialiseBraintree';
import { useSetSelectedPaymentType } from './useSetSelectedPaymentType';

export const useAuth = () => {
  const auth = useContext(AuthContext);
  const [queryCheckout] = useLazyFetchCheckoutQuery();
  const dispatch = useAppDispatch();
  const { setSelectedPaymentType } = useSetSelectedPaymentType();
  const { initialiseBraintree } = useInitialiseBraintree();
  const isEmbedded = useAppSelector(getIsEmbedded);

  const forceGuest = async () => {
    try {
      await fetchWithTimeout(APP_SETTINGS.AUTH_GUEST_LOGIN_URL, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'Cache-Control': 'no-cache',
        },
        mode: 'cors',
        body: JSON.stringify({
          GrantType: 'guest',
          ClientId: 'jg.onecheckout.guest',
        }),
        method: 'POST',
        credentials: 'include',
      });
      window.Sentry?.addBreadcrumb({
        category: 'auth',
        message: `User logged in as guest`,
        level: 'info',
      });

      await initialiseBraintree();
      await setSelectedPaymentType();
      dispatch(updateIsLoggedIn(true));
    } catch (e) {
      logInfo({ tags: ['fetchWithTimeout'], message: 'Forced Guest login call failed', additional: e });
    }
  };

  const checkAuth = async () => {
    const { data } = await queryCheckout(undefined, true);
    if (auth?.isUserLoggedIn()) {
      dispatch(updateIsLoggedIn(true));
    }

    if (getIsGoCardlessInstantBankPaymentOutcome()) {
      return Promise.resolve();
    }

    const isGuestSupported = getGQLSupportsGuest(data);
    const isRecurringOrDirectDebit = getGQLIsRecurringOrDirectDebit(data);

    const redirectToSSO = () => {
      let SSO_url = `${APP_SETTINGS.LOGIN_URL}${window.location.href}`;

      if (!auth?.isConsumer() && !auth?.isGuest()) {
        SSO_url += '&actionType=no_permission_consumer';
      }
      window.location.assign(SSO_url);
      return Promise.reject();
    };

    if (!auth?.isUserLoggedIn()) return;

    try {
      if (auth?.isGuest() && !isGuestSupported) return redirectToSSO();
      // TODO Consider updating this next line in the future to destroy the guest cookie if the donation is not owned or throw an error if its owned instead of redirecting
      if (auth?.isGuest() && isRecurringOrDirectDebit) return redirectToSSO();
      if (!auth?.isGuest() && !auth?.isConsumer()) return redirectToSSO();

      if (auth?.isGuest()) {
        dispatch(updateIsLoggedIn(true));
        return;
      }

      await auth?.refreshAccessTokenIfExpired();

      const { profilePictureUrl, guid, givenName, familyName } = auth.getAccessToken() as AccessToken;
      dispatch(updateUserDetails({ profilePictureUrl, guid, givenName, familyName }));
    } catch (error) {
      logError(error);
      redirectToSSO();
    }
  };

  const manageCreatedDonationLogin = async () => {
    const { data } = await queryCheckout(undefined, true);
    const isGuestSupported = getGQLSupportsGuest(data);
    const isRecurringOrDirectDebit = getGQLIsRecurringOrDirectDebit(data);
    const lineItemValueInPounds = getGQLLineItemValueInPounds(data);

    if (
      lineItemValueInPounds &&
      getGQLCurrencyCode(data) &&
      !auth?.isUserLoggedIn() &&
      !getIsGoCardlessInstantBankPaymentOutcome()
    ) {
      if (isGuestSupported && !isRecurringOrDirectDebit) await forceGuest();
      if (!auth?.isUserLoggedIn()) {
        window.location.assign(getLoginURL(isEmbedded));
        return true;
      }
    }
    return false;
  };

  return { checkAuth, manageCreatedDonationLogin };
};
