import React, { FunctionComponent } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { SelectPaymentTypeFormValues } from '../../../../types/formValues';
import { sprinkles } from '../../../styles/sprinkles.css';
import VisuallyHidden from '../../components/VisuallyHidden/VisuallyHidden';
import Button from '../../components/button/Button';
import { useFetchCheckoutQuery } from '../../redux/checkoutApiSlice/queryCheckoutApi';
import { getGQLRequiresFullBillingAddress } from '../../redux/checkoutApiSlice/selectors/order.selectors';
import BillingAddressForm from '../BillingAddressForm/BillingAddressForm';
import CvvInput from '../Payment/CvvInput';
import PaymentCardNumberInput from '../Payment/PaymentCardNumberInput';
import PaymentCardSaveForFutureUseInput from '../Payment/PaymentCardSaveForFutureUseInput';
import PaymentNameOnCard from '../Payment/PaymentNameOnCard';
import CardExpiryForm from './CardExpiryForm';
import HousePostalCodeForm from './HousePostalCodeForm';

const AddPaymentCardForm: FunctionComponent = () => {
  const { requiresFullBillingAddress } = useFetchCheckoutQuery(undefined, {
    selectFromResult: ({ data }) => {
      return {
        requiresFullBillingAddress: getGQLRequiresFullBillingAddress(data),
      };
    },
  });
  const { t } = useTranslation();
  const {
    formState: { errors, isSubmitting },
  } = useFormContext<SelectPaymentTypeFormValues>();

  return (
    <>
      <PaymentCardNumberInput />
      <PaymentNameOnCard />
      <CardExpiryForm />
      <CvvInput />
      <HousePostalCodeForm />
      <PaymentCardSaveForFutureUseInput />
      {requiresFullBillingAddress && <BillingAddressForm />}

      <Button
        disabled={isSubmitting || Object.keys(errors).length > 0}
        isLoading={isSubmitting}
        qaSelector="selectPaymentPageContinueButton"
        className={sprinkles({ marginTop: 'sizeSpacing05' })}
      >
        {t('continueLabel')} <VisuallyHidden>{t('continueLabelAccessibleExtension')}</VisuallyHidden>
      </Button>
    </>
  );
};

export default AddPaymentCardForm;
