import { useCallback, useContext } from 'react';

import { AuthContext } from '@justgiving/auth-react';
import debug from 'debug';

import { httpErrorMessage } from '../config/httpErrorMessage';
import { PAGES } from '../config/pages';
import { useSetAbTests } from '../modules/ABTests/useSetAbTests';
import { useLazyFetchPageQuery } from '../redux/additionalPageDataApiSlice/additionalPageDataApiSlice';
import { useLazyFetchCheckoutQuery } from '../redux/checkoutApiSlice/queryCheckoutApi';
import { getGQLCheckoutMode } from '../redux/checkoutApiSlice/selectors/additionalData.selectors';
import { getGQLSourcePageRoute, getGQLSourceUrl } from '../redux/checkoutApiSlice/selectors/lineItem.selectors';
import { updateIsEmbedded } from '../redux/session/session.actions';
import { useInitialRoute } from '../router/useInitialRoute';
import { addBaseHeapProperties } from '../utils/heap';
import { useAppDispatch } from './reduxHooks';
import { useAuth } from './useAuth';
import { useInitialiseBraintree } from './useInitialiseBraintree';
import { useSetSelectedPaymentType } from './useSetSelectedPaymentType';

const jgDebug = debug('jg:checkout');

export const useSetupApp = () => {
  const dispatch = useAppDispatch();
  const { setSelectedPaymentType } = useSetSelectedPaymentType();
  const auth = useContext(AuthContext);
  const [queryCheckout] = useLazyFetchCheckoutQuery();
  const [queryPage] = useLazyFetchPageQuery();
  const { initialiseBraintree } = useInitialiseBraintree();
  const { manageCreatedDonationLogin, checkAuth } = useAuth();
  const { setAbTests } = useSetAbTests();
  const { initialRouteName } = useInitialRoute();

  const setupApp = useCallback(async () => {
    await queryPage(undefined, true);

    const { isError, error, data } = await queryCheckout(undefined, true);
    if (!isError && !data) {
      const dataError = new Error(httpErrorMessage.GRAPHQL_403_OR_404);
      // @ts-ignore
      dataError.isInformational = true;
      throw dataError;
    }

    if (isError) {
      const apiError = new Error(error.message);
      // @ts-ignore
      apiError.isInformational = true;
      throw apiError;
    }

    const checkoutMode = getGQLCheckoutMode(data);

    if (checkoutMode === 'Headless') {
      // TODO This essentially copies api data into state, which we prefer not to do. Checkout api will be in the future updated to always return public data on unauthenticated calls, when this is available we can remove this and update the retry function(s) to access checkout mode directly from data and redirect appropriately
      dispatch(updateIsEmbedded());
    }

    await checkAuth();

    try {
      if (data) addBaseHeapProperties(data, auth);
    } catch (error) {
      jgDebug('Heap:', error);
      window.Sentry?.captureException?.(error);
    }
    try {
      if (data) await setAbTests(data);
    } catch (error) {
      jgDebug('Ab:', error);
      window.Sentry?.captureException?.(error);
    }

    const redirectingToSSO = await manageCreatedDonationLogin();
    if (redirectingToSSO) {
      return { redirectingToSSO: true };
    }

    if (auth?.isUserLoggedIn()) {
      await initialiseBraintree();
      await setSelectedPaymentType();
    }

    const sourcePageRoute = getGQLSourcePageRoute(data);
    if (!sourcePageRoute) {
      // TODO - Remove this temporary logging after end of June 2024
      const sourcePageUrl = getGQLSourceUrl(data);
      try {
        window.Sentry?.captureMessage('Unexpected source page URL value', {
          level: 'warning',
          extra: { sourcePageUrl, sourcePageRoute: sourcePageRoute || "'(Empty String)" },
        });
      } catch (err) {
        // Do nothing
      }
    }
    const pageName = await initialRouteName();
    if (pageName) {
      const { search: queryString } = window.location;
      return { redirectToPage: `${sourcePageRoute}/${PAGES[pageName]}${queryString}` };
    }
    return { redirectingToSSO: false };
  }, [
    queryPage,
    queryCheckout,
    checkAuth,
    manageCreatedDonationLogin,
    auth,
    initialRouteName,
    dispatch,
    setAbTests,
    initialiseBraintree,
    setSelectedPaymentType,
  ]);

  return { setupApp };
};
