import React, { FunctionComponent } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useDecision } from '@optimizely/react-sdk';

import { AcceptedCurrencyCode, LineItemType } from '../../../../types/__generated-graphQL__';
import { AmountPageFormValues } from '../../../../types/formValues';
import { InfoBox } from '../../components/InfoBox/InfoBox';
import ButtonTextLink from '../../components/button/ButtonTextLink';
import { getAbTestKey } from '../../config/abTests';
import { useFetchCheckoutQuery } from '../../redux/checkoutApiSlice/queryCheckoutApi';
import { getGQLOrderType } from '../../redux/checkoutApiSlice/selectors/lineItem.selectors';
import { formatCurrency } from '../../utils/value';

const AmountBoostPromptContents: FunctionComponent = () => {
  const { t } = useTranslation();
  const { setValue, watch } = useFormContext<AmountPageFormValues>();

  const [{ enabled: amountBoostEnabled }] = useDecision(getAbTestKey({ abTest: 'DONATE_MORE_PROMPT' }));
  const [donationValue] = watch(['donationValue']);

  if (!amountBoostEnabled) return null;

  const amountToBoost = 2;
  const addValue = formatCurrency(AcceptedCurrencyCode.Gbp, amountToBoost, false);

  const adjustValue = () => {
    const newDonationValue = Number(donationValue ?? '0') + amountToBoost;
    setValue('donationValue', String(newDonationValue));
  };

  return (
    <InfoBox type="star">
      {t('donateMore', {
        ns: 'ab',
        addValue,
      })}
      <ButtonTextLink onClick={adjustValue}>
        {t('donateMoreCta', {
          ns: 'ab',
          addValue,
        })}
      </ButtonTextLink>
    </InfoBox>
  );
};

export const AmountBoostPrompt: FunctionComponent = () => {
  const { orderType } = useFetchCheckoutQuery(undefined, {
    selectFromResult: ({ data }) => {
      return {
        orderType: getGQLOrderType(data),
      };
    },
  });
  const [transactionType, donationCurrencyCode] = useWatch<AmountPageFormValues>({
    name: ['transactionType', 'donationCurrencyCode'],
  });
  if (
    orderType === LineItemType.DonationCharityDirect ||
    ['RECURRING', 'CRYPTO'].includes(transactionType) ||
    donationCurrencyCode !== AcceptedCurrencyCode.Gbp
  ) {
    return null;
  }
  return <AmountBoostPromptContents />;
};
