import React, { FunctionComponent } from 'react';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import classNames from 'classnames';

import { CardType } from '../../../../types/__generated-graphQL__';
import { AddPaymentCardPageFormValues } from '../../../../types/formValues';
import { paymentIcon } from '../../../styles/icons.css';
import VisuallyHidden from '../../components/VisuallyHidden/VisuallyHidden';
import { useFetchCheckoutQuery } from '../../redux/checkoutApiSlice/queryCheckoutApi';
import { getGQLIsAmexSupported } from '../../redux/checkoutApiSlice/selectors/complex.selectors';
import { getGQLSupportedCardTypes } from '../../redux/checkoutApiSlice/selectors/order.selectors';
import * as styles from './AcceptedPaymentCardsInline.css';
import { getCardType } from './paymentCard.utils';

const AcceptedPaymentCardsInline: FunctionComponent = () => {
  const { isAmexSupported, supportedCardTypes } = useFetchCheckoutQuery(undefined, {
    selectFromResult: ({ data }) => {
      return {
        isAmexSupported: getGQLIsAmexSupported(data),
        supportedCardTypes: getGQLSupportedCardTypes(data),
      };
    },
  });
  const { t } = useTranslation();
  const cardNumber = useWatch<AddPaymentCardPageFormValues, 'cardNumber'>({ name: 'cardNumber' });
  const activeCards = getCardType(cardNumber);
  const activeCardTypes = activeCards.length ? activeCards : supportedCardTypes;

  const unavailableTitle = t('paymentCard|cardTypesAccepted|unavailable', { ns: 'payment' });

  return (
    <>
      <VisuallyHidden as="strong">{t('paymentCard|cardTypesAccepted', { ns: 'payment' })}</VisuallyHidden>
      <ul className={styles.iconsList} data-qa="paymentCardIcons">
        <li
          title={`Visa${!activeCardTypes?.includes(CardType.Visa) ? unavailableTitle : ''}`}
          className={classNames(styles.cardIcon, paymentIcon({ type: 'visa' }), {
            [styles.active]: activeCardTypes?.includes(CardType.Visa),
          })}
        >
          <VisuallyHidden>Visa</VisuallyHidden>
        </li>
        <li
          title={`Mastercard${!activeCardTypes?.includes(CardType.Mastercard) ? unavailableTitle : ''}`}
          className={classNames(styles.cardIcon, paymentIcon({ type: 'mastercard' }), {
            [styles.active]: activeCardTypes?.includes(CardType.Mastercard),
          })}
        >
          <VisuallyHidden>Mastercard</VisuallyHidden>
        </li>
        {isAmexSupported && (
          <li
            title={`American Express${!activeCardTypes?.includes(CardType.AmericanExpress) ? unavailableTitle : ''}`}
            className={classNames(styles.cardIcon, paymentIcon({ type: 'amex' }), {
              [styles.active]: activeCardTypes?.includes(CardType.AmericanExpress),
            })}
          >
            <VisuallyHidden>American Express</VisuallyHidden>
          </li>
        )}
      </ul>
    </>
  );
};

export default AcceptedPaymentCardsInline;
