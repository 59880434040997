import React, { FunctionComponent, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { AuthContext } from '@justgiving/auth-react';
import { OptimizelyContext } from '@optimizely/react-sdk';
import classNames from 'classnames';

import APP_SETTINGS from '../../../config';
import { button } from '../../../styles/recipes/buttons.css';
import { sprinkles } from '../../../styles/sprinkles.css';
import { useFetchCheckoutQuery } from '../../redux/checkoutApiSlice/queryCheckoutApi';
import { getGQLCharityName, getGQLLegacyCharityId } from '../../redux/checkoutApiSlice/selectors/complex.selectors';
import { getGQLIsRecurringOrDirectDebit } from '../../redux/checkoutApiSlice/selectors/complex.selectors';
import { getGQLIsRegularGivingAvailableForAnySupportedCurrency } from '../../redux/checkoutApiSlice/selectors/order.selectors';
import useAccordionExperiment from '../AccordionPages/useAccordionExperiment';
import * as styles from './RegularGivingPrompt.css';

export const RegularGivingPrompt: FunctionComponent = () => {
  const { charityName, legacyCharityId, isRecurringOrDirectDebit, isRegularGivingAvailableForAnySupportedCurrency } =
    useFetchCheckoutQuery(undefined, {
      selectFromResult: ({ data }) => {
        return {
          charityName: getGQLCharityName(data),
          legacyCharityId: getGQLLegacyCharityId(data),
          isRecurringOrDirectDebit: getGQLIsRecurringOrDirectDebit(data),
          isRegularGivingAvailableForAnySupportedCurrency: getGQLIsRegularGivingAvailableForAnySupportedCurrency(data),
        };
      },
    });
  const { t } = useTranslation();

  const { accordionEnabled } = useAccordionExperiment();

  const { optimizely } = useContext(OptimizelyContext);

  const auth = useContext(AuthContext);
  // If in doubt, assume user is guest so that cookie is "deleted" - allowing a regular gift to be created in next transaction
  const isGuest = (auth && auth.isGuest()) ?? true;

  if (isRecurringOrDirectDebit || !isRegularGivingAvailableForAnySupportedCurrency || !legacyCharityId) {
    return null;
  }

  const handleClick = () => {
    optimizely?.track('jg_checkout_thankyou_regular_giving_button');
    if (isGuest) {
      document.cookie = `${APP_SETTINGS.AUTH_COOKIE_NAME}=; Path=/; Domain=.justgiving.com; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    }
    window.location.href = `${APP_SETTINGS.LINK_SERVICE_URL}/v1/charity/donate/charityId/${legacyCharityId}?isRecurring=true`;
  };

  return (
    <div className={classNames(styles.wrapper, { [styles.accordionWrapper]: accordionEnabled })}>
      <div className={styles.prompt}>
        <p>{t('regularGivingPromptMessage', { charityName })}</p>
        <button
          onClick={handleClick}
          className={classNames(
            button({ size: 'minWidth', color: 'primary' }),
            sprinkles({ marginTop: 'sizeSpacing05' }),
          )}
        >
          {t('regularGivingPromptButton')}
        </button>
      </div>
    </div>
  );
};
