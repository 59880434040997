import React, { FunctionComponent, memo, useEffect, useRef } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AddPaymentCardPageFormValues } from '../../../../types/formValues';
import Input from '../../components/Form/Input/Input';
import Label from '../../components/Form/Label/Label';
import { FormError } from '../../components/FormError/FormError';
import { useFetchCheckoutQuery } from '../../redux/checkoutApiSlice/queryCheckoutApi';
import { getGQLSupportedCardTypes } from '../../redux/checkoutApiSlice/selectors/order.selectors';
import { isValidLuhn } from '../../utils/isValidLuhn';
import { formatCardNumber, getCardType } from './paymentCard.utils';

const PaymentCardNumberInput: FunctionComponent = memo(() => {
  const { supportedCardTypes } = useFetchCheckoutQuery(undefined, {
    selectFromResult: ({ data }) => {
      return {
        supportedCardTypes: getGQLSupportedCardTypes(data),
      };
    },
  });
  const { t } = useTranslation();

  const { setValue, control } = useFormContext<AddPaymentCardPageFormValues>();

  const cardNumber = useWatch<AddPaymentCardPageFormValues>({ name: 'cardNumber' });
  const prevCardNumberRef = useRef<string>();

  useEffect(() => {
    prevCardNumberRef.current = cardNumber;
  }, [cardNumber]);
  const prevCardNumber = prevCardNumberRef.current;

  return (
    <>
      <Label htmlFor="cardNumber">{t('paymentCard|CardNumber|label', { ns: 'payment' })}</Label>
      <Controller
        name="cardNumber"
        control={control}
        defaultValue=""
        rules={{
          required: { value: true, message: t('paymentCard|CardNumber|error', { ns: 'validation' }) },
          pattern: {
            value: /(^\d{15,16}$)|(^\d{4} \d{6} \d{5}$)|(^\d{4} \d{4} \d{4} \d{4}$)/,
            message: t('paymentCard|CardNumber|notSupported', { ns: 'validation' }),
          },
          validate: {
            validLuhn: cardNumber => {
              return (
                isValidLuhn(cardNumber) || (t('paymentCard|CardNumber|inValidLuhn', { ns: 'validation' }) as string)
              );
            },
            supported: (cardNumber = '') => {
              const cardType = getCardType(cardNumber);
              const bin = cardNumber.replace(/\s/g, '');
              if (bin.length >= 6 && !supportedCardTypes?.includes(cardType[0]))
                return t('paymentCard|CardNumber|notSupported', { ns: 'validation' }) as string;
              return true;
            },
          },
        }}
        render={({ field: { name, value, onBlur, onChange } }) => (
          <Input
            name={name}
            id={name}
            heap-ignore="true"
            inputMode="numeric"
            pattern="[0-9]*"
            onBlur={onBlur}
            value={value}
            autoComplete="off"
            onChange={e => {
              setValue('cvv', '');
              onChange(formatCardNumber(e.target.value, prevCardNumber));
            }}
            required
          />
        )}
      />
      <FormError<AddPaymentCardPageFormValues> fieldName="cardNumber" />
    </>
  );
});

export default PaymentCardNumberInput;
