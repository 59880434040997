import React, { FunctionComponent } from 'react';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AmountPageFormValues } from '../../../../types/formValues';
import { sprinkles } from '../../../styles/sprinkles.css';
import Checkbox from '../../components/Form/Checkbox/Checkbox';
import { VoluntaryContributionTooltip } from '../../components/VoluntaryContributionTooltip/VoluntaryContributionTooltip';
import { useFetchCheckoutQuery } from '../../redux/checkoutApiSlice/queryCheckoutApi';
import { getGQLIsDonorPaysFeesEnabled } from '../../redux/checkoutApiSlice/selectors/lineItem.selectors';
import { getGQLPlatformFeePercentage } from '../../redux/checkoutApiSlice/selectors/order.selectors';
import { formatCurrency } from '../../utils/value';
import { calculateTip } from './donorPaysFees.utils';

const DonorPaysFees: FunctionComponent = () => {
  const { platformFeePercentage } = useFetchCheckoutQuery(undefined, {
    selectFromResult: ({ data }) => {
      return {
        platformFeePercentage: getGQLPlatformFeePercentage(data),
      };
    },
  });

  const { t } = useTranslation();
  const [donationCurrencyCode, donationValue] = useWatch<
    AmountPageFormValues,
    ['donationCurrencyCode', 'donationValue']
  >({ name: ['donationCurrencyCode', 'donationValue'] });

  const amount = formatCurrency(donationCurrencyCode, calculateTip(donationValue, platformFeePercentage));

  return (
    <div className={sprinkles({ marginBottom: 'sizeSpacing05' })} data-qa="donor-pays-fees-container">
      <Checkbox
        name="isDonorPayingFees"
        id="isDonorPayingFees"
        data-qa="donorPaysFees"
        className={sprinkles({ marginTop: 'sizeSpacing04' })}
      >
        <>
          {t('amount|label', { amount, percentage: platformFeePercentage, ns: 'dpf' })}{' '}
          <VoluntaryContributionTooltip
            dynamicDonationPlatformFee={calculateTip(donationValue, platformFeePercentage)}
            dynamicDonationCurrencyCode={donationCurrencyCode}
            showText
          />
        </>
      </Checkbox>
    </div>
  );
};

export const DonorPaysFeesWrapper: FunctionComponent = () => {
  const { data } = useFetchCheckoutQuery();
  const isDonorPaysFeesEnabled = getGQLIsDonorPaysFeesEnabled(data);
  return isDonorPaysFeesEnabled ? <DonorPaysFees /> : null;
};
