export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  CountryCode: any;
  CurrencyCode: any;
  DateTime: any;
  FixedFloat: any;
  JSON: any;
  LeaderboardId: any;
  MonetaryValue: any;
  Preview: any;
  Slug: any;
  URL: any;
  UUID: any;
};

export enum AcceptedCurrencyCode {
  Aud = 'AUD',
  Cad = 'CAD',
  Eur = 'EUR',
  Gbp = 'GBP',
  Nzd = 'NZD',
  Usd = 'USD',
}

export type Account = {
  __typename?: 'Account';
  connectionStatus: AccountConnectionStatus;
  startDate?: Maybe<Scalars['DateTime']>;
  timeRemaining?: Maybe<AccountConnectionTimeline>;
};

export enum AccountConnectionStatus {
  Connected = 'CONNECTED',
  Deactivated = 'DEACTIVATED',
  ElevatedFrozen = 'ELEVATED_FROZEN',
  Frozen = 'FROZEN',
  Initialised = 'INITIALISED',
  ManualVerification = 'MANUAL_VERIFICATION',
  PendingVerification = 'PENDING_VERIFICATION',
  PseudoSuspended = 'PSEUDO_SUSPENDED',
  Reminded = 'REMINDED',
  Unfunded = 'UNFUNDED',
  Verified = 'VERIFIED',
}

export type AccountConnectionTimeline = {
  __typename?: 'AccountConnectionTimeline';
  final?: Maybe<TimeRemaining>;
  next?: Maybe<TimeRemaining>;
};

export type Activity = {
  __typename?: 'Activity';
  date?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  type: ActivityType;
};

export type ActivityInput = {
  date?: InputMaybe<Scalars['DateTime']>;
  name: Scalars['String'];
  type: ActivityType;
};

export enum ActivityType {
  Anniversaries = 'Anniversaries',
  Birthday = 'Birthday',
  CharityAppeal = 'CharityAppeal',
  Christening = 'Christening',
  Christmas = 'Christmas',
  CompanyAppeal = 'CompanyAppeal',
  Cycling = 'Cycling',
  InMemory = 'InMemory',
  IndividualAppeal = 'IndividualAppeal',
  NewYearsResolutions = 'NewYearsResolutions',
  OtherCelebration = 'OtherCelebration',
  OtherPersonalChallenge = 'OtherPersonalChallenge',
  OtherSportingEvents = 'OtherSportingEvents',
  ParachutingSkydives = 'Parachuting_Skydives',
  PersonalCycling = 'PersonalCycling',
  PersonalParachutingSkydives = 'PersonalParachuting_Skydives',
  PersonalRunningMarathons = 'PersonalRunning_Marathons',
  PersonalStreamingGaming = 'PersonalStreaming_Gaming',
  PersonalSwimming = 'PersonalSwimming',
  PersonalTreks = 'PersonalTreks',
  PersonalTriathlons = 'PersonalTriathlons',
  PersonalWalks = 'PersonalWalks',
  RunningMarathons = 'Running_Marathons',
  Swimming = 'Swimming',
  Treks = 'Treks',
  Triathlons = 'Triathlons',
  Walks = 'Walks',
  Wedding = 'Wedding',
}

export type AddMediaItemInput = {
  media: MediaItemInput;
  pageId: Scalars['ID'];
  type: PageType;
};

export type AddMediaItemPayload = {
  __typename?: 'AddMediaItemPayload';
  itemId: Scalars['String'];
};

export type Address = {
  __typename?: 'Address';
  country?: Maybe<Scalars['String']>;
  countyStateRegion?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  postalCode: Scalars['String'];
  townCity?: Maybe<Scalars['String']>;
};

export type AddressInput = {
  country: Scalars['String'];
  countyStateRegion?: InputMaybe<Scalars['String']>;
  line1: Scalars['String'];
  line2?: InputMaybe<Scalars['String']>;
  postalCode: Scalars['String'];
  selectionHash?: InputMaybe<Scalars['String']>;
  townCity: Scalars['String'];
};

export type AddressesByPostalCodeConnection = {
  __typename?: 'AddressesByPostalCodeConnection';
  edges: Array<AddressesByPostalCodeEdge>;
  totalCount: Scalars['Int'];
};

export type AddressesByPostalCodeEdge = {
  __typename?: 'AddressesByPostalCodeEdge';
  node: Address;
};

export type AddressesByPostalCodeInput = {
  postalCode: Scalars['String'];
};

export type AggregationRule = {
  __typename?: 'AggregationRule';
  measurementDomains: Array<Scalars['String']>;
  segment: Scalars['String'];
  timeBox?: Maybe<TimeBox>;
};

export enum AlternativePaymentMethod {
  BraintreeApplePay = 'BraintreeApplePay',
  BraintreeGooglePay = 'BraintreeGooglePay',
  BraintreePayPal = 'BraintreePayPal',
  BraintreeVenmo = 'BraintreeVenmo',
  Crypto = 'Crypto',
  DirectDebit = 'DirectDebit',
  GoCardlessInstantBankPayment = 'GoCardlessInstantBankPayment',
  JustGivingVoucher = 'JustGivingVoucher',
}

export type AnalyticsData = {
  __typename?: 'AnalyticsData';
  id: Scalars['String'];
  type: Scalars['String'];
};

export type AnalyticsInput = {
  id: Scalars['String'];
  type: Scalars['String'];
};

export type Application = FitnessApplication;

export enum ApplicationType {
  Fitness = 'FITNESS',
}

export type ArchiveDonationLinkInput = {
  donationLinkId: Scalars['String'];
};

export type AsksItem = {
  __typename?: 'AsksItem';
  amountRaised?: Maybe<Scalars['MonetaryValue']>;
  askStatus?: Maybe<Scalars['String']>;
  beneficiaryName?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['CurrencyCode']>;
  editUrl?: Maybe<Scalars['String']>;
  hasTarget?: Maybe<Scalars['Boolean']>;
  imageUrl?: Maybe<Scalars['String']>;
  isYimby?: Maybe<Scalars['Boolean']>;
  legacyId?: Maybe<Scalars['String']>;
  progress?: Maybe<Scalars['String']>;
  target?: Maybe<Scalars['MonetaryValue']>;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  yimbyPitchBecause?: Maybe<Scalars['String']>;
  yimbyPitchFor?: Maybe<Scalars['String']>;
};

export type AssignTagsToChildrenInput = {
  children?: InputMaybe<Array<ChildrenInput>>;
  parentId: Scalars['UUID'];
};

export type Author = {
  __typename?: 'Author';
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type AvailableSlugs = {
  __typename?: 'AvailableSlugs';
  isAvailable?: Maybe<Scalars['Boolean']>;
  slugs?: Maybe<Array<Maybe<Scalars['Slug']>>>;
};

export type Badge = {
  __typename?: 'Badge';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  image?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type Badges = {
  __typename?: 'Badges';
  fundraiserBadges?: Maybe<Array<Maybe<Badge>>>;
  teamBadges?: Maybe<Array<Maybe<Badge>>>;
};

export type BeneficiaryConnection = {
  __typename?: 'BeneficiaryConnection';
  edges: Array<BeneficiaryEdge>;
  nodes: Array<BeneficiaryItem>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type BeneficiaryEdge = {
  __typename?: 'BeneficiaryEdge';
  cursor: Scalars['String'];
  node: BeneficiaryItem;
};

export type BeneficiaryItem = Charity | User;

export type Birthday = {
  __typename?: 'Birthday';
  age?: Maybe<Scalars['Int']>;
  celebrationDate?: Maybe<Scalars['DateTime']>;
};

export type BirthdayInput = {
  age?: InputMaybe<Scalars['Int']>;
  celebrationDate?: InputMaybe<Scalars['DateTime']>;
};

export type Branding = {
  __typename?: 'Branding';
  backgroundColor?: Maybe<Scalars['String']>;
  backgroundImage?: Maybe<Scalars['String']>;
  emailCampaignLogo?: Maybe<Scalars['String']>;
  emailCover?: Maybe<Scalars['String']>;
  externalFooterBackgroundColor?: Maybe<Scalars['String']>;
  externalFooterForegroundColor?: Maybe<Scalars['String']>;
  externalHeaderBackgroundColor?: Maybe<Scalars['String']>;
  externalHeaderForegroundColor?: Maybe<Scalars['String']>;
  headerImage?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  logo?: Maybe<Scalars['String']>;
  primaryColor?: Maybe<Scalars['String']>;
};

export type BrandingDisplayOptionsInput = {
  headerImageCharityFallback?: InputMaybe<Scalars['Boolean']>;
};

export type BrandingInput = {
  backgroundColor?: InputMaybe<Scalars['String']>;
  backgroundImage?: InputMaybe<Scalars['String']>;
  emailCampaignLogo?: InputMaybe<Scalars['String']>;
  emailCover?: InputMaybe<Scalars['String']>;
  headerImage?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<Scalars['String']>;
  primaryColor?: InputMaybe<Scalars['String']>;
};

export enum CacheControlScope {
  Private = 'PRIVATE',
  Public = 'PUBLIC',
}

export type CanParticipateInInput = {
  /** ID of a team to filter by */
  teamId?: InputMaybe<Scalars['ID']>;
};

export type CancelPageInput = {
  id: Scalars['ID'];
  type?: InputMaybe<PageType>;
};

export type CancelPagePayload = {
  __typename?: 'CancelPagePayload';
  page: PageWithId;
};

export enum CardType {
  AmericanExpress = 'AmericanExpress',
  Mastercard = 'Mastercard',
  Unknown = 'Unknown',
  Visa = 'Visa',
}

export type Cause = Node & {
  __typename?: 'Cause';
  bbms?: Maybe<CauseBbms>;
  beneficiaryRef: Scalars['String'];
  causeRef: Scalars['String'];
  giftAid: CauseGiftAid;
  id: Scalars['ID'];
  marketCode: MarketCode;
  paymentMethods: Array<PaymentMethod>;
  pointsSchemes: Array<CausePointsScheme>;
  settlementCurrency: AcceptedCurrencyCode;
  sourceRef: Scalars['String'];
  supportsGuest: Scalars['Boolean'];
  tip: CauseTip;
  type: Scalars['String'];
};

export type CauseBbms = {
  __typename?: 'CauseBbms';
  merchantAccountId?: Maybe<Scalars['String']>;
};

export type CauseByCauseRefInput = {
  causeRef: Scalars['String'];
};

export type CauseBySourceRefInput = {
  sourceRef: Scalars['String'];
};

export type CauseGiftAid = {
  __typename?: 'CauseGiftAid';
  externalProviderEnabled: Scalars['Boolean'];
  supported: Scalars['Boolean'];
};

export type CauseInput = {
  causeRef?: InputMaybe<Scalars['String']>;
  causeSourceType?: InputMaybe<CauseSourceType>;
  sourceRef?: InputMaybe<Scalars['String']>;
};

export type CausePointsScheme = {
  __typename?: 'CausePointsScheme';
  currency: Scalars['CurrencyCode'];
  supportedSchemes: Array<PointsScheme>;
};

export type CauseResponse = {
  __typename?: 'CauseResponse';
  cause?: Maybe<Cause>;
  error?: Maybe<CauseRetrievalError>;
};

export enum CauseRetrievalError {
  Deprecated = 'Deprecated',
  Expired = 'Expired',
  NotFound = 'NotFound',
  Unsubscribed = 'Unsubscribed',
}

export type CauseSearchConnection = {
  __typename?: 'CauseSearchConnection';
  edges: Array<CauseSearchEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type CauseSearchEdge = {
  __typename?: 'CauseSearchEdge';
  node: CauseSearchResult;
};

export type CauseSearchOptionsInput = {
  filterCountry?: InputMaybe<Scalars['Boolean']>;
  fuzzy?: InputMaybe<Scalars['Boolean']>;
};

export type CauseSearchResult = Node & {
  __typename?: 'CauseSearchResult';
  campaignId?: Maybe<Scalars['String']>;
  campaignName?: Maybe<Scalars['String']>;
  charityId?: Maybe<Scalars['String']>;
  charityName?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  fundraisersCount?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  logo?: Maybe<Scalars['URL']>;
  registrationNumber?: Maybe<Scalars['String']>;
  startFundraisingUrl?: Maybe<Scalars['String']>;
  type?: Maybe<SearchResultType>;
};

export enum CauseSourceType {
  CampaignDirect = 'CampaignDirect',
  CampaignFrp = 'CampaignFRP',
  CharityDirect = 'CharityDirect',
  CharityFrp = 'CharityFRP',
  CharityId = 'CharityId',
  Crowdfunding = 'Crowdfunding',
}

export type CauseTip = {
  __typename?: 'CauseTip';
  tipJarConfiguration?: Maybe<Array<TipJarConfiguration>>;
  tipScheme: TipScheme;
};

export type Charity = Node & {
  __typename?: 'Charity';
  beneficiaryId?: Maybe<Scalars['UUID']>;
  brandColor?: Maybe<Scalars['String']>;
  cause?: Maybe<Cause>;
  /** @deprecated Use `cause.causeRef` */
  causeId?: Maybe<Scalars['String']>;
  children?: Maybe<PageRelationshipConnection>;
  consentStatements?: Maybe<Array<ConsentStatement>>;
  countryCode?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  displayCurrency?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  donationPrompts?: Maybe<Array<DonationPrompt>>;
  donationStats?: Maybe<DonationStats>;
  entitlements?: Maybe<Entitlements>;
  externalId: Scalars['Int'];
  externalUrl?: Maybe<Scalars['URL']>;
  /** @deprecated Use fundraiserListV2. Current migrating from cursor based to offset pagination. */
  fundraiserList?: Maybe<CharityFundraiserListConnection>;
  fundraiserListV2?: Maybe<CharityFundraiserListV2Connection>;
  headerLogo?: Maybe<Scalars['URL']>;
  id: Scalars['ID'];
  impactStatement?: Maybe<Scalars['String']>;
  isApproved?: Maybe<Scalars['Boolean']>;
  /** @deprecated Use entitlements.hasPoweredBy to provide the same value */
  isPoweredBy?: Maybe<Scalars['Boolean']>;
  landingPage?: Maybe<Page>;
  /** @deprecated Use externalId */
  legacyCharityId?: Maybe<Scalars['Int']>;
  legacyId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['URL']>;
  name?: Maybe<Scalars['String']>;
  /** @deprecated Use landingPage.legacyId */
  pageId?: Maybe<Scalars['String']>;
  profilePageUrl: Scalars['URL'];
  registrationNumber?: Maybe<Scalars['String']>;
  slug: Scalars['Slug'];
  supportsGiftAid?: Maybe<Scalars['Boolean']>;
  /** @deprecated Use landingPage.targetWithCurrency */
  targetWithCurrency?: Maybe<MonetaryAmount>;
  thankYouMessage?: Maybe<Scalars['String']>;
};

export type CharityChildrenArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<GetChildrenOrderByArg>;
  productTypes: Array<ProductType>;
  statuses?: InputMaybe<Array<PageStatus>>;
};

export type CharityConsentStatementsArgs = {
  consentLocation: ConsentLocation;
};

export type CharityDonationStatsArgs = {
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
};

export type CharityFundraiserListArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  endDate: Scalars['DateTime'];
  first?: InputMaybe<Scalars['Int']>;
  fundraiserFilterType: FundraiserFilterType;
  last?: InputMaybe<Scalars['Int']>;
  startDate: Scalars['DateTime'];
};

export type CharityFundraiserListV2Args = {
  filters: CharityFundraiserFilters;
  pagination: OffsetPaginationInput;
};

export type CharityConnection = {
  __typename?: 'CharityConnection';
  edges: Array<CharityEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type CharityEdge = {
  __typename?: 'CharityEdge';
  node: Charity;
};

export type CharityExternalLinks = {
  __typename?: 'CharityExternalLinks';
  links?: Maybe<Array<Maybe<Link>>>;
  privacyUrl?: Maybe<Scalars['String']>;
  socialLinks?: Maybe<Array<Maybe<Link>>>;
  termsUrl?: Maybe<Scalars['String']>;
};

export type CharityFilters = {
  registrationNumber?: InputMaybe<Scalars['String']>;
};

export type CharityFundraiserFilters = {
  endDate: Scalars['DateTime'];
  fundraiserFilterType: FundraiserFilterType;
  startDate: Scalars['DateTime'];
};

export type CharityFundraiserListConnection = {
  __typename?: 'CharityFundraiserListConnection';
  edges: Array<CharityFundraiserListEdge>;
  nodes: Array<Page>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type CharityFundraiserListEdge = {
  __typename?: 'CharityFundraiserListEdge';
  cursor: Scalars['String'];
  node: Page;
};

export type CharityFundraiserListV2Connection = {
  __typename?: 'CharityFundraiserListV2Connection';
  nodes: Array<Page>;
  pageInfo: OffsetPageInfo;
  totalCount: Scalars['Int'];
};

export type Checkout = Node & {
  __typename?: 'Checkout';
  additionalData: Scalars['JSON'];
  checkoutId: Scalars['ID'];
  customAttributes?: Maybe<CustomAttributes>;
  giftAid?: Maybe<GiftAid>;
  id: Scalars['ID'];
  order?: Maybe<Order>;
  payment?: Maybe<Payment>;
  transactingUser?: Maybe<TransactingUser>;
  transaction?: Maybe<Transaction>;
  userLocale?: Maybe<Locale>;
};

export type Checkout3DsData = {
  __typename?: 'Checkout3DSData';
  bin?: Maybe<Scalars['String']>;
  clientTokenUnavailable: Scalars['Boolean'];
  exemptionRequested?: Maybe<Scalars['Boolean']>;
  nonce?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
};

export type Checkout3DsNonceBillingAddress = {
  __typename?: 'Checkout3DSNonceBillingAddress';
  addressLine1: Scalars['String'];
  addressLine2?: Maybe<Scalars['String']>;
  billingAddressId: Scalars['String'];
  country: Scalars['String'];
  countyStateRegion?: Maybe<Scalars['String']>;
  postalCode: Scalars['String'];
  townCity?: Maybe<Scalars['String']>;
  udprn?: Maybe<Scalars['String']>;
};

export type Checkout3DsNonceCallInput = {
  checkoutId: Scalars['ID'];
  deviceType: DeviceType;
};

export type Checkout3DsNonceResponse = {
  __typename?: 'Checkout3DSNonceResponse';
  billingAddress: Checkout3DsNonceBillingAddress;
  bin: Scalars['Int'];
  emailAddress: Scalars['String'];
  exemptionRequested: Scalars['Boolean'];
  nonce: Scalars['String'];
  paymentCardId: Scalars['String'];
};

export type Checkout3DsVerification = {
  __typename?: 'Checkout3DSVerification';
  isSuccessful: Scalars['Boolean'];
};

export type Checkout3dsInitialiseCallInput = {
  checkoutId: Scalars['ID'];
};

export type CheckoutBillingRequestFlowId = {
  __typename?: 'CheckoutBillingRequestFlowId';
  billingRequestFlowId: Scalars['String'];
};

export type CheckoutInitialise3DsResponse = {
  __typename?: 'CheckoutInitialise3DSResponse';
  clientTokenUnavailable?: Maybe<Scalars['Boolean']>;
  token: Scalars['String'];
};

export type CheckoutInput = {
  checkoutId: Scalars['ID'];
  isPayWithBankTransfer?: InputMaybe<Scalars['Boolean']>;
};

export type CheckoutPaymentOrchestratorRedirectUrl = {
  __typename?: 'CheckoutPaymentOrchestratorRedirectUrl';
  redirectUrl: Scalars['String'];
};

export type CheckoutWalletToken = {
  __typename?: 'CheckoutWalletToken';
  token: Scalars['String'];
};

export type CheckoutWalletVerification = {
  __typename?: 'CheckoutWalletVerification';
  isSuccessful: Scalars['Boolean'];
};

export type ChildrenInput = {
  HasTagsAssigned: Scalars['Boolean'];
  pageId: Scalars['UUID'];
};

export type Client = {
  __typename?: 'Client';
  name?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
};

export type ConfirmCheckoutInput = {
  checkoutId: Scalars['String'];
  deviceType: DeviceType;
  donorDetails: DonorDetailsInput;
  preferences?: InputMaybe<PreferencesInput>;
  sessionInformation?: InputMaybe<SessionInformationInput>;
};

export type ConnectFitnessInput = {
  code?: InputMaybe<Scalars['String']>;
  scope?: InputMaybe<Scalars['String']>;
};

export type ConsentInput = {
  consentGiven?: InputMaybe<Scalars['Boolean']>;
  consentStatementId?: InputMaybe<Scalars['Int']>;
  consentStatementText?: InputMaybe<Scalars['String']>;
  directMarketingType: Scalars['Boolean'];
  entityType: EntityType;
  hidden: Scalars['Boolean'];
};

export enum ConsentLocation {
  FundraisingPageCreation = 'FundraisingPageCreation',
}

export type ConsentStatement = {
  __typename?: 'ConsentStatement';
  entityType?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  text?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type ConsentsInput = {
  consentGiven?: InputMaybe<Scalars['Boolean']>;
  consentStatementId?: InputMaybe<Scalars['String']>;
  consentStatementText?: InputMaybe<Scalars['String']>;
  entityId?: InputMaybe<Scalars['String']>;
};

export type ConsumerMessagingFeature = {
  __typename?: 'ConsumerMessagingFeature';
  WeDidItEnabled: Scalars['Boolean'];
};

export type CreateDonationLinkInput = {
  name: Scalars['String'];
  parameters: DonationLinkParametersInput;
};

export type CreateEventInput = {
  activities: Array<FitnessApplicationActivityType>;
  allowManualActivities: Scalars['Boolean'];
  campaignId?: InputMaybe<Scalars['String']>;
  endDate: Scalars['DateTime'];
  externalFitnessSources: Array<ExternalFitnessSources>;
  fundraiserFitnessGoal: FitnessGoalInput;
  startDate: Scalars['DateTime'];
  title: Scalars['String'];
};

export type CreateLivestreamInput = {
  pageId: Scalars['String'];
  pageType: PageType;
  streamId: Scalars['String'];
  streamType: Scalars['String'];
};

export type CreateMediaCollectionInput = {
  media: MediaCollectionInput;
  ttl?: InputMaybe<Scalars['Int']>;
};

export type CreateMediaCollectionPayload = {
  __typename?: 'CreateMediaCollectionPayload';
  mediaCollectionId?: Maybe<Scalars['String']>;
};

export type CreateOfflineDonationInput = {
  amount: Scalars['MonetaryValue'];
  currency: Scalars['CurrencyCode'];
  displayName?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  pageId: Scalars['String'];
};

export type CreatePageMetadataInput = {
  isSitebuilder?: InputMaybe<Scalars['Boolean']>;
  ownerUserGroupGuid?: InputMaybe<Scalars['String']>;
};

export type CreatePageV2Input = {
  activity?: InputMaybe<ActivityInput>;
  appId?: InputMaybe<Scalars['String']>;
  beneficiaries: Array<PageBeneficiaryInput>;
  childRelationships?: InputMaybe<Array<PageRelationshipInput>>;
  cover?: InputMaybe<MediaItemInput>;
  coverMediaCollectionId?: InputMaybe<Scalars['String']>;
  customCodes?: InputMaybe<Array<CustomCodeInput>>;
  expirationDate?: InputMaybe<Scalars['DateTime']>;
  fundraisingSettings?: InputMaybe<FundraisingSettingsInput>;
  galleryMediaCollectionId?: InputMaybe<Scalars['String']>;
  membershipType?: InputMaybe<PageMembership>;
  occasion?: InputMaybe<OccasionInput>;
  product: ProductType;
  redirectUrl?: InputMaybe<Scalars['String']>;
  relationships: Array<PageRelationshipInput>;
  slug: Scalars['Slug'];
  startDate?: InputMaybe<Scalars['DateTime']>;
  story?: InputMaybe<Scalars['String']>;
  storyWithType?: InputMaybe<StoryInput>;
  summary?: InputMaybe<Scalars['String']>;
  /** Sets gift aid status for page, should always be true for newly created fundraising pages */
  supportsGiftAid: Scalars['Boolean'];
  tags: Array<PageTagInput>;
  target?: InputMaybe<MonetaryAmountInput>;
  thumbnailImage?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

export type CreateTeamInput = {
  campaignGuid?: InputMaybe<Scalars['UUID']>;
  coverPhotoImageUrl?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  relatedPageId?: InputMaybe<Scalars['UUID']>;
  relatedPageType?: InputMaybe<PageType>;
  story?: InputMaybe<StoryInput>;
  targetCurrency?: InputMaybe<Scalars['String']>;
  teamShortName?: InputMaybe<Scalars['String']>;
  teamTarget?: InputMaybe<Scalars['Int']>;
};

export type CreateTimelineEntryInput = {
  createdDate?: InputMaybe<Scalars['DateTime']>;
  creatorGuid?: InputMaybe<Scalars['String']>;
  fitness?: InputMaybe<FitnessActivityInput>;
  media?: InputMaybe<MediaCollectionInput>;
  message?: InputMaybe<Scalars['String']>;
  pageId: Scalars['ID'];
  pageSlug?: InputMaybe<Scalars['Slug']>;
  steps?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<PageType>;
};

export type CreateVideoStoryInput = {
  charityLogoUrl: Scalars['String'];
  currency: Scalars['String'];
  donationTotal: Scalars['Float'];
  fitness?: InputMaybe<FitnessTotalsInput>;
  imageUrls: Array<Scalars['String']>;
  pageGuid: Scalars['ID'];
  pageStatement?: InputMaybe<Scalars['String']>;
  pageTitle: Scalars['String'];
  userName: Scalars['String'];
};

export type CrowdfundingPageDetails = {
  id?: InputMaybe<Scalars['String']>;
  owner?: InputMaybe<CrowdfundingPageOwner>;
  targetAmount?: InputMaybe<Scalars['Float']>;
  targetCurrencyIsoCode?: InputMaybe<Scalars['String']>;
};

export type CrowdfundingPageOwner = {
  emailAddress: Scalars['String'];
  name: Scalars['String'];
};

export type Crypto = {
  __typename?: 'Crypto';
  currencyFullName: Scalars['String'];
  currencyLogoUrl: Scalars['String'];
  currencyShortName: Scalars['String'];
  estimatedExchangeRate: Scalars['Float'];
  id: Scalars['ID'];
};

export type CryptoInput = {
  currencyFullName: Scalars['String'];
  currencyLogoUrl: Scalars['String'];
  currencyShortName: Scalars['String'];
  estimatedExchangeRate: Scalars['Float'];
};

export type CryptocurrenciesInput = {
  checkoutId: Scalars['ID'];
};

export type Cryptocurrency = {
  __typename?: 'Cryptocurrency';
  exchangeRate: Scalars['Float'];
  exchangeRateTimestamp: Scalars['DateTime'];
  logoUrl: Scalars['String'];
  name: Scalars['String'];
  shortCode: Scalars['String'];
};

export type CryptocurrencyList = {
  __typename?: 'CryptocurrencyList';
  cryptocurrencies: Array<Cryptocurrency>;
};

export type CurrencyDefaults = {
  __typename?: 'CurrencyDefaults';
  default: Scalars['MonetaryValue'];
  maximum: Scalars['MonetaryValue'];
  minimum: Scalars['MonetaryValue'];
  suggestedAmounts: Array<SuggestedAmount>;
  supportedCardTypes: Array<CardType>;
  supportedPaymentTypes: Array<PaymentType>;
  supportedPointsSchemes: Array<PointsScheme>;
};

export type CursorPaginationInput = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
};

export type CustomAttributes = {
  __typename?: 'CustomAttributes';
  abTestUserId?: Maybe<Scalars['String']>;
  currency: AcceptedCurrencyCode;
  deepLinkAmountCurrency?: Maybe<AcceptedCurrencyCode>;
  exitUrl?: Maybe<Scalars['URL']>;
  externalRefs: Array<OutputExternalRef>;
  lockAmount?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
  redirectOnSuccess: Scalars['Boolean'];
  referralCode?: Maybe<Scalars['String']>;
  skipGiftAid?: Maybe<Scalars['Boolean']>;
  skipMessage?: Maybe<Scalars['Boolean']>;
  successUrl?: Maybe<Scalars['URL']>;
  suggestedPaymentType?: Maybe<PaymentType>;
  tipScheme?: Maybe<TipScheme>;
};

export type CustomCode = {
  __typename?: 'CustomCode';
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type CustomCodeInput = {
  id?: InputMaybe<Scalars['String']>;
  label?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type DateFilterInput = {
  endDate: Scalars['String'];
  startDate: Scalars['String'];
};

export type DeleteLivestreamInput = {
  mediaId: Scalars['String'];
  pageId: Scalars['String'];
  positionId: Scalars['Int'];
};

export type DeleteMediaItemInput = {
  itemId: Scalars['ID'];
  pageId: Scalars['ID'];
  type: PageType;
};

export type DeleteMediaItemPayload = {
  __typename?: 'DeleteMediaItemPayload';
  itemId: Scalars['String'];
};

export type DeleteOfflineDonationInput = {
  offlineDonationId: Scalars['String'];
  pageId: Scalars['String'];
};

export enum DeleteStatus {
  Fail = 'FAIL',
  Success = 'SUCCESS',
}

export type DeleteTimelineEntryInput = {
  id: Scalars['ID'];
};

export enum DeviceType {
  Desktop = 'Desktop',
  Mobile = 'Mobile',
}

export type DirectDebit = {
  __typename?: 'DirectDebit';
  accountHolderName: Scalars['String'];
  accountNumber: Scalars['String'];
  address?: Maybe<Address>;
  cancelWhenCauseExpires?: Maybe<Scalars['Boolean']>;
  dayOfMonth: Scalars['Int'];
  id: Scalars['ID'];
  mandateReference?: Maybe<Scalars['String']>;
  nextPaymentDate: Scalars['DateTime'];
  sortCode: Scalars['String'];
};

export type DirectDebitInput = {
  accountHolderName: Scalars['String'];
  accountNumber: Scalars['String'];
  address?: InputMaybe<AddressInput>;
  cancelWhenCauseExpires?: InputMaybe<Scalars['Boolean']>;
  dayOfMonth: Scalars['Int'];
  sortCode: Scalars['String'];
};

export type DiscoverItem = {
  __typename?: 'DiscoverItem';
  id: Scalars['Int'];
  imageSrc: Scalars['String'];
  linkUrl: Scalars['String'];
  pageGuid: Scalars['String'];
  pageTitle: Scalars['String'];
  pageType: Scalars['String'];
  position: Scalars['Int'];
  userImageSrc: Scalars['String'];
  username: Scalars['String'];
};

export type DiscoverPersistedPages = {
  __typename?: 'DiscoverPersistedPages';
  nodes: Array<Page>;
};

export type Distance = {
  __typename?: 'Distance';
  unit: DistanceUnit;
  value: Scalars['FixedFloat'];
};

export enum DistanceUnit {
  Feet = 'FEET',
  Metres = 'METRES',
}

export type Donation = Node & {
  __typename?: 'Donation';
  amount?: Maybe<MonetaryAmount>;
  avatar?: Maybe<Scalars['URL']>;
  beneficiary?: Maybe<Scalars['String']>;
  creationDate: Scalars['DateTime'];
  displayName: Scalars['String'];
  donationReference?: Maybe<Scalars['Int']>;
  donationStatus?: Maybe<SupporterDonationStatus>;
  giftAidAmount?: Maybe<MonetaryAmount>;
  id: Scalars['ID'];
  legacyId: Scalars['String'];
  matches: Array<DonationMatch>;
  message?: Maybe<Scalars['String']>;
  pageName?: Maybe<Scalars['String']>;
  pageOwnerReaction?: Maybe<Reaction>;
  updatedDate?: Maybe<Scalars['DateTime']>;
};

export type DonationAmountDetails = {
  donationCurrencyCode?: InputMaybe<Scalars['String']>;
  donationTotal?: InputMaybe<Scalars['Float']>;
  donationValue?: InputMaybe<Scalars['Float']>;
  donationValuePrivacy?: InputMaybe<Scalars['String']>;
  tipScheme?: InputMaybe<Scalars['String']>;
};

export type DonationCause = {
  __typename?: 'DonationCause';
  causeId: Scalars['ID'];
  displayHideAmountOption: Scalars['Boolean'];
  id: Scalars['ID'];
  isBbms: Scalars['Boolean'];
  isExternalGiftAidProviderEnabled?: Maybe<Scalars['Boolean']>;
  marketCode: MarketCode;
  settlementCurrency: AcceptedCurrencyCode;
  sourceRef: Scalars['String'];
  sourceType?: Maybe<PageType>;
  sourceUrl?: Maybe<Scalars['URL']>;
  supportsGiftAid: Scalars['Boolean'];
  supportsGuest?: Maybe<Scalars['Boolean']>;
  supportsRecurringPayments?: Maybe<Scalars['Boolean']>;
  tipScheme?: Maybe<TipScheme>;
  voluntaryContributionConfig?: Maybe<Array<Maybe<VoluntaryContributionConfig>>>;
};

export type DonationConnection = {
  __typename?: 'DonationConnection';
  edges: Array<DonationEdge>;
  nodes: Array<Donation>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type DonationDeepLinkParameters = {
  displayHideAmount?: InputMaybe<Scalars['Boolean']>;
  donationAmount?: InputMaybe<DonationAmountDetails>;
  isRecurring?: InputMaybe<Scalars['Boolean']>;
  lockAmount?: InputMaybe<Scalars['Boolean']>;
  paymentMethod?: InputMaybe<Scalars['String']>;
  skipGiftAid?: InputMaybe<Scalars['Boolean']>;
  suggestedAmountDescriptions?: InputMaybe<Array<InputMaybe<SuggestedAmountDescription>>>;
  suggestedAmounts?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  supportedCurrencies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  supportsGiftAid?: InputMaybe<Scalars['Boolean']>;
  supportsGuest?: InputMaybe<Scalars['Boolean']>;
};

export type DonationEdge = {
  __typename?: 'DonationEdge';
  cursor: Scalars['String'];
  direct: Scalars['Boolean'];
  node: Donation;
};

export type DonationItemDetails = {
  __typename?: 'DonationItemDetails';
  cause?: Maybe<DonationCause>;
  displayName?: Maybe<Scalars['String']>;
  isAmountPrivate: Scalars['Boolean'];
  isAnonymous?: Maybe<Scalars['Boolean']>;
  isRecurring: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  page?: Maybe<Page>;
  recurrenceDayOfMonth?: Maybe<Scalars['String']>;
  recurrenceNextPaymentDate?: Maybe<Scalars['String']>;
};

export type DonationLink = Node & {
  __typename?: 'DonationLink';
  author?: Maybe<Author>;
  charityId: Scalars['Int'];
  createDate?: Maybe<Scalars['DateTime']>;
  donationLinkId: Scalars['String'];
  id: Scalars['ID'];
  isArchived: Scalars['Boolean'];
  isDefault: Scalars['Boolean'];
  name: Scalars['String'];
  parameters: DonationLinkParameters;
  totalRaised?: Maybe<Total>;
  url: Scalars['String'];
};

export type DonationLinkConnection = {
  __typename?: 'DonationLinkConnection';
  edges: Array<DonationLinkEdge>;
  pageInfo: ForwardOnlyPageInfo;
};

export type DonationLinkEdge = {
  __typename?: 'DonationLinkEdge';
  node: DonationLink;
};

export type DonationLinkInput = {
  donationLinkId: Scalars['String'];
};

export type DonationLinkParameters = {
  __typename?: 'DonationLinkParameters';
  currencyCode?: Maybe<AcceptedCurrencyCode>;
  defaultAmount?: Maybe<Scalars['MonetaryValue']>;
  exitUrl?: Maybe<Scalars['String']>;
  recurring: Scalars['Boolean'];
  reference?: Maybe<Scalars['String']>;
  suggestedAmounts?: Maybe<Array<DonationLinkSuggestedAmount>>;
};

export type DonationLinkParametersInput = {
  currencyCode: AcceptedCurrencyCode;
  defaultAmount?: InputMaybe<Scalars['MonetaryValue']>;
  exitUrl?: InputMaybe<Scalars['String']>;
  recurring: Scalars['Boolean'];
  reference?: InputMaybe<Scalars['String']>;
  suggestedAmounts: Array<DonationLinkSuggestedAmountInput>;
};

export type DonationLinkSuggestedAmount = {
  __typename?: 'DonationLinkSuggestedAmount';
  amount: Scalars['MonetaryValue'];
  description?: Maybe<Scalars['String']>;
};

export type DonationLinkSuggestedAmountInput = {
  amount: Scalars['MonetaryValue'];
  description?: InputMaybe<Scalars['String']>;
};

export type DonationLinksInput = {
  after?: InputMaybe<Scalars['String']>;
  archived?: InputMaybe<Scalars['Boolean']>;
  first?: InputMaybe<Scalars['Int']>;
};

export type DonationMatch = {
  __typename?: 'DonationMatch';
  amount: MonetaryAmount;
  /** @deprecated Experimental Feature - may be removed without warning */
  matchingDonorAvatar?: Maybe<Scalars['String']>;
  /** @deprecated Experimental Feature - may be removed without warning */
  matchingDonorName?: Maybe<Scalars['String']>;
  type: DonationMatchType;
};

export enum DonationMatchType {
  CompanyContribution = 'COMPANY_CONTRIBUTION',
  DirectGiftAid = 'DIRECT_GIFT_AID',
  GiftAid = 'GIFT_AID',
  ThirdParty = 'THIRD_PARTY',
}

export type DonationMessageEnhanceInput = {
  causeTitle?: InputMaybe<Scalars['String']>;
  fundraiserName?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  tones: Array<Scalars['String']>;
};

export enum DonationMethod {
  Children = 'CHILDREN',
  Direct = 'DIRECT',
  Text = 'TEXT',
}

export type DonationPrompt = {
  __typename?: 'DonationPrompt';
  amount?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type DonationPublicReadModel = {
  __typename?: 'DonationPublicReadModel';
  id: Scalars['String'];
};

export type DonationRequestUris = {
  exitUri: Scalars['String'];
  failureUri: Scalars['String'];
  successUri: Scalars['String'];
};

export type DonationStats = {
  __typename?: 'DonationStats';
  numberOfDonations?: Maybe<Scalars['Int']>;
  numberOfDonationsFRP?: Maybe<Scalars['Int']>;
  numberOfDonationsNonFRP?: Maybe<Scalars['Int']>;
  settlementCurrencyCode?: Maybe<Scalars['CurrencyCode']>;
  totalDonationAmount?: Maybe<Scalars['MonetaryValue']>;
  totalDonationAmountFRP?: Maybe<Scalars['MonetaryValue']>;
  totalDonationAmountNonFRP?: Maybe<Scalars['MonetaryValue']>;
  totalGiftAidClaimed?: Maybe<Scalars['MonetaryValue']>;
  totalGiftAidClaimedFRP?: Maybe<Scalars['MonetaryValue']>;
  totalGiftAidClaimedNonFRP?: Maybe<Scalars['MonetaryValue']>;
};

export type DonationSummary = {
  __typename?: 'DonationSummary';
  aggregatedDonationsCount?: Maybe<Scalars['Int']>;
  directDonationAmount?: Maybe<MonetaryAmount>;
  donationCount?: Maybe<Scalars['Int']>;
  fundraiserDonationsCount?: Maybe<Scalars['Int']>;
  fundraiserRaisedAmount?: Maybe<MonetaryAmount>;
  offlineAmount?: Maybe<MonetaryAmount>;
  offlineAmountWithConversions?: Maybe<MoneyBag>;
  offlineDonationCount?: Maybe<Scalars['Int']>;
  onlineAmount?: Maybe<MonetaryAmount>;
  onlineAmountWithConversions?: Maybe<MoneyBag>;
  onlineDonationCount?: Maybe<Scalars['Int']>;
  textDonations?: Maybe<MonetaryAmount>;
  totalAmount?: Maybe<MonetaryAmount>;
  totalAmountWithConversions?: Maybe<MoneyBag>;
  totalMatched: Array<DonationMatch>;
};

export type DonationSummaryDisplayOptionsInput = {
  currencyCode?: InputMaybe<Scalars['String']>;
};

export enum DonationType {
  Offline = 'OFFLINE',
  Online = 'ONLINE',
}

export type DonorDetailsInput = {
  address?: InputMaybe<AddressInput>;
  emailAddress?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type DonorDonationSummary = {
  __typename?: 'DonorDonationSummary';
  totalAmount?: Maybe<MonetaryAmount>;
  totalGiftAidAmount?: Maybe<MonetaryAmount>;
};

export type Duration = {
  __typename?: 'Duration';
  unit: TimeUnit;
  value: Scalars['FixedFloat'];
};

export type EditDonationLinkInput = {
  donationLinkId: Scalars['String'];
  name: Scalars['String'];
  parameters: DonationLinkParametersInput;
};

export type EditOfflineDonationInput = {
  amount: Scalars['MonetaryValue'];
  currency: Scalars['CurrencyCode'];
  displayName?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  offlineDonationId: Scalars['String'];
  pageId: Scalars['String'];
};

export type EmailBrandingInput = {
  brandingTemplateId: Scalars['String'];
  emailCampaignCoverPhoto?: InputMaybe<Scalars['String']>;
  emailCampaignLogo?: InputMaybe<Scalars['String']>;
};

export type EnhancedDonationMessage = {
  __typename?: 'EnhancedDonationMessage';
  message: Scalars['String'];
};

export type EnhancedStory = {
  __typename?: 'EnhancedStory';
  story: Scalars['String'];
  tones: Array<Tone>;
};

export type Entitlements = {
  __typename?: 'Entitlements';
  hasCampaignFundraisingPageRedirect?: Maybe<Scalars['Boolean']>;
  hasCampaignPageRedirect?: Maybe<Scalars['Boolean']>;
  hasCampaignStartFundraisingRedirect?: Maybe<Scalars['Boolean']>;
  hasLinkLibrary?: Maybe<Scalars['Boolean']>;
  hasPoweredBy?: Maybe<Scalars['Boolean']>;
};

export enum EntityType {
  Charity = 'Charity',
  Corporate = 'Corporate',
  Fundraiser = 'Fundraiser',
  JustGiving = 'JustGiving',
}

export type Event = Node & {
  __typename?: 'Event';
  activities?: Maybe<Array<FitnessApplicationActivityType>>;
  allowManualActivities?: Maybe<Scalars['Boolean']>;
  endDate?: Maybe<Scalars['DateTime']>;
  eventCategory?: Maybe<Scalars['String']>;
  externalFitnessSources?: Maybe<Array<ExternalFitnessSources>>;
  fundraiserGoals?: Maybe<Array<Goal>>;
  fundraisersCount?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  isCreatedByUser?: Maybe<Scalars['Boolean']>;
  legacyId: Scalars['String'];
  startDate?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  visible: Scalars['Boolean'];
};

export type EventConnection = {
  __typename?: 'EventConnection';
  edges: Array<EventEdge>;
  nodes: Array<Event>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type EventEdge = {
  __typename?: 'EventEdge';
  cursor: Scalars['String'];
  node: Event;
};

export enum ExternalFitnessSources {
  StravaAuto = 'STRAVA_AUTO',
  StravaManual = 'STRAVA_MANUAL',
}

export type ExternalRef = {
  name?: InputMaybe<Scalars['String']>;
  ref?: InputMaybe<Scalars['String']>;
};

export type Fees = {
  __typename?: 'Fees';
  platformFeePercentage?: Maybe<Scalars['Float']>;
};

export type Fitbit = {
  __typename?: 'Fitbit';
  isConnected?: Maybe<Scalars['Boolean']>;
};

export type FitnessActivity = {
  __typename?: 'FitnessActivity';
  activityId?: Maybe<Scalars['String']>;
  activityType?: Maybe<FitnessApplicationActivityType>;
  distance?: Maybe<Distance>;
  duration?: Maybe<Duration>;
  elevation?: Maybe<Distance>;
  /** @deprecated Use `activityId` as it matches the property name of the model and `id` can be confused as a `Node.ID`. */
  id?: Maybe<Scalars['String']>;
  mapPolyline?: Maybe<Scalars['String']>;
  steps?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
};

export type FitnessActivityDistanceArgs = {
  measurementSystem?: InputMaybe<MeasurementSystem>;
};

export type FitnessActivityDurationArgs = {
  unit?: InputMaybe<TimeUnit>;
};

export type FitnessActivityElevationArgs = {
  measurementSystem?: InputMaybe<MeasurementSystem>;
};

export type FitnessActivityInput = {
  activityType: FitnessApplicationActivityType;
  distance?: InputMaybe<Scalars['FixedFloat']>;
  duration?: InputMaybe<Scalars['FixedFloat']>;
  elevation?: InputMaybe<Scalars['FixedFloat']>;
  mapPolyline?: InputMaybe<Scalars['String']>;
  steps?: InputMaybe<Scalars['Int']>;
  title: Scalars['String'];
};

export type FitnessApplication = {
  __typename?: 'FitnessApplication';
  name?: Maybe<Scalars['String']>;
  settings?: Maybe<FitnessApplicationSettings>;
};

/** Fitness applications */
export enum FitnessApplicationActivityType {
  Hike = 'HIKE',
  Ride = 'RIDE',
  Run = 'RUN',
  Swim = 'SWIM',
  Walk = 'WALK',
  Wheelchair = 'WHEELCHAIR',
}

export type FitnessApplicationSettings = {
  __typename?: 'FitnessApplicationSettings';
  measurementSystem?: Maybe<MeasurementSystem>;
  showDistance?: Maybe<Scalars['Boolean']>;
  showDuration?: Maybe<Scalars['Boolean']>;
  showElevation?: Maybe<Scalars['Boolean']>;
  showMap?: Maybe<Scalars['Boolean']>;
  showPhotos?: Maybe<Scalars['Boolean']>;
  showTotaliser?: Maybe<Scalars['Boolean']>;
  subscribedActivities?: Maybe<Array<Maybe<FitnessApplicationActivityType>>>;
};

export type FitnessApplicationSettingsInput = {
  measurementSystem?: InputMaybe<MeasurementSystem>;
  name: Scalars['String'];
  pageId: Scalars['ID'];
  showDistance?: InputMaybe<Scalars['Boolean']>;
  showDuration?: InputMaybe<Scalars['Boolean']>;
  showElevation?: InputMaybe<Scalars['Boolean']>;
  showMap?: InputMaybe<Scalars['Boolean']>;
  showPhotos?: InputMaybe<Scalars['Boolean']>;
  showTotaliser?: InputMaybe<Scalars['Boolean']>;
  subscribedActivities?: InputMaybe<Array<InputMaybe<FitnessApplicationActivityType>>>;
};

export type FitnessGoal = {
  __typename?: 'FitnessGoal';
  activities?: Maybe<Array<FitnessApplicationActivityType>>;
  endDate?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  targetAmount?: Maybe<Scalars['Float']>;
  targetMetric?: Maybe<TargetMetric>;
  unitOfMeasure?: Maybe<UnitOfMeasure>;
};

export type FitnessGoalInput = {
  targetAmount?: InputMaybe<Scalars['Float']>;
  unitOfMeasure: UnitOfMeasure;
};

export type FitnessTotalsInput = {
  metric: Scalars['String'];
  total: Scalars['Float'];
};

/** Forward-only pagination page information. */
export type ForwardOnlyPageInfo = {
  __typename?: 'ForwardOnlyPageInfo';
  /** The cursor for the last edge on the current page. */
  endCursor?: Maybe<Scalars['String']>;
  /** `true` if it is possible to navigate to the next page. */
  hasNextPage: Scalars['Boolean'];
};

export enum FundraiserFilterType {
  Donations = 'Donations',
  PagesCreated = 'PagesCreated',
  PagesExpiring = 'PagesExpiring',
  PagesWithEvent = 'PagesWithEvent',
}

export type FundraisingDefaults = {
  __typename?: 'FundraisingDefaults';
  cover?: Maybe<Media>;
  story?: Maybe<Story>;
  targetAmount?: Maybe<MonetaryAmount>;
  title?: Maybe<Scalars['String']>;
};

export type FundraisingPageDonationsCsvInput = {
  externalId?: InputMaybe<Scalars['String']>;
  pageGuid: Scalars['String'];
};

export type FundraisingPageDonationsCsvPayload = {
  __typename?: 'FundraisingPageDonationsCsvPayload';
  signedURL: Scalars['String'];
};

export type FundraisingSettings = {
  __typename?: 'FundraisingSettings';
  defaults: FundraisingDefaults;
  fundraisingPageUrl?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  isTargetHidden?: Maybe<Scalars['Boolean']>;
  pageId: Scalars['UUID'];
  thankYouMessages: Array<Maybe<ThankYouMessage>>;
  totaliserLabel?: Maybe<Scalars['String']>;
  totaliserTheme?: Maybe<TotaliserTheme>;
};

export type FundraisingSettingsDefaultsInput = {
  coverMediaCollectionId?: InputMaybe<Scalars['ID']>;
  heroImage?: InputMaybe<MediaItemInput>;
  story?: InputMaybe<Scalars['String']>;
  target?: InputMaybe<MonetaryAmountInput>;
  targetAmount?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
};

export type FundraisingSettingsInput = {
  defaults?: InputMaybe<FundraisingSettingsDefaultsInput>;
  fundraisingPageUrl?: InputMaybe<Scalars['String']>;
  hasDefaults?: InputMaybe<Scalars['Boolean']>;
  isBranded?: InputMaybe<Scalars['Boolean']>;
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  isFundraisingPageRedirectEnabled?: InputMaybe<Scalars['Boolean']>;
  isStartFundraisingUrlEnabled?: InputMaybe<Scalars['Boolean']>;
  isTargetHidden?: InputMaybe<Scalars['Boolean']>;
  startFundraisingUrl?: InputMaybe<Scalars['String']>;
  thankYouMessages?: InputMaybe<Array<ThankYouMessageInput>>;
};

export type Funds = {
  __typename?: 'Funds';
  accounts?: Maybe<Array<Account>>;
  withdrawals?: Maybe<WithdrawalConnection>;
};

export type FundsInput = {
  slug?: InputMaybe<Scalars['Slug']>;
  type?: InputMaybe<PageType>;
};

export enum GetChildrenOrderByArg {
  CreatedDateAsc = 'CreatedDateAsc',
  CreatedDateDesc = 'CreatedDateDesc',
}

export type GiftAid = {
  __typename?: 'GiftAid';
  id: Scalars['ID'];
  isClaimed?: Maybe<Scalars['Boolean']>;
  isExternalGiftAidProviderEnabled: Scalars['Boolean'];
  isMatchedInExternalGiftAidProvider?: Maybe<Scalars['Boolean']>;
  ratePercent: Scalars['Float'];
  taxPayerDetails?: Maybe<TaxPayerDetails>;
};

export type GiftAidDetailsInput = {
  address: AddressInput;
  firstName: Scalars['String'];
  isOverseas: Scalars['Boolean'];
  lastName: Scalars['String'];
};

export type Goal = FitnessGoal;

export type Hero = {
  __typename?: 'Hero';
  imageTitle?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['URL']>;
};

export type Image = {
  __typename?: 'Image';
  alt?: Maybe<Scalars['String']>;
  url: Scalars['String'];
};

export type ImageConnection = {
  __typename?: 'ImageConnection';
  edges: Array<ImageEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type ImageEdge = {
  __typename?: 'ImageEdge';
  node: Image;
};

export type ImageMedia = Node & {
  __typename?: 'ImageMedia';
  alt?: Maybe<Scalars['String']>;
  caption?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  legacyId?: Maybe<Scalars['Int']>;
  url?: Maybe<Scalars['URL']>;
};

export type ImageMediaInput = {
  alt?: InputMaybe<Scalars['String']>;
  caption?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  isHero?: InputMaybe<Scalars['Boolean']>;
  url?: InputMaybe<Scalars['URL']>;
};

export type InMemory = {
  __typename?: 'InMemory';
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  firstNameOfDeceased: Scalars['String'];
  lastNameOfDeceased: Scalars['String'];
};

export type InitialiseCheckout3DsInput = {
  checkoutId: Scalars['ID'];
  deviceType: DeviceType;
};

export type InitialiseCheckoutGoCardlessInstantBankPaymentInput = {
  abTestUserId?: InputMaybe<Scalars['String']>;
  checkoutId: Scalars['String'];
  deviceType: DeviceType;
  emailAddress: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  preferences?: InputMaybe<PreferencesInput>;
  privacyPolicyVersionAccepted: Scalars['String'];
  termsAndConditionsVersionAccepted: Scalars['String'];
};

export type InitialiseCheckoutPaymentOrchestratorTransactionInput = {
  checkoutId: Scalars['String'];
  deviceType: DeviceType;
  preferences?: InputMaybe<PreferencesInput>;
  privacyPolicyVersionAccepted: Scalars['String'];
  sessionInformation?: InputMaybe<SessionInformationInput>;
  termsAndConditionsVersionAccepted: Scalars['String'];
};

export type InitialiseCheckoutWalletTokenInput = {
  checkoutId: Scalars['ID'];
  paymentServiceProvider: PaymentServiceProvider;
};

export type JoinTeamInput = {
  /** ID of a page */
  id: Scalars['ID'];
  /** ID of the team to join */
  teamId: Scalars['ID'];
  /** Type of page */
  type: PageType;
};

export type JoinTeamPayload = {
  __typename?: 'JoinTeamPayload';
  /** The page that is joining */
  page?: Maybe<PageWithId>;
};

export type LayoutFeature = {
  __typename?: 'LayoutFeature';
  CauseMiniEnabled: Scalars['Boolean'];
};

export type Leaderboard = {
  __typename?: 'Leaderboard';
  definition?: Maybe<LeaderboardDefinition>;
  id: Scalars['LeaderboardId'];
  /** @deprecated Use totalsWithPagination */
  totals: Array<LeaderboardTotal>;
  totalsWithPagination?: Maybe<LeaderboardTotalConnection>;
};

export type LeaderboardTotalsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
};

export type LeaderboardTotalsWithPaginationArgs = {
  after?: InputMaybe<Scalars['String']>;
  first: Scalars['Int'];
};

export type LeaderboardDefinition = {
  __typename?: 'LeaderboardDefinition';
  conditions: Array<Tag>;
  measurementDomain: Scalars['String'];
  measurementDomains: Array<Scalars['String']>;
  primaryUnit: Scalars['String'];
  segment: Scalars['String'];
  tagDefinition: TagDefinition;
};

export type LeaderboardTotal = {
  __typename?: 'LeaderboardTotal';
  amounts: Array<TotalAmount>;
  tagValue: Scalars['String'];
  tagValueAsNode?: Maybe<Node>;
};

export type LeaderboardTotalConnection = {
  __typename?: 'LeaderboardTotalConnection';
  edges: Array<LeaderboardTotal>;
  pageInfo: ForwardOnlyPageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type Lifecycle = {
  __typename?: 'Lifecycle';
  allowedActions?: Maybe<Array<Maybe<Scalars['String']>>>;
  expiryDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<LifecycleStatus>;
  updatedDate?: Maybe<Scalars['DateTime']>;
};

export enum LifecycleStatus {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Draft = 'DRAFT',
  Live = 'LIVE',
}

export type LineItem = {
  __typename?: 'LineItem';
  details?: Maybe<LineItemDetails>;
  id: Scalars['ID'];
  image?: Maybe<Scalars['String']>;
  lineItemId: Scalars['ID'];
  name: Scalars['String'];
  sourceUrl?: Maybe<Scalars['URL']>;
  type: LineItemType;
  value?: Maybe<Scalars['MonetaryValue']>;
};

export type LineItemDetails = DonationItemDetails | ProductItemDetails;

export enum LineItemType {
  DonationCampaignDirect = 'DonationCampaignDirect',
  DonationCampaignFrp = 'DonationCampaignFRP',
  DonationCharityDirect = 'DonationCharityDirect',
  DonationCharityFrp = 'DonationCharityFRP',
  DonationCrowdfunding = 'DonationCrowdfunding',
}

export type Link = {
  __typename?: 'Link';
  type?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type LinkPageToCampaignInput = {
  campaignId: Scalars['String'];
  pageSlug: Scalars['String'];
};

export type Livestream = {
  __typename?: 'Livestream';
  entries?: Maybe<Array<Maybe<LivestreamEntries>>>;
  mediaId: Scalars['String'];
  pageId: Scalars['String'];
  pageType?: Maybe<PageType>;
};

export type LivestreamEntries = {
  __typename?: 'LivestreamEntries';
  isLive?: Maybe<Scalars['Boolean']>;
  mediaType?: Maybe<Scalars['String']>;
  positionId?: Maybe<Scalars['Int']>;
  streamId?: Maybe<Scalars['String']>;
  streamType?: Maybe<Scalars['String']>;
};

export type LivestreamMedia = Node & {
  __typename?: 'LivestreamMedia';
  embeddedStreamChatURL?: Maybe<Scalars['URL']>;
  embeddedStreamURL?: Maybe<Scalars['URL']>;
  id: Scalars['ID'];
  legacyId?: Maybe<Scalars['Int']>;
  /** @deprecated Use `streamPlatform` with the type LivestreamMediaPlatform */
  platform?: Maybe<Scalars['String']>;
  posterUrl?: Maybe<Scalars['URL']>;
  /** @deprecated Use `url` to send the whole URL */
  streamId?: Maybe<Scalars['String']>;
  /** @deprecated Use `url` to send the whole URL */
  streamPlatform?: Maybe<LivestreamMediaPlatform>;
  url?: Maybe<Scalars['URL']>;
};

export type LivestreamMediaInput = {
  alt?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  isHero?: InputMaybe<Scalars['Boolean']>;
  platform?: InputMaybe<LivestreamMediaPlatform>;
  streamId?: InputMaybe<Scalars['ID']>;
  streamPlatform?: InputMaybe<LivestreamMediaPlatform>;
  url?: InputMaybe<Scalars['URL']>;
};

export enum LivestreamMediaPlatform {
  Twitch = 'TWITCH',
  Youtube = 'YOUTUBE',
}

export type LivestreamMetaData = {
  __typename?: 'LivestreamMetaData';
  isLive: Scalars['Boolean'];
  mediaId: Scalars['String'];
  pageId: Scalars['String'];
  pageType?: Maybe<PageType>;
  pageUrl: Scalars['String'];
  streamDescription: Scalars['String'];
  streamPreview: Scalars['String'];
  streamTitle: Scalars['String'];
  streamUrl: Scalars['String'];
  streamUserId: Scalars['String'];
  viewerCount: Scalars['Int'];
};

export enum Locale {
  /** Andorra = AD */
  Ad = 'AD',
  /** United Arab Emirates = AE */
  Ae = 'AE',
  /** Afghanistan = AF */
  Af = 'AF',
  /** Antigua And Barbuda = AG */
  Ag = 'AG',
  /** Anguilla = AI */
  Ai = 'AI',
  /** Albania = AL */
  Al = 'AL',
  /** Armenia = AM */
  Am = 'AM',
  /** Netherlands Antilles = AN */
  An = 'AN',
  /** Angola = AO */
  Ao = 'AO',
  /** Antarctica = AQ */
  Aq = 'AQ',
  /** Argentina = AR */
  Ar = 'AR',
  /** American Samoa = AS */
  As = 'AS',
  /** Austria = AT */
  At = 'AT',
  /** Australia = AU */
  Au = 'AU',
  /** Aruba = AW */
  Aw = 'AW',
  /** Aland Islands = AX */
  Ax = 'AX',
  /** Azerbaijan = AZ */
  Az = 'AZ',
  /** Bosnia And Herzegovina = BA */
  Ba = 'BA',
  /** Barbados = BB */
  Bb = 'BB',
  /** Bangladesh = BD */
  Bd = 'BD',
  /** Belgium = BE */
  Be = 'BE',
  /** Burkina Faso = BF */
  Bf = 'BF',
  /** Bulgaria = BG */
  Bg = 'BG',
  /** Bahrain = BH */
  Bh = 'BH',
  /** Burundi = BI */
  Bi = 'BI',
  /** Benin = BJ */
  Bj = 'BJ',
  /** Saint Barthélemy = BL */
  Bl = 'BL',
  /** Bermuda = BM */
  Bm = 'BM',
  /** Brunei = BN */
  Bn = 'BN',
  /** Bolivia = BO */
  Bo = 'BO',
  /** Caribbean Netherlands = BQ */
  Bq = 'BQ',
  /** Brazil = BR */
  Br = 'BR',
  /** Bahamas = BS */
  Bs = 'BS',
  /** Bhutan = BT */
  Bt = 'BT',
  /** Bouvet Island = BV */
  Bv = 'BV',
  /** Botswana = BW */
  Bw = 'BW',
  /** Belarus = BY */
  By = 'BY',
  /** Belize = BZ */
  Bz = 'BZ',
  /** Canada = CA */
  Ca = 'CA',
  /** Cocos (Keeling) Islands = CC */
  Cc = 'CC',
  /** Congo, The Democratic Republic Of The = CD */
  Cd = 'CD',
  /** Central African Republic = CF */
  Cf = 'CF',
  /** Congo = CG */
  Cg = 'CG',
  /** Switzerland = CH */
  Ch = 'CH',
  /** Côte d'Ivoire = CI */
  Ci = 'CI',
  /** Cook Islands = CK */
  Ck = 'CK',
  /** Chile = CL */
  Cl = 'CL',
  /** Republic of Cameroon = CM */
  Cm = 'CM',
  /** China = CN */
  Cn = 'CN',
  /** Colombia = CO */
  Co = 'CO',
  /** Costa Rica = CR */
  Cr = 'CR',
  /** Cuba = CU */
  Cu = 'CU',
  /** Cape Verde = CV */
  Cv = 'CV',
  /** Curaçao = CW */
  Cw = 'CW',
  /** Christmas Island = CX */
  Cx = 'CX',
  /** Cyprus = CY */
  Cy = 'CY',
  /** Czech Republic = CZ */
  Cz = 'CZ',
  /** Germany = DE */
  De = 'DE',
  /** Djibouti = DJ */
  Dj = 'DJ',
  /** Denmark = DK */
  Dk = 'DK',
  /** Dominica = DM */
  Dm = 'DM',
  /** Dominican Republic = DO */
  Do = 'DO',
  /** Algeria = DZ */
  Dz = 'DZ',
  /** Ecuador = EC */
  Ec = 'EC',
  /** Estonia = EE */
  Ee = 'EE',
  /** Egypt = EG */
  Eg = 'EG',
  /** Western Sahara = EH */
  Eh = 'EH',
  /** Eritrea = ER */
  Er = 'ER',
  /** Spain = ES */
  Es = 'ES',
  /** Ethiopia = ET */
  Et = 'ET',
  /** Finland = FI */
  Fi = 'FI',
  /** Fiji = FJ */
  Fj = 'FJ',
  /** Falkland Islands (Malvinas) = FK */
  Fk = 'FK',
  /** Micronesia, Federated States of = FM */
  Fm = 'FM',
  /** Faroe Islands = FO */
  Fo = 'FO',
  /** France = FR */
  Fr = 'FR',
  /** Gabon = GA */
  Ga = 'GA',
  /** United Kingdom = GB */
  Gb = 'GB',
  /** Grenada = GD */
  Gd = 'GD',
  /** Georgia = GE */
  Ge = 'GE',
  /** French Guiana = GF */
  Gf = 'GF',
  /** Guernsey = GG */
  Gg = 'GG',
  /** Ghana = GH */
  Gh = 'GH',
  /** Gibraltar = GI */
  Gi = 'GI',
  /** Greenland = GL */
  Gl = 'GL',
  /** Gambia = GM */
  Gm = 'GM',
  /** Guinea = GN */
  Gn = 'GN',
  /** Guadeloupe = GP */
  Gp = 'GP',
  /** Equatorial Guinea = GQ */
  Gq = 'GQ',
  /** Greece = GR */
  Gr = 'GR',
  /** South Georgia And The South Sandwich Islands = GS */
  Gs = 'GS',
  /** Guatemala = GT */
  Gt = 'GT',
  /** Guam = GU */
  Gu = 'GU',
  /** Guinea Bissau = GW */
  Gw = 'GW',
  /** Guyana = GY */
  Gy = 'GY',
  /** Hong Kong = HK */
  Hk = 'HK',
  /** Heard Island And Mcdonald Islands = HM */
  Hm = 'HM',
  /** Honduras = HN */
  Hn = 'HN',
  /** Croatia = HR */
  Hr = 'HR',
  /** Haiti = HT */
  Ht = 'HT',
  /** Hungary = HU */
  Hu = 'HU',
  /** Indonesia = ID */
  Id = 'ID',
  /** Ireland = IE */
  Ie = 'IE',
  /** Israel = IL */
  Il = 'IL',
  /** Isle Of Man = IM */
  Im = 'IM',
  /** India = IN */
  In = 'IN',
  /** British Indian Ocean Territory = IO */
  Io = 'IO',
  /** Iraq = IQ */
  Iq = 'IQ',
  /** Iran, Islamic Republic Of = IR */
  Ir = 'IR',
  /** Iceland = IS */
  Is = 'IS',
  /** Italy = IT */
  It = 'IT',
  /** Jersey = JE */
  Je = 'JE',
  /** Jamaica = JM */
  Jm = 'JM',
  /** Jordan = JO */
  Jo = 'JO',
  /** Japan = JP */
  Jp = 'JP',
  /** Kenya = KE */
  Ke = 'KE',
  /** Kyrgyzstan = KG */
  Kg = 'KG',
  /** Cambodia = KH */
  Kh = 'KH',
  /** Kiribati = KI */
  Ki = 'KI',
  /** Comoros = KM */
  Km = 'KM',
  /** Saint Kitts And Nevis = KN */
  Kn = 'KN',
  /** Korea, Democratic People's Republic Of = KP */
  Kp = 'KP',
  /** South Korea = KR */
  Kr = 'KR',
  /** Kuwait = KW */
  Kw = 'KW',
  /** Cayman Islands = KY */
  Ky = 'KY',
  /** Kazakhstan = KZ */
  Kz = 'KZ',
  /** Lao People's Democratic Republic = LA */
  La = 'LA',
  /** Lebanon = LB */
  Lb = 'LB',
  /** Saint Lucia = LC */
  Lc = 'LC',
  /** Liechtenstein = LI */
  Li = 'LI',
  /** Sri Lanka = LK */
  Lk = 'LK',
  /** Liberia = LR */
  Lr = 'LR',
  /** Lesotho = LS */
  Ls = 'LS',
  /** Lithuania = LT */
  Lt = 'LT',
  /** Luxembourg = LU */
  Lu = 'LU',
  /** Latvia = LV */
  Lv = 'LV',
  /** Libyan Arab Jamahiriya = LY */
  Ly = 'LY',
  /** Morocco = MA */
  Ma = 'MA',
  /** Monaco = MC */
  Mc = 'MC',
  /** Moldova, Republic of = MD */
  Md = 'MD',
  /** Montenegro = ME */
  Me = 'ME',
  /** Saint Martin = MF */
  Mf = 'MF',
  /** Madagascar = MG */
  Mg = 'MG',
  /** Marshall Islands, Republic of the = MH */
  Mh = 'MH',
  /** North Macedonia = MK */
  Mk = 'MK',
  /** Mali = ML */
  Ml = 'ML',
  /** Myanmar = MM */
  Mm = 'MM',
  /** Mongolia = MN */
  Mn = 'MN',
  /** Macao = MO */
  Mo = 'MO',
  /** Northern Mariana Islands = MP */
  Mp = 'MP',
  /** Martinique = MQ */
  Mq = 'MQ',
  /** Mauritania = MR */
  Mr = 'MR',
  /** Montserrat = MS */
  Ms = 'MS',
  /** Malta = MT */
  Mt = 'MT',
  /** Mauritius = MU */
  Mu = 'MU',
  /** Maldives = MV */
  Mv = 'MV',
  /** Malawi = MW */
  Mw = 'MW',
  /** Mexico = MX */
  Mx = 'MX',
  /** Malaysia = MY */
  My = 'MY',
  /** Mozambique = MZ */
  Mz = 'MZ',
  /** Namibia = NA */
  Na = 'NA',
  /** New Caledonia = NC */
  Nc = 'NC',
  /** Niger = NE */
  Ne = 'NE',
  /** Norfolk Island = NF */
  Nf = 'NF',
  /** Nigeria = NG */
  Ng = 'NG',
  /** Nicaragua = NI */
  Ni = 'NI',
  /** Netherlands = NL */
  Nl = 'NL',
  /** Norway = NO */
  No = 'NO',
  /** Nepal = NP */
  Np = 'NP',
  /** Nauru = NR */
  Nr = 'NR',
  /** Niue = NU */
  Nu = 'NU',
  /** New Zealand = NZ */
  Nz = 'NZ',
  /** Oman = OM */
  Om = 'OM',
  /** Panama = PA */
  Pa = 'PA',
  /** Peru = PE */
  Pe = 'PE',
  /** French Polynesia = PF */
  Pf = 'PF',
  /** Papua New Guinea = PG */
  Pg = 'PG',
  /** Philippines = PH */
  Ph = 'PH',
  /** Pakistan = PK */
  Pk = 'PK',
  /** Poland = PL */
  Pl = 'PL',
  /** Saint Pierre And Miquelon = PM */
  Pm = 'PM',
  /** Pitcairn = PN */
  Pn = 'PN',
  /** Puerto Rico = PR */
  Pr = 'PR',
  /** Palestinian Territory, Occupied = PS */
  Ps = 'PS',
  /** Portugal = PT */
  Pt = 'PT',
  /** Palau, Republic of = PW */
  Pw = 'PW',
  /** Paraguay = PY */
  Py = 'PY',
  /** Qatar = QA */
  Qa = 'QA',
  /** Reunion = RE */
  Re = 'RE',
  /** Romania = RO */
  Ro = 'RO',
  /** Serbia = RS */
  Rs = 'RS',
  /** Russia = RU */
  Ru = 'RU',
  /** Rwanda = RW */
  Rw = 'RW',
  /** Saudi Arabia = SA */
  Sa = 'SA',
  /** Solomon Islands = SB */
  Sb = 'SB',
  /** Seychelles = SC */
  Sc = 'SC',
  /** Sudan = SD */
  Sd = 'SD',
  /** Sweden = SE */
  Se = 'SE',
  /** Singapore = SG */
  Sg = 'SG',
  /** Saint Helena = SH */
  Sh = 'SH',
  /** Slovenia = SI */
  Si = 'SI',
  /** Svalbard And Jan Mayen = SJ */
  Sj = 'SJ',
  /** Slovakia = SK */
  Sk = 'SK',
  /** Sierra Leone = SL */
  Sl = 'SL',
  /** San Marino = SM */
  Sm = 'SM',
  /** Senegal = SN */
  Sn = 'SN',
  /** Somalia = SO */
  So = 'SO',
  /** Suriname = SR */
  Sr = 'SR',
  /** South Sudan = SS */
  Ss = 'SS',
  /** Sao Tome And Principe = ST */
  St = 'ST',
  /** El Salvador = SV */
  Sv = 'SV',
  /** Sint Maarten = SX */
  Sx = 'SX',
  /** Syria = SY */
  Sy = 'SY',
  /** Eswatini = SZ */
  Sz = 'SZ',
  /** Turks and Caicos Islands = TC */
  Tc = 'TC',
  /** Chad = TD */
  Td = 'TD',
  /** French Southern Territories = TF */
  Tf = 'TF',
  /** Togo = TG */
  Tg = 'TG',
  /** Thailand = TH */
  Th = 'TH',
  /** Tajikistan = TJ */
  Tj = 'TJ',
  /** Tokelau = TK */
  Tk = 'TK',
  /** Timor Leste = TL */
  Tl = 'TL',
  /** Turkmenistan = TM */
  Tm = 'TM',
  /** Tunisia = TN */
  Tn = 'TN',
  /** Tonga = TO */
  To = 'TO',
  /** Turkey = TR */
  Tr = 'TR',
  /** Trinidad and Tobago = TT */
  Tt = 'TT',
  /** Tuvalu = TV */
  Tv = 'TV',
  /** Taiwan = TW */
  Tw = 'TW',
  /** Tanzania, United Republic Of = TZ */
  Tz = 'TZ',
  /** Ukraine = UA */
  Ua = 'UA',
  /** Uganda = UG */
  Ug = 'UG',
  /** United States Minor Outlying Islands = UM */
  Um = 'UM',
  /** United States = US */
  Us = 'US',
  /** Uruguay = UY */
  Uy = 'UY',
  /** Uzbekistan = UZ */
  Uz = 'UZ',
  /** Holy See (Vatican City State) = VA */
  Va = 'VA',
  /** St. Vincent = VC */
  Vc = 'VC',
  /** Venezuela = VE */
  Ve = 'VE',
  /** Virgin Islands, British = VG */
  Vg = 'VG',
  /** Virgin Islands, United States = VI */
  Vi = 'VI',
  /** Vietnam = VN */
  Vn = 'VN',
  /** Vanuatu = VU */
  Vu = 'VU',
  /** Wallis And Futuna = WF */
  Wf = 'WF',
  /** Samoa = WS */
  Ws = 'WS',
  /** Kosovo = XK */
  Xk = 'XK',
  /** Yemen = YE */
  Ye = 'YE',
  /** Mayotte = YT */
  Yt = 'YT',
  /** South Africa = ZA */
  Za = 'ZA',
  /** Zambia = ZM */
  Zm = 'ZM',
  /** Zimbabwe = ZW */
  Zw = 'ZW',
}

export type Location = {
  __typename?: 'Location';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
};

export enum MarketCode {
  Au = 'AU',
  Ca = 'CA',
  Eu = 'EU',
  Gb = 'GB',
  Nz = 'NZ',
  Us = 'US',
}

export enum MeasurementSystem {
  Imperial = 'IMPERIAL',
  Metric = 'METRIC',
}

export type Media = ImageMedia | LivestreamMedia | VideoMedia;

export type MediaCollectionInput = {
  imageMedia?: InputMaybe<Array<ImageMediaInput>>;
  livestreamMedia?: InputMaybe<Array<LivestreamMediaInput>>;
  videoMedia?: InputMaybe<Array<VideoMediaInput>>;
};

export type MediaConnection = {
  __typename?: 'MediaConnection';
  edges: Array<MediaEdge>;
  nodes: Array<Media>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type MediaEdge = {
  __typename?: 'MediaEdge';
  cursor: Scalars['String'];
  node: Media;
};

export type MediaItemInput = {
  imageMedia?: InputMaybe<ImageMediaInput>;
  livestreamMedia?: InputMaybe<LivestreamMediaInput>;
  videoMedia?: InputMaybe<VideoMediaInput>;
};

export type Meta = {
  __typename?: 'Meta';
  client: Client;
  releaseVersion: Scalars['String'];
};

/** Combines a monetary value with a currency code. */
export type MonetaryAmount = {
  __typename?: 'MonetaryAmount';
  currencyCode: Scalars['CurrencyCode'];
  value: Scalars['MonetaryValue'];
};

export type MonetaryAmountInput = {
  currencyCode: Scalars['CurrencyCode'];
  /** Set the type to 'Whole' if passing the value in pounds/usd etc. Default or 'Fractional' is for value in pence */
  type?: InputMaybe<MonetaryValueType>;
  value: Scalars['MonetaryValue'];
};

export enum MonetaryValueType {
  Fractional = 'Fractional',
  Whole = 'Whole',
}

/** Combines a monetary value with conversions in various currencies */
export type MoneyBag = {
  __typename?: 'MoneyBag';
  conversions?: Maybe<Array<MonetaryAmount>>;
  primary?: Maybe<MonetaryAmount>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** @deprecated Use updatePageGallery */
  addMediaItem?: Maybe<AddMediaItemPayload>;
  addOfflineDonation?: Maybe<OfflineDonation>;
  addPageRelationships?: Maybe<PageRelationshipsPayload>;
  /** Archive an active Donation Link */
  archiveDonationLink: Scalars['ID'];
  assignTagsToChildren?: Maybe<Scalars['Boolean']>;
  cancelPage?: Maybe<CancelPagePayload>;
  checkout3dsInitialise?: Maybe<CheckoutInitialise3DsResponse>;
  checkout3dsNonce?: Maybe<Checkout3DsNonceResponse>;
  completeTask?: Maybe<Tasks>;
  confirmCheckout?: Maybe<Checkout>;
  connectFitbit: Scalars['Boolean'];
  connectFitness: Scalars['Boolean'];
  /** Create a new Donation Link */
  createDonationLink?: Maybe<DonationLink>;
  createEvent?: Maybe<Event>;
  createLegacyPage?: Maybe<Page>;
  /** Create a new livestream entry */
  createLivestreamEntry?: Maybe<Livestream>;
  createMediaCollection?: Maybe<CreateMediaCollectionPayload>;
  createPageV2?: Maybe<Page>;
  createTeam?: Maybe<Page>;
  /** Create a new timeline entry */
  createTimelineEntry?: Maybe<TimelineEntry>;
  /** Create a new video story */
  createVideoStory: Scalars['Boolean'];
  /** Delete a livestream entry */
  deleteLivestreamEntry?: Maybe<Livestream>;
  /** @deprecated Use updatePageGallery */
  deleteMediaItem?: Maybe<DeleteMediaItemPayload>;
  deleteOfflineDonation: Scalars['ID'];
  deletePageRelationships?: Maybe<PageRelationshipsPayload>;
  deleteTimelineEntry: Scalars['ID'];
  disconnectFitbit: Scalars['Boolean'];
  disconnectFitness: Scalars['Boolean'];
  /** Edit an existing Donation Link */
  editDonationLink?: Maybe<DonationLink>;
  editOfflineDonation?: Maybe<OfflineDonation>;
  generatePageDonationsCsv?: Maybe<FundraisingPageDonationsCsvPayload>;
  initialiseCheckout3DS?: Maybe<Checkout3DsData>;
  initialiseCheckoutGoCardlessInstantBankPayment?: Maybe<CheckoutBillingRequestFlowId>;
  initialiseCheckoutPaymentOrchestratorTransaction?: Maybe<CheckoutPaymentOrchestratorRedirectUrl>;
  initialiseCheckoutWalletToken?: Maybe<CheckoutWalletToken>;
  initialiseCheckoutWalletTokenForMerchantAccount?: Maybe<CheckoutWalletToken>;
  joinTeam?: Maybe<JoinTeamPayload>;
  /** @deprecated Use addPageRelationships mutation */
  linkPageToCampaign?: Maybe<Scalars['Boolean']>;
  refreshTimelineEntry: Scalars['ID'];
  removeRelationship?: Maybe<Scalars['Boolean']>;
  /** Restore an archived Donation Link */
  restoreDonationLink: Scalars['ID'];
  /**
   * **DEPRECATED**
   * `saveFitnessApplicationSettings` Mutation is deprecated as of 26/05/2020. Use `setFitnessApplicationSettings` instead.
   */
  saveFitnessApplicationSettings?: Maybe<Scalars['Boolean']>;
  setCheckoutReadonly?: Maybe<Checkout>;
  setFitnessApplicationSettings?: Maybe<FitnessApplicationSettings>;
  /** @deprecated Use updatePageCover or updatePageGallery */
  setPageMedia?: Maybe<SetPageMediaPayload>;
  /** @deprecated Link Service (GG.Service.Link) should be used instead of this mutation */
  startCheckoutSession?: Maybe<StartSessionResult>;
  /** Sends a message to a donor to say thanks. */
  thankDonor?: Maybe<Donation>;
  /** @deprecated Use removeRelationship mutation */
  unlinkPageFromCampaign?: Maybe<Scalars['Boolean']>;
  unlinkTeam?: Maybe<UnlinkTeamPayload>;
  updateCampaignAnalytics?: Maybe<Scalars['Boolean']>;
  updateCheckoutApplyPoints?: Maybe<Checkout>;
  updateCheckoutGiftAidDeclaration?: Maybe<Checkout>;
  updateCheckoutOrderAmount?: Maybe<Checkout>;
  updateCheckoutPaymentType?: Maybe<Checkout>;
  updateCheckoutPersonalisation?: Maybe<Checkout>;
  updateCheckoutSelectedCard?: Maybe<Checkout>;
  updateCheckoutTipValue?: Maybe<Checkout>;
  /** Updates campaign branding - email cover photo and logo */
  updateEmailBranding?: Maybe<Scalars['Boolean']>;
  /** Update a livestream entry */
  updateLivestreamEntry?: Maybe<Livestream>;
  updatePage?: Maybe<UpdatePagePayload>;
  updatePageAsStaff?: Maybe<UpdatePagePayload>;
  updatePageCover?: Maybe<UpdatePageCoverPayload>;
  updatePageGallery?: Maybe<UpdatePageGalleryPayload>;
  updatePreferenceService?: Maybe<PreferenceServicePayload>;
  updateRelationships?: Maybe<Scalars['Boolean']>;
  verifyCheckout3DS?: Maybe<Checkout3DsVerification>;
  verifyCheckoutWallet?: Maybe<CheckoutWalletVerification>;
};

export type MutationAddMediaItemArgs = {
  input: AddMediaItemInput;
};

export type MutationAddOfflineDonationArgs = {
  input: CreateOfflineDonationInput;
};

export type MutationAddPageRelationshipsArgs = {
  input: PageRelationshipsInput;
};

export type MutationArchiveDonationLinkArgs = {
  input: ArchiveDonationLinkInput;
};

export type MutationAssignTagsToChildrenArgs = {
  input: AssignTagsToChildrenInput;
};

export type MutationCancelPageArgs = {
  input: CancelPageInput;
};

export type MutationCheckout3dsInitialiseArgs = {
  input: Checkout3dsInitialiseCallInput;
};

export type MutationCheckout3dsNonceArgs = {
  input: Checkout3DsNonceCallInput;
};

export type MutationCompleteTaskArgs = {
  input?: InputMaybe<TaskInput>;
};

export type MutationConfirmCheckoutArgs = {
  input: ConfirmCheckoutInput;
};

export type MutationConnectFitbitArgs = {
  input: ConnectFitnessInput;
};

export type MutationConnectFitnessArgs = {
  input: ConnectFitnessInput;
};

export type MutationCreateDonationLinkArgs = {
  input: CreateDonationLinkInput;
};

export type MutationCreateEventArgs = {
  input: CreateEventInput;
};

export type MutationCreateLegacyPageArgs = {
  createPageMetadata?: InputMaybe<CreatePageMetadataInput>;
  input: CreatePageV2Input;
};

export type MutationCreateLivestreamEntryArgs = {
  input: CreateLivestreamInput;
};

export type MutationCreateMediaCollectionArgs = {
  input: CreateMediaCollectionInput;
};

export type MutationCreatePageV2Args = {
  input: CreatePageV2Input;
};

export type MutationCreateTeamArgs = {
  input: CreateTeamInput;
};

export type MutationCreateTimelineEntryArgs = {
  input: CreateTimelineEntryInput;
};

export type MutationCreateVideoStoryArgs = {
  input: CreateVideoStoryInput;
};

export type MutationDeleteLivestreamEntryArgs = {
  input: DeleteLivestreamInput;
};

export type MutationDeleteMediaItemArgs = {
  input: DeleteMediaItemInput;
};

export type MutationDeleteOfflineDonationArgs = {
  input: DeleteOfflineDonationInput;
};

export type MutationDeletePageRelationshipsArgs = {
  input: PageRelationshipsInput;
};

export type MutationDeleteTimelineEntryArgs = {
  input: DeleteTimelineEntryInput;
};

export type MutationEditDonationLinkArgs = {
  input: EditDonationLinkInput;
};

export type MutationEditOfflineDonationArgs = {
  input: EditOfflineDonationInput;
};

export type MutationGeneratePageDonationsCsvArgs = {
  input: FundraisingPageDonationsCsvInput;
};

export type MutationInitialiseCheckout3DsArgs = {
  input: InitialiseCheckout3DsInput;
};

export type MutationInitialiseCheckoutGoCardlessInstantBankPaymentArgs = {
  input: InitialiseCheckoutGoCardlessInstantBankPaymentInput;
};

export type MutationInitialiseCheckoutPaymentOrchestratorTransactionArgs = {
  input: InitialiseCheckoutPaymentOrchestratorTransactionInput;
};

export type MutationInitialiseCheckoutWalletTokenArgs = {
  input: InitialiseCheckoutWalletTokenInput;
};

export type MutationInitialiseCheckoutWalletTokenForMerchantAccountArgs = {
  input: InitialiseCheckoutWalletTokenInput;
};

export type MutationJoinTeamArgs = {
  input: JoinTeamInput;
};

export type MutationLinkPageToCampaignArgs = {
  input: LinkPageToCampaignInput;
};

export type MutationRefreshTimelineEntryArgs = {
  input: RefreshTimelineEntryInput;
};

export type MutationRemoveRelationshipArgs = {
  input: RemoveRelationshipInput;
};

export type MutationRestoreDonationLinkArgs = {
  input: RestoreDonationLinkInput;
};

export type MutationSaveFitnessApplicationSettingsArgs = {
  input: FitnessApplicationSettingsInput;
};

export type MutationSetCheckoutReadonlyArgs = {
  input: SetCheckoutReadonlyInput;
};

export type MutationSetFitnessApplicationSettingsArgs = {
  input: FitnessApplicationSettingsInput;
};

export type MutationSetPageMediaArgs = {
  input: SetPageMediaInput;
};

export type MutationStartCheckoutSessionArgs = {
  input: StartDonationInput;
};

export type MutationThankDonorArgs = {
  donorId: Scalars['String'];
  message: Scalars['String'];
  pageId: Scalars['String'];
};

export type MutationUnlinkPageFromCampaignArgs = {
  input: UnlinkPageFromCampaignInput;
};

export type MutationUnlinkTeamArgs = {
  input: UnlinkTeamInput;
};

export type MutationUpdateCampaignAnalyticsArgs = {
  input: UpdateAnalyticsInput;
};

export type MutationUpdateCheckoutApplyPointsArgs = {
  input: UpdateCheckoutApplyPointsInput;
};

export type MutationUpdateCheckoutGiftAidDeclarationArgs = {
  input: UpdateCheckoutGiftAidDeclarationInput;
};

export type MutationUpdateCheckoutOrderAmountArgs = {
  input: UpdateCheckoutOrderAmountInput;
};

export type MutationUpdateCheckoutPaymentTypeArgs = {
  input: UpdateCheckoutPaymentTypeInput;
};

export type MutationUpdateCheckoutPersonalisationArgs = {
  input: UpdateCheckoutPersonalisationInput;
};

export type MutationUpdateCheckoutSelectedCardArgs = {
  input: UpdateCheckoutSelectedCardInput;
};

export type MutationUpdateCheckoutTipValueArgs = {
  input: UpdateCheckoutTipValueInput;
};

export type MutationUpdateEmailBrandingArgs = {
  input: EmailBrandingInput;
};

export type MutationUpdateLivestreamEntryArgs = {
  input: UpdateLivestreamInput;
};

export type MutationUpdatePageArgs = {
  input: UpdatePageInput;
};

export type MutationUpdatePageAsStaffArgs = {
  input: UpdatePageAsStaffInput;
};

export type MutationUpdatePageCoverArgs = {
  input: UpdatePageCoverInput;
};

export type MutationUpdatePageGalleryArgs = {
  input: UpdatePageGalleryInput;
};

export type MutationUpdatePreferenceServiceArgs = {
  input: UpdatePreferenceServiceInput;
};

export type MutationUpdateRelationshipsArgs = {
  input: UpdateRelationshipsInput;
};

export type MutationVerifyCheckout3DsArgs = {
  input: VerifyCheckout3DsInput;
};

export type MutationVerifyCheckoutWalletArgs = {
  input: VerifyCheckoutWalletInput;
};

/** Marks an entity as being fetchable by its ID. */
export type Node = {
  id: Scalars['ID'];
};

export type Occasion = {
  __typename?: 'Occasion';
  date?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  names?: Maybe<Array<Scalars['String']>>;
  relationshipToPageCreator?: Maybe<RelationshipToPerson>;
  type: OccasionType;
};

export type OccasionInput = {
  date?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  names?: InputMaybe<Array<Scalars['String']>>;
  relationshipToPageCreator?: InputMaybe<RelationshipToPerson>;
  type: OccasionType;
};

export enum OccasionType {
  Birthday = 'BIRTHDAY',
  InMemory = 'IN_MEMORY',
  Other = 'OTHER',
  Wedding = 'WEDDING',
}

export type OfflineDonation = Node & {
  __typename?: 'OfflineDonation';
  amount: Scalars['MonetaryValue'];
  currency: Scalars['CurrencyCode'];
  dateCreated: Scalars['DateTime'];
  displayName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  legacyId: Scalars['String'];
  message?: Maybe<Scalars['String']>;
};

export type OfflineDonationConnection = {
  __typename?: 'OfflineDonationConnection';
  edges: Array<OfflineDonationEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type OfflineDonationEdge = {
  __typename?: 'OfflineDonationEdge';
  node: OfflineDonation;
};

export type OffsetPageInfo = {
  __typename?: 'OffsetPageInfo';
  /** `number` of the current page based on offset */
  currentPage: Scalars['Int'];
  /** `true` if it is possible to navigate to the next page. */
  hasNextPage: Scalars['Boolean'];
  /** `true` if it is possible to navigate to the previous page. */
  hasPreviousPage: Scalars['Boolean'];
  /** `number` of how many records skipped or current record index */
  offset: Scalars['Int'];
  /** `number` of how many records to take */
  take: Scalars['Int'];
  /** `number` of pages available to pagination */
  totalPages: Scalars['Int'];
};

export type OffsetPaginationInput = {
  offset: Scalars['Int'];
  take: Scalars['Int'];
};

export type Order = {
  __typename?: 'Order';
  appliedTipScheme?: Maybe<TipScheme>;
  currency?: Maybe<AcceptedCurrencyCode>;
  fees?: Maybe<Fees>;
  id: Scalars['ID'];
  lineItems: Array<LineItem>;
  requiresFullBillingAddress?: Maybe<Scalars['Boolean']>;
  supportedCurrencies: SupportedCurrencies;
  supportedFeatures?: Maybe<SupportedFeatures>;
  tipAmount?: Maybe<Scalars['MonetaryValue']>;
  totalAmount?: Maybe<Scalars['MonetaryValue']>;
  voluntaryContributionConfig?: Maybe<Array<VoluntaryContributionConfig>>;
};

export type OutputExternalRef = {
  __typename?: 'OutputExternalRef';
  name?: Maybe<Scalars['String']>;
  ref?: Maybe<Scalars['String']>;
};

export type OwnerMessagingFeature = {
  __typename?: 'OwnerMessagingFeature';
  SuccessEnabled: Scalars['Boolean'];
};

export enum OwnerType {
  Individual = 'INDIVIDUAL',
  Organisation = 'ORGANISATION',
  PeerGroup = 'PEER_GROUP',
}

export type Page = Node & {
  __typename?: 'Page';
  activity?: Maybe<Activity>;
  /** @deprecated Deprecated Oct 2022 - Use `fundraisingSettings.isEnabled` when querying one_page */
  allowFundraising?: Maybe<Scalars['Boolean']>;
  analytics?: Maybe<Array<Maybe<AnalyticsData>>>;
  appealName?: Maybe<Scalars['String']>;
  application?: Maybe<Application>;
  attribution?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['URL']>;
  badges?: Maybe<Badges>;
  /** @deprecated Use occasion instead */
  birthday?: Maybe<Birthday>;
  branding?: Maybe<Branding>;
  categories?: Maybe<Array<Scalars['String']>>;
  cause?: Maybe<Cause>;
  charity?: Maybe<Charity>;
  charityExternalLinks?: Maybe<CharityExternalLinks>;
  cover?: Maybe<Media>;
  coverMediaCollectionId?: Maybe<Scalars['UUID']>;
  createDate?: Maybe<Scalars['DateTime']>;
  /** @deprecated Use `targetWithCurrency.currencyCode` this will evetually become `target.currencyCode` */
  currency?: Maybe<Scalars['String']>;
  customCodes?: Maybe<Array<Maybe<CustomCode>>>;
  donationSummary?: Maybe<DonationSummary>;
  donations?: Maybe<DonationConnection>;
  endDate?: Maybe<Scalars['DateTime']>;
  eventGivingGroupId?: Maybe<Scalars['Int']>;
  externalId?: Maybe<Scalars['String']>;
  fundraisingSettings?: Maybe<FundraisingSettings>;
  gallery?: Maybe<MediaConnection>;
  galleryMediaCollectionId?: Maybe<Scalars['UUID']>;
  /** @deprecated Deprecated Nov 2019 - `hero` has been replaced by `heroMedia`. This allows a media type to be set, and to provide consistency with the `mediaCollection` field */
  hero?: Maybe<Hero>;
  /** @deprecated Use `hero` as it has `imageTitle` so you can set an image Alt. */
  heroImage?: Maybe<Scalars['URL']>;
  /** @deprecated Deprecated Mar 2021 - `heroMedia` has been replaced by `cover`. This follows the new naming conventions for OnePage. */
  heroMedia?: Maybe<Media>;
  hiddenFromSearch?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  inMemory?: Maybe<InMemory>;
  isSitebuilder?: Maybe<Scalars['Boolean']>;
  joinUrl?: Maybe<Scalars['String']>;
  joinVerb?: Maybe<Scalars['String']>;
  launchDate?: Maybe<Scalars['DateTime']>;
  leaderboard?: Maybe<PageLeaderboardConnection>;
  legacyId: Scalars['String'];
  /** @deprecated Lifecycle will be deleted in the next release. Please use status instead */
  lifecycle?: Maybe<Lifecycle>;
  livestream?: Maybe<Livestream>;
  location?: Maybe<Location>;
  logo?: Maybe<Scalars['String']>;
  /** @deprecated Deprecated Mar 2021 - `mediaCollection` has been replaced by `gallery`, which has a more meaningful name and enforces valid pagination args to be passed. */
  mediaCollection?: Maybe<MediaConnection>;
  membershipPolicy?: Maybe<Scalars['String']>;
  occasion?: Maybe<Occasion>;
  offlineDonations?: Maybe<OfflineDonationConnection>;
  owner?: Maybe<User>;
  product?: Maybe<Product>;
  reason?: Maybe<Reason>;
  /** @deprecated Deprecated Oct 2022 - Use `fundraisingSettings.fundraisingPageUrl` when querying one_page */
  redirectUrl?: Maybe<Scalars['String']>;
  redirectionPageUrl?: Maybe<Scalars['String']>;
  relationships?: Maybe<PageRelationships>;
  slug?: Maybe<Scalars['Slug']>;
  startDate?: Maybe<Scalars['DateTime']>;
  startFundraisingUrl?: Maybe<Scalars['String']>;
  status?: Maybe<PageStatus>;
  /** @deprecated Use storyWithType */
  story?: Maybe<Scalars['String']>;
  storyWithType?: Maybe<Story>;
  subtitle?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  supporters?: Maybe<SupporterConnection>;
  supportsGiftAid?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<Array<TagValue>>;
  tagsAssigneeId?: Maybe<Scalars['String']>;
  /** @deprecated Use `targetWithCurrency.value` this will evetually become `target.value` */
  target?: Maybe<Scalars['MonetaryValue']>;
  targetWithCurrency?: Maybe<MonetaryAmount>;
  targetWithCurrencyAndConversions?: Maybe<MoneyBag>;
  thumbnailImage?: Maybe<Scalars['String']>;
  timeline?: Maybe<TimelineEntryConnection>;
  title: Scalars['String'];
  totals?: Maybe<PageTotals>;
  trend?: Maybe<SupporterTrend>;
  type: PageType;
  updateDate?: Maybe<Scalars['DateTime']>;
  url: Scalars['URL'];
};

export type PageApplicationArgs = {
  name: Scalars['String'];
  type: ApplicationType;
};

export type PageBrandingArgs = {
  displayOptions?: InputMaybe<BrandingDisplayOptionsInput>;
};

export type PageDonationSummaryArgs = {
  displayOptions?: InputMaybe<DonationSummaryDisplayOptionsInput>;
};

export type PageDonationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  direct?: InputMaybe<Scalars['Boolean']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type PageGalleryArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type PageLeaderboardArgs = {
  after?: InputMaybe<Scalars['String']>;
  first: Scalars['Int'];
  id?: InputMaybe<Scalars['String']>;
  type: PageLeaderboardType;
};

export type PageMediaCollectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type PageOfflineDonationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type PageSupportersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<SupportersSortInput>;
  statuses?: InputMaybe<Array<SupporterDonationStatus>>;
};

export type PageTimelineArgs = {
  after?: InputMaybe<Scalars['String']>;
  entryType?: InputMaybe<TimelineEntryType>;
  first?: InputMaybe<Scalars['Int']>;
};

export type PageBeneficiaryInput = {
  id: Scalars['String'];
  type: PageBeneficiaryType;
};

export enum PageBeneficiaryType {
  Charity = 'CHARITY',
}

export type PageCompletion = {
  __typename?: 'PageCompletion';
  activity?: Maybe<Activity>;
  charity: Charity;
  occasion?: Maybe<Occasion>;
  slug: Scalars['Slug'];
  story: Scalars['String'];
  targetAmount?: Maybe<MonetaryAmount>;
  title: Scalars['String'];
};

export type PageCompletionInput = {
  activity?: InputMaybe<ActivityInput>;
  charityName?: InputMaybe<Scalars['String']>;
  occasion?: InputMaybe<OccasionInput>;
  prompt: Scalars['String'];
  targetAmount?: InputMaybe<MonetaryAmountInput>;
};

export type PageCompletionPromptAnalysis = {
  __typename?: 'PageCompletionPromptAnalysis';
  activity?: Maybe<Activity>;
  charityName?: Maybe<Scalars['String']>;
  occasion?: Maybe<Occasion>;
  targetAmount?: Maybe<MonetaryAmount>;
};

/** The connection type for Page. */
export type PageConnection = {
  __typename?: 'PageConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PageEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Page>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type PageCreatedCountSummaryItem = {
  __typename?: 'PageCreatedCountSummaryItem';
  pageCreatedCount: Scalars['Int'];
  pageCreatedDate: Scalars['DateTime'];
  pageCreatedWithTagsCount?: Maybe<Scalars['Int']>;
  pageCreatedWithoutTagsCount?: Maybe<Scalars['Int']>;
};

/** An edge in a connection. */
export type PageEdge = {
  __typename?: 'PageEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge. */
  node: Page;
};

export type PageFilterInput = {
  charityId?: InputMaybe<Array<Scalars['Int']>>;
  status?: InputMaybe<Array<PageStatus>>;
};

/** Pagination page information. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** The cursor for the last edge on the current page. */
  endCursor?: Maybe<Scalars['String']>;
  /** `true` if it is possible to navigate to the next page. */
  hasNextPage: Scalars['Boolean'];
  /** `true` if it is possible to navigate to the previous page. */
  hasPreviousPage: Scalars['Boolean'];
  /** The cursor of the first edge on the current page. */
  startCursor?: Maybe<Scalars['String']>;
};

export type PageLeaderboardConnection = {
  __typename?: 'PageLeaderboardConnection';
  edges: Array<PageLeaderboardEdge>;
  leaderboardId?: Maybe<Scalars['UUID']>;
  nodes: Array<Page>;
  pageInfo: ForwardOnlyPageInfo;
  totalCount: Scalars['Int'];
};

export type PageLeaderboardEdge = {
  __typename?: 'PageLeaderboardEdge';
  amounts: Array<TotalAmount>;
  node: Page;
  tagValue: Scalars['String'];
};

export enum PageLeaderboardType {
  Fundraisers = 'FUNDRAISERS',
  Teams = 'TEAMS',
}

export enum PageMembership {
  InviteOnly = 'INVITE_ONLY',
  Open = 'OPEN',
}

export type PageRelationship = {
  __typename?: 'PageRelationship';
  page?: Maybe<Page>;
  productName: ProductType;
  requiredForChildren?: Maybe<Scalars['Boolean']>;
  type: PageRelationshipType;
};

export type PageRelationshipConfig = {
  __typename?: 'PageRelationshipConfig';
  membersDisabled: Scalars['Boolean'];
};

export type PageRelationshipConnection = {
  __typename?: 'PageRelationshipConnection';
  edges: Array<PageRelationshipEdge>;
  nodes: Array<Page>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type PageRelationshipEdge = {
  __typename?: 'PageRelationshipEdge';
  cursor: Scalars['String'];
  node: Page;
};

export type PageRelationshipInput = {
  relatedEntityId: Scalars['String'];
  requiredForChildren?: InputMaybe<Scalars['Boolean']>;
  type: PageRelationshipType;
};

export enum PageRelationshipType {
  HasBeneficiary = 'HAS_BENEFICIARY',
  ParticipatesIn = 'PARTICIPATES_IN',
  PromotedByCampaign = 'PROMOTED_BY_CAMPAIGN',
  PromotesEvent = 'PROMOTES_EVENT',
  RaisesMoneyFor = 'RAISES_MONEY_FOR',
}

export type PageRelationships = {
  __typename?: 'PageRelationships';
  beneficiaries: BeneficiaryConnection;
  campaigns: PageRelationshipConnection;
  children: PageRelationshipConnection;
  companies: PageRelationshipConnection;
  config?: Maybe<PageRelationshipConfig>;
  events: EventConnection;
  members: PageRelationshipConnection;
  /** @deprecated Use parentsConnection instead */
  parents: Array<PageRelationship>;
  parentsConnection: ParentPageRelationshipConnection;
  teams: PageRelationshipConnection;
};

export type PageRelationshipsBeneficiariesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type PageRelationshipsCampaignsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type PageRelationshipsChildrenArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  createDateFrom?: InputMaybe<Scalars['String']>;
  createDateTo?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<GetChildrenOrderByArg>;
  productName?: InputMaybe<Scalars['String']>;
  productNames?: InputMaybe<Array<Scalars['String']>>;
  searchTerm?: InputMaybe<Scalars['String']>;
  tagsAssigned?: InputMaybe<Scalars['Boolean']>;
};

export type PageRelationshipsCompaniesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type PageRelationshipsEventsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type PageRelationshipsMembersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type PageRelationshipsParentsArgs = {
  productName?: InputMaybe<ProductType>;
};

export type PageRelationshipsParentsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  first: Scalars['Int'];
  productName?: InputMaybe<ProductType>;
};

export type PageRelationshipsTeamsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type PageRelationshipsInput = {
  beneficiaryIds?: InputMaybe<Array<Scalars['ID']>>;
  id: Scalars['ID'];
  relatedPages?: InputMaybe<Array<RelatedPageInput>>;
};

export type PageRelationshipsPayload = {
  __typename?: 'PageRelationshipsPayload';
  page: PageWithId;
};

export enum PageStatus {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED',
  Closed = 'CLOSED',
  Completed = 'COMPLETED',
  Draft = 'DRAFT',
  Hidden = 'HIDDEN',
  Inactive = 'INACTIVE',
  Offline = 'OFFLINE',
  PendingApproval = 'PENDING_APPROVAL',
  Scheduled = 'SCHEDULED',
}

export type PageTagInput = {
  key: Scalars['String'];
  label?: InputMaybe<Scalars['String']>;
  value: Scalars['String'];
  values?: InputMaybe<Array<Scalars['String']>>;
};

/** A single amount/metric. */
export type PageTotal = {
  __typename?: 'PageTotal';
  /** Conversions in various units, for the amount. */
  amounts: Array<TotalAmount>;
  /** The description/id of the amount. */
  measurementDomain: Scalars['String'];
};

/** A collection of numeric totals captured from the Tags System. */
export type PageTotals = {
  __typename?: 'PageTotals';
  /** Specifies the list of totals to display. */
  displayOptions: PageTotalsDisplayOptions;
  /** Specifies the primary measurement type to display totals in. */
  primaryMeasurementType: Scalars['String'];
  /** A summary of fitness/activity amounts which are present on the page timeline. */
  timeline: Array<PageTotal>;
};

export enum PageTotalsDisplayOptions {
  DoNotDisplay = 'DO_NOT_DISPLAY',
  TimelineOnly = 'TIMELINE_ONLY',
}

/** Used to decide from which underlying API the page data will be retrieved */
export enum PageType {
  Campaign = 'CAMPAIGN',
  Charity = 'CHARITY',
  Company = 'COMPANY',
  Crowdfunding = 'CROWDFUNDING',
  Event = 'EVENT',
  Fundraising = 'FUNDRAISING',
  InMemory = 'IN_MEMORY',
  OnePage = 'ONE_PAGE',
  Team = 'TEAM',
}

export type PageWithId = {
  __typename?: 'PageWithId';
  id: Scalars['ID'];
  legacyId: Scalars['ID'];
};

export type ParentPageRelationshipConnection = {
  __typename?: 'ParentPageRelationshipConnection';
  edges: Array<ParentPageRelationshipEdge>;
  nodes: Array<PageRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type ParentPageRelationshipEdge = {
  __typename?: 'ParentPageRelationshipEdge';
  cursor: Scalars['String'];
  node: PageRelationship;
};

export type Payment = {
  __typename?: 'Payment';
  details?: Maybe<PaymentDetails>;
  id: Scalars['ID'];
  type: PaymentType;
};

export type PaymentCard = {
  __typename?: 'PaymentCard';
  billingAddress: Address;
  cardType: CardType;
  disabled: Scalars['Boolean'];
  expiryMonth: Scalars['String'];
  expiryYear: Scalars['String'];
  id: Scalars['ID'];
  lastFourDigits: Scalars['String'];
  nameOnCard: Scalars['String'];
  paymentCardId?: Maybe<Scalars['ID']>;
  points?: Maybe<PaymentCardPoints>;
  requires3DS: Scalars['Boolean'];
  saved: Scalars['Boolean'];
};

export type PaymentCardPoints = {
  __typename?: 'PaymentCardPoints';
  appliedPoints?: Maybe<Scalars['Int']>;
  conversionRate: Scalars['Float'];
  isEligible: Scalars['Boolean'];
  totalPoints: Scalars['Int'];
};

export type PaymentDetails = Crypto | DirectDebit | PaymentCard;

export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  alternative?: Maybe<Array<AlternativePaymentMethod>>;
  amounts: PaymentMethodAmounts;
  card?: Maybe<Array<CardType>>;
  currency: Scalars['CurrencyCode'];
  supportsRecurringCard: Scalars['Boolean'];
};

export type PaymentMethodAmounts = {
  __typename?: 'PaymentMethodAmounts';
  defaultAmount: Scalars['Int'];
  maximum: Scalars['Int'];
  minimum: Scalars['Int'];
  suggested?: Maybe<Array<Scalars['Int']>>;
};

export enum PaymentServiceProvider {
  Braintree = 'Braintree',
}

export enum PaymentType {
  BraintreeApplePay = 'BraintreeApplePay',
  BraintreeGooglePay = 'BraintreeGooglePay',
  BraintreePayPal = 'BraintreePayPal',
  BraintreeVenmo = 'BraintreeVenmo',
  Crypto = 'Crypto',
  DirectDebit = 'DirectDebit',
  GoCardlessInstantBankPayment = 'GoCardlessInstantBankPayment',
  JustGivingVoucher = 'JustGivingVoucher',
  PaymentCard = 'PaymentCard',
  RecurringPaymentCard = 'RecurringPaymentCard',
}

export enum PointOfView {
  FirstPerson = 'FIRST_PERSON',
  ThirdPerson = 'THIRD_PERSON',
}

export enum PointsScheme {
  AmexPayWithPoints = 'AmexPayWithPoints',
}

export type PreferenceServicePayload = {
  __typename?: 'PreferenceServicePayload';
  ok?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['Int']>;
};

export type PreferencesInput = {
  collectionLocationId?: InputMaybe<Scalars['Int']>;
  consentStatement?: InputMaybe<Scalars['Int']>;
  consents: Array<ConsentInput>;
  donationCauseSourceRef?: InputMaybe<Scalars['String']>;
  isConsentRequired?: InputMaybe<Scalars['Boolean']>;
  transactionId?: InputMaybe<Scalars['String']>;
  transactionTypeId?: InputMaybe<Scalars['String']>;
};

export type Product = {
  __typename?: 'Product';
  allowedDonationMethods: Array<DonationMethod>;
  allowedDonationTypes: Array<DonationType>;
  allowedPointsOfView: Array<PointOfView>;
  assigneePrefix?: Maybe<Scalars['String']>;
  features: ProductFeatures;
  name: Scalars['String'];
  ownerType?: Maybe<OwnerType>;
};

export type ProductFeatures = {
  __typename?: 'ProductFeatures';
  consumerMessaging?: Maybe<ConsumerMessagingFeature>;
  layout?: Maybe<LayoutFeature>;
  ownerMessaging?: Maybe<OwnerMessagingFeature>;
  sharing?: Maybe<SharingFeature>;
  teams?: Maybe<TeamsFeature>;
  thirdPartyApplications?: Maybe<ThirdPartyApplicationsFeature>;
  timeline?: Maybe<TimelineFeature>;
};

export type ProductItemDetails = {
  __typename?: 'ProductItemDetails';
  quantity?: Maybe<Scalars['Int']>;
};

export enum ProductType {
  Birthday = 'BIRTHDAY',
  Campaign = 'CAMPAIGN',
  Charity = 'CHARITY',
  Event = 'EVENT',
  Fundraising = 'FUNDRAISING',
  Sitebuilder = 'SITEBUILDER',
  SitebuilderFundraising = 'SITEBUILDER_FUNDRAISING',
  Team = 'TEAM',
}

export enum PublishingSource {
  Fitbit = 'FITBIT',
  Manual = 'MANUAL',
  Strava = 'STRAVA',
}

export type Query = {
  __typename?: 'Query';
  /** Searches addresses by postal code */
  addressesByPostalCode?: Maybe<AddressesByPostalCodeConnection>;
  availableSlugs?: Maybe<AvailableSlugs>;
  /** Gets a Cause from the Cause API by either Cause Ref or Source Ref */
  cause?: Maybe<CauseResponse>;
  /** Performs a combined search for charities and campaigns */
  causes: CauseSearchConnection;
  /** Searches charities */
  charities: CharityConnection;
  /** Gets a charity by charity ID. */
  charity?: Maybe<Charity>;
  /** Gets a charity by the external ID. */
  charityByExternalId?: Maybe<Charity>;
  /** Gets a Checkout from the Donation Collection API */
  checkout?: Maybe<Checkout>;
  coverImageSuggestion: Array<Image>;
  /** Gets the list of available Cryptocurrencies and their exchange rate */
  cryptocurrencies?: Maybe<CryptocurrencyList>;
  discoverPersistedPages?: Maybe<DiscoverPersistedPages>;
  /** Gets the Donation Link by ID */
  donationLink?: Maybe<DonationLink>;
  /** Gets a collection of Donation Links */
  donationLinks?: Maybe<DonationLinkConnection>;
  enhanceDonationMessage?: Maybe<EnhancedDonationMessage>;
  enhanceStory: EnhancedStory;
  /** Searches events */
  events: EventConnection;
  /** Gets funds data */
  funds?: Maybe<Funds>;
  /**
   * Gets the branding by campaign ID.
   * @deprecated Branding now resolved on the page
   */
  getCampaignBranding?: Maybe<Branding>;
  /**
   * Gets a Cause from the Cause API by Cause Ref
   * @deprecated Use cause query
   */
  getCauseByCauseRef?: Maybe<Cause>;
  /**
   * Gets a Cause from the Cause API by Source Ref
   * @deprecated Use cause query
   */
  getCauseBySourceRef?: Maybe<Cause>;
  /** Get the the video story */
  getVideoStory: VideoStory;
  leaderboard: Leaderboard;
  livestream?: Maybe<Livestream>;
  livestreamByType?: Maybe<Array<Maybe<LivestreamMetaData>>>;
  meta: Meta;
  /** Gets node by id (node can anything that can be fetched by a unique global ID, for example: a page, user etc.) */
  node?: Maybe<Node>;
  /** Gets the page data by the page type and slug or ID. */
  page?: Maybe<Page>;
  /**
   * Get the page data by the product type and external ID.
   * @deprecated Use the page query with an externalId argument type
   */
  pageByExternalId?: Maybe<Page>;
  /**
   * Gets the page data by the page type and ID.
   * @deprecated Use the page query with an id argument type
   */
  pageById?: Maybe<Page>;
  pageCompletion: PageCompletion;
  pageComplmentionPromptAnalysis: PageCompletionPromptAnalysis;
  pageCreatedCountSummary: Array<PageCreatedCountSummaryItem>;
  pageStoryTone: StoryTone;
  /** Get the pages data by the page IDs. */
  pages?: Maybe<PageConnection>;
  product: Product;
  /** Performs a filtered search for campaigns. Can be extended to search for charities and events */
  search: SearchConnection;
  searchImages: ImageConnection;
  slow?: Maybe<Scalars['String']>;
  supporter?: Maybe<Supporter>;
  tagValues: Array<TagValue>;
  tasks?: Maybe<Tasks>;
  totals: Array<Total>;
  /** Gets the status for a Checkout Transaction from the Donations API */
  transactionStatus?: Maybe<TransactionStatus>;
  trendingCharities?: Maybe<TrendingCharities>;
  /** Gets the logged-in user data. */
  viewer?: Maybe<User>;
};

export type QueryAddressesByPostalCodeArgs = {
  input: AddressesByPostalCodeInput;
};

export type QueryAvailableSlugsArgs = {
  slug: Scalars['Slug'];
  type?: InputMaybe<PageType>;
};

export type QueryCauseArgs = {
  input: CauseInput;
};

export type QueryCausesArgs = {
  after?: InputMaybe<Scalars['String']>;
  options?: InputMaybe<CauseSearchOptionsInput>;
  search?: InputMaybe<Scalars['String']>;
  take?: InputMaybe<Scalars['Int']>;
};

export type QueryCharitiesArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<CharityFilters>;
  search?: InputMaybe<Scalars['String']>;
  take?: InputMaybe<Scalars['Int']>;
};

export type QueryCharityArgs = {
  charityId: Scalars['UUID'];
};

export type QueryCharityByExternalIdArgs = {
  externalId: Scalars['Int'];
};

export type QueryCheckoutArgs = {
  input: CheckoutInput;
};

export type QueryCoverImageSuggestionArgs = {
  prompt: Scalars['String'];
};

export type QueryCryptocurrenciesArgs = {
  input: CryptocurrenciesInput;
};

export type QueryDonationLinkArgs = {
  input: DonationLinkInput;
};

export type QueryDonationLinksArgs = {
  input: DonationLinksInput;
};

export type QueryEnhanceDonationMessageArgs = {
  input: DonationMessageEnhanceInput;
};

export type QueryEnhanceStoryArgs = {
  input: StoryEnhanceInput;
};

export type QueryEventsArgs = {
  after?: InputMaybe<Scalars['String']>;
  charityIds?: InputMaybe<Array<Scalars['Int']>>;
  includeManagedByJG?: InputMaybe<Scalars['Boolean']>;
  includePublic?: InputMaybe<Scalars['Boolean']>;
  search?: InputMaybe<Scalars['String']>;
  take?: InputMaybe<Scalars['Int']>;
};

export type QueryFundsArgs = {
  input: FundsInput;
};

export type QueryGetCampaignBrandingArgs = {
  pageId: Scalars['UUID'];
};

export type QueryGetCauseByCauseRefArgs = {
  input: CauseByCauseRefInput;
};

export type QueryGetCauseBySourceRefArgs = {
  input: CauseBySourceRefInput;
};

export type QueryGetVideoStoryArgs = {
  legacyId: Scalars['ID'];
};

export type QueryLeaderboardArgs = {
  id: Scalars['LeaderboardId'];
};

export type QueryLivestreamArgs = {
  legacyId: Scalars['String'];
};

export type QueryLivestreamByTypeArgs = {
  isLive: Scalars['Boolean'];
  streamType: Scalars['String'];
};

export type QueryNodeArgs = {
  id: Scalars['ID'];
};

export type QueryPageArgs = {
  externalId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  preview?: InputMaybe<Scalars['Preview']>;
  slug?: InputMaybe<Scalars['Slug']>;
  type?: InputMaybe<PageType>;
};

export type QueryPageByExternalIdArgs = {
  externalId: Scalars['String'];
  productType: ProductType;
};

export type QueryPageByIdArgs = {
  id: Scalars['String'];
  preview?: InputMaybe<Scalars['Preview']>;
  type?: InputMaybe<PageType>;
};

export type QueryPageCompletionArgs = {
  input: PageCompletionInput;
};

export type QueryPageComplmentionPromptAnalysisArgs = {
  prompt: Scalars['String'];
};

export type QueryPageCreatedCountSummaryArgs = {
  month: Scalars['Int'];
  pageId: Scalars['UUID'];
  productName?: InputMaybe<ProductType>;
  year: Scalars['Int'];
};

export type QueryPageStoryToneArgs = {
  input: ToneOptionsInput;
};

export type QueryPagesArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  pageIds: Array<Scalars['String']>;
};

export type QueryProductArgs = {
  name: Scalars['String'];
};

export type QuerySearchArgs = {
  filter?: InputMaybe<SearchFilterInput>;
  index: Array<SearchIndex>;
  pagination: CursorPaginationInput;
  query: Scalars['String'];
  sort?: InputMaybe<SearchSortInput>;
};

export type QuerySearchImagesArgs = {
  query: Scalars['String'];
};

export type QuerySupporterArgs = {
  donationId: Scalars['Int'];
};

export type QueryTagValuesArgs = {
  assignee: Scalars['String'];
};

export type QueryTasksArgs = {
  input: TaskInput;
};

export type QueryTotalsArgs = {
  measurementDomain?: InputMaybe<Scalars['String']>;
  segment: Scalars['String'];
  tagDefinitionId: Scalars['String'];
  tagValue: Scalars['String'];
};

export type QueryTransactionStatusArgs = {
  input: TransactionStatusInput;
};

export type Reaction = {
  __typename?: 'Reaction';
  message: Scalars['String'];
};

export type Reason = {
  __typename?: 'Reason';
  description?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  /** @deprecated Use `description` the naming is more indicative of what it actually is */
  reason?: Maybe<Scalars['String']>;
  reasonUrl?: Maybe<Scalars['String']>;
};

export type ReasonInput = {
  description?: InputMaybe<Scalars['String']>;
  icon?: InputMaybe<Scalars['String']>;
};

export type RefreshTimelineEntryInput = {
  id: Scalars['ID'];
};

export type RelatedPageInput = {
  id: Scalars['ID'];
  type: PageRelationshipType;
};

export enum RelationshipToPerson {
  Child = 'CHILD',
  Colleague = 'COLLEAGUE',
  FamilyMember = 'FAMILY_MEMBER',
  Friend = 'FRIEND',
  Grandchild = 'GRANDCHILD',
  Grandparent = 'GRANDPARENT',
  Parent = 'PARENT',
  Partner = 'PARTNER',
  PreferNotToSay = 'PREFER_NOT_TO_SAY',
  Sibling = 'SIBLING',
  SomeoneElse = 'SOMEONE_ELSE',
  Spouse = 'SPOUSE',
}

export type RemoveRelationshipInput = {
  pageId: Scalars['String'];
  parentPageId: Scalars['String'];
};

export type RestoreDonationLinkInput = {
  donationLinkId: Scalars['String'];
};

export type SearchConnection = {
  __typename?: 'SearchConnection';
  edges: Array<SearchEdge>;
  nodes: Array<SearchItem>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type SearchEdge = {
  __typename?: 'SearchEdge';
  node: SearchItem;
};

export type SearchFilterInput = {
  page?: InputMaybe<PageFilterInput>;
};

export enum SearchIndex {
  Campaign = 'CAMPAIGN',
}

export type SearchItem = Page;

export enum SearchResultType {
  Campaign = 'CAMPAIGN',
  Charity = 'CHARITY',
}

export enum SearchSortField {
  CreationDate = 'CREATION_DATE',
}

export type SearchSortInput = {
  direction?: InputMaybe<SortBy>;
  field?: InputMaybe<SearchSortField>;
};

export type SessionInformationInput = {
  payload: Scalars['String'];
  transactionId: Scalars['String'];
};

export type SetCheckoutReadonlyInput = {
  checkoutId: Scalars['ID'];
};

export type SetPageLifecyclePayload = {
  __typename?: 'SetPageLifecyclePayload';
  page: PageWithId;
};

export type SetPageMediaInput = {
  id: Scalars['ID'];
  media?: InputMaybe<MediaCollectionInput>;
  type: PageType;
};

export type SetPageMediaPayload = {
  __typename?: 'SetPageMediaPayload';
  page: PageWithId;
};

export type SharingFeature = {
  __typename?: 'SharingFeature';
  emailBodyKeyFirstPerson?: Maybe<Scalars['String']>;
  emailBodyKeyThirdPerson?: Maybe<Scalars['String']>;
  twitterKeyFirstPerson?: Maybe<Scalars['String']>;
  twitterKeyThirdPerson?: Maybe<Scalars['String']>;
  whatsAppAndSmsKeyFirstPerson?: Maybe<Scalars['String']>;
  whatsAppAndSmsKeyThirdPerson?: Maybe<Scalars['String']>;
};

export enum SortBy {
  Ascending = 'ASCENDING',
  Descending = 'DESCENDING',
}

export type StartDonationInput = {
  additionalData?: InputMaybe<Scalars['String']>;
  causeRef?: InputMaybe<Scalars['String']>;
  crowdfundingPageDetails?: InputMaybe<CrowdfundingPageDetails>;
  deepLinkParameters?: InputMaybe<DonationDeepLinkParameters>;
  externalRefs: Array<ExternalRef>;
  referralCode?: InputMaybe<Scalars['String']>;
  sourceId?: InputMaybe<Scalars['String']>;
  uris: DonationRequestUris;
};

export type StartSessionResult = {
  __typename?: 'StartSessionResult';
  id: Scalars['String'];
};

export type Steps = {
  __typename?: 'Steps';
  unit: StepsUnit;
  value: Scalars['Int'];
};

export enum StepsUnit {
  Steps = 'STEPS',
}

export type Story = {
  __typename?: 'Story';
  content?: Maybe<Scalars['String']>;
  type: StoryType;
};

export type StoryEnhanceInput = {
  causeTitle?: InputMaybe<Scalars['String']>;
  eventDate?: InputMaybe<Scalars['String']>;
  eventName?: InputMaybe<Scalars['String']>;
  eventType?: InputMaybe<Scalars['String']>;
  story: Scalars['String'];
  target?: InputMaybe<MonetaryAmountInput>;
  tones: Array<Scalars['String']>;
};

export type StoryInput = {
  content: Scalars['String'];
  type: StoryType;
};

export type StoryTone = {
  __typename?: 'StoryTone';
  tones: Array<Tone>;
};

export enum StoryType {
  Html = 'HTML',
  Plaintext = 'PLAINTEXT',
  RichStoryV1Json = 'RICH_STORY_V1_JSON',
}

export type Strava = {
  __typename?: 'Strava';
  isConnected?: Maybe<Scalars['Boolean']>;
};

export type SuggestedAmount = {
  __typename?: 'SuggestedAmount';
  description?: Maybe<Scalars['String']>;
  value: Scalars['MonetaryValue'];
};

export type SuggestedAmountDescription = {
  amount?: InputMaybe<Scalars['Float']>;
  description?: InputMaybe<Scalars['String']>;
};

export type SupportedCurrencies = {
  __typename?: 'SupportedCurrencies';
  AUD?: Maybe<CurrencyDefaults>;
  CAD?: Maybe<CurrencyDefaults>;
  EUR?: Maybe<CurrencyDefaults>;
  GBP?: Maybe<CurrencyDefaults>;
  NZD?: Maybe<CurrencyDefaults>;
  USD?: Maybe<CurrencyDefaults>;
};

export type SupportedFeatures = {
  __typename?: 'SupportedFeatures';
  crypto?: Maybe<Scalars['Boolean']>;
  giftAid?: Maybe<Scalars['Boolean']>;
  guest?: Maybe<Scalars['Boolean']>;
};

export type Supporter = {
  __typename?: 'Supporter';
  amount?: Maybe<MonetaryAmount>;
  avatar?: Maybe<Scalars['URL']>;
  beneficiary?: Maybe<Scalars['String']>;
  causeSourceType?: Maybe<CauseSourceType>;
  creationDate?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  /** @deprecated Flattening Supporter as no need to have a donation object anymore */
  donation?: Maybe<Donation>;
  donationReference?: Maybe<Scalars['Int']>;
  donationStatus?: Maybe<SupporterDonationStatus>;
  giftAidAmount?: Maybe<MonetaryAmount>;
  id: Scalars['UUID'];
  legacyId?: Maybe<Scalars['String']>;
  matches?: Maybe<Array<DonationMatch>>;
  message?: Maybe<Scalars['String']>;
  pageName?: Maybe<Scalars['String']>;
  pageOwnerReaction?: Maybe<Reaction>;
  supporterPrivacy?: Maybe<SupporterPrivacy>;
  thirdPartyReference?: Maybe<Scalars['String']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  user?: Maybe<User>;
};

export type SupporterConnection = {
  __typename?: 'SupporterConnection';
  edges: Array<SupporterEdge>;
  nodes: Array<Supporter>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export enum SupporterDonationStatus {
  Declined = 'Declined',
  Invalid = 'Invalid',
  Pending = 'Pending',
  Succeeded = 'Succeeded',
}

export type SupporterEdge = {
  __typename?: 'SupporterEdge';
  cursor: Scalars['String'];
  node: Supporter;
};

export type SupporterPrivacy = {
  __typename?: 'SupporterPrivacy';
  amountPrivacy?: Maybe<SupporterVisibility>;
  avatarPrivacy?: Maybe<SupporterVisibility>;
  messagePrivacy?: Maybe<SupporterVisibility>;
};

export type SupporterTrend = {
  __typename?: 'SupporterTrend';
  count?: Maybe<Scalars['Int']>;
  days?: Maybe<Scalars['Int']>;
};

export enum SupporterVisibility {
  DonorAndCause = 'DonorAndCause',
  Public = 'Public',
}

export type SupportersSortInput = {
  donationValue?: InputMaybe<SortBy>;
};

export type Tag = {
  __typename?: 'Tag';
  tagDefinition: TagDefinition;
  /**
   * Stores the tag value as a single string or csv format for multiple .
   * Tags api accepts either value with valid string and undefined values or value undefined and values as valid array of non empty string.
   */
  value: Scalars['String'];
  /**
   * Values represents an array of assigned string items to the tags value. When assigned it stores a csv format of the array into value.
   * Tags api accepts either value with valid string and undefined values or value undefined and values as valid array of non empty string.
   */
  values?: Maybe<Array<Scalars['String']>>;
};

export type TagDefinition = {
  __typename?: 'TagDefinition';
  id: Scalars['String'];
  label?: Maybe<Scalars['String']>;
};

export type TagValue = {
  __typename?: 'TagValue';
  aggregation: Array<AggregationRule>;
  tagDefinition: TagDefinition;
  value: Scalars['String'];
  values?: Maybe<Array<Scalars['String']>>;
};

export type TagWithNode = {
  __typename?: 'TagWithNode';
  tagDefinition: TagDefinition;
  value: Scalars['String'];
  valueAsNode?: Maybe<Node>;
};

export enum TargetMetric {
  Distance = 'DISTANCE',
  Duration = 'DURATION',
  Elevation = 'ELEVATION',
}

export type TaskInput = {
  id: Scalars['String'];
  key?: InputMaybe<Scalars['String']>;
  type: PageType;
};

export type Tasks = {
  __typename?: 'Tasks';
  completed: Array<Maybe<Scalars['String']>>;
};

export type TaxPayerDetails = {
  __typename?: 'TaxPayerDetails';
  address?: Maybe<Address>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  selectionHash?: Maybe<Scalars['String']>;
};

export type TeamsFeature = {
  __typename?: 'TeamsFeature';
  teamDetailsEnabled: Scalars['Boolean'];
};

export type ThankYouMessage = {
  __typename?: 'ThankYouMessage';
  message?: Maybe<Scalars['String']>;
  type: ThankYouMessageType;
};

export type ThankYouMessageInput = {
  message?: InputMaybe<Scalars['String']>;
  type: ThankYouMessageType;
};

export enum ThankYouMessageType {
  Donation = 'DONATION',
}

export type ThirdPartyApplications = {
  __typename?: 'ThirdPartyApplications';
  fitbit?: Maybe<Fitbit>;
  strava?: Maybe<Strava>;
};

export type ThirdPartyApplicationsFeature = {
  __typename?: 'ThirdPartyApplicationsFeature';
  fitbitEnabled: Scalars['Boolean'];
  stravaEnabled: Scalars['Boolean'];
};

export type TimeBox = {
  __typename?: 'TimeBox';
  notAfter?: Maybe<Scalars['DateTime']>;
  notBefore?: Maybe<Scalars['DateTime']>;
};

export type TimeRemaining = {
  __typename?: 'TimeRemaining';
  days?: Maybe<Scalars['Int']>;
};

export enum TimeUnit {
  Milliseconds = 'MILLISECONDS',
  Seconds = 'SECONDS',
}

export type TimelineEntry = Node & {
  __typename?: 'TimelineEntry';
  createdAt?: Maybe<Scalars['DateTime']>;
  fitnessActivity?: Maybe<FitnessActivity>;
  id: Scalars['ID'];
  legacyId: Scalars['String'];
  media?: Maybe<Array<Media>>;
  message?: Maybe<Scalars['String']>;
  publishingSource?: Maybe<PublishingSource>;
  refreshRequestedTime?: Maybe<Scalars['DateTime']>;
  type: TimelineEntryType;
};

export type TimelineEntryConnection = {
  __typename?: 'TimelineEntryConnection';
  edges: Array<TimelineEntryEdge>;
  nodes: Array<TimelineEntry>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type TimelineEntryEdge = {
  __typename?: 'TimelineEntryEdge';
  cursor: Scalars['String'];
  node: TimelineEntry;
};

export type TimelineEntryInput = {
  test?: InputMaybe<Scalars['String']>;
};

export enum TimelineEntryType {
  Fitness = 'FITNESS',
  Manual = 'MANUAL',
}

export type TimelineFeature = {
  __typename?: 'TimelineFeature';
  fitbitEnabled: Scalars['Boolean'];
  manualEnabled: Scalars['Boolean'];
  stravaEnabled: Scalars['Boolean'];
};

export type TipJarConfiguration = {
  __typename?: 'TipJarConfiguration';
  defaultValue: Scalars['Float'];
  from: Scalars['Float'];
  isToInfinity: Scalars['Boolean'];
  to: Scalars['Float'];
  valueType: TipJarValueType;
  values?: Maybe<Array<Scalars['Float']>>;
};

export enum TipJarValueType {
  Amount = 'Amount',
  Percentage = 'Percentage',
}

export enum TipScheme {
  Disabled = 'Disabled',
  Dpf1_2 = 'Dpf1_2',
  TipJar2_1 = 'TipJar2_1',
  TipJar2_3 = 'TipJar2_3',
}

export enum Tone {
  Casual = 'CASUAL',
  Empathetic = 'EMPATHETIC',
  Formal = 'FORMAL',
  Grateful = 'GRATEFUL',
  Informative = 'INFORMATIVE',
  Inspirational = 'INSPIRATIONAL',
  Optimistic = 'OPTIMISTIC',
  Urgent = 'URGENT',
}

export type ToneOptionsInput = {
  prompt: Scalars['String'];
};

export type Total = {
  __typename?: 'Total';
  amounts: Array<TotalAmount>;
  measurementDomain: Scalars['String'];
  segment: Scalars['String'];
  segmentAsNode?: Maybe<Node>;
  tag: TagWithNode;
};

export type TotalAmount = {
  __typename?: 'TotalAmount';
  unit: Scalars['String'];
  value: Scalars['Float'];
};

export enum TotaliserTheme {
  Default = 'DEFAULT',
  LondonEye = 'LONDON_EYE',
  Star = 'STAR',
}

export type TransactingUser = {
  __typename?: 'TransactingUser';
  id: Scalars['ID'];
  savedCards: Array<PaymentCard>;
  transactingUserId: Scalars['ID'];
  type?: Maybe<TransactingUserType>;
};

export enum TransactingUserType {
  Guest = 'Guest',
  LoggedIn = 'LoggedIn',
}

export type Transaction = {
  __typename?: 'Transaction';
  id: Scalars['ID'];
  isComplete: Scalars['Boolean'];
  reference?: Maybe<Scalars['String']>;
  startedTime: Scalars['String'];
  transactionId?: Maybe<Scalars['String']>;
};

export enum TransactionStatus {
  Declined = 'Declined',
  Pending = 'Pending',
  Succeeded = 'Succeeded',
}

export type TransactionStatusInput = {
  transactionReference: Scalars['ID'];
};

export type TrendingCharities = {
  __typename?: 'TrendingCharities';
  edges: Array<TrendingCharitiesEdge>;
  nodes: Array<Charity>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type TrendingCharitiesEdge = {
  __typename?: 'TrendingCharitiesEdge';
  cursor: Scalars['String'];
  node: Charity;
};

export enum UnitOfMeasure {
  Days = 'DAYS',
  Feet = 'FEET',
  FeetClimbed = 'FEET_CLIMBED',
  Hours = 'HOURS',
  Kilometres = 'KILOMETRES',
  Metres = 'METRES',
  MetresClimbed = 'METRES_CLIMBED',
  Miles = 'MILES',
  Minutes = 'MINUTES',
}

export type UnlinkPageFromCampaignInput = {
  campaignId: Scalars['String'];
  pageSlug: Scalars['String'];
};

export type UnlinkTeamInput = {
  /** Captain ID of a team */
  captainId?: InputMaybe<Scalars['ID']>;
  /** ID of a page */
  id: Scalars['ID'];
  /** ID of the team to unlink */
  teamId: Scalars['ID'];
  /** Type of page */
  type: PageType;
};

export type UnlinkTeamPayload = {
  __typename?: 'UnlinkTeamPayload';
  /** The page that is unlinking */
  page?: Maybe<PageWithId>;
};

export type UpdateActivityInput = {
  date?: InputMaybe<Scalars['DateTime']>;
  name: Scalars['String'];
};

export type UpdateAnalyticsInput = {
  analytics: Array<AnalyticsInput>;
  campaignId: Scalars['String'];
  ownerUserGroupGuid: Scalars['String'];
};

export type UpdateCheckoutApplyPointsInput = {
  checkoutId: Scalars['ID'];
  paymentCardId: Scalars['String'];
  points: Scalars['Int'];
};

export type UpdateCheckoutGiftAidDeclarationInput = {
  checkoutId: Scalars['ID'];
  giftAidDetails?: InputMaybe<GiftAidDetailsInput>;
  isClaimed: Scalars['Boolean'];
};

export type UpdateCheckoutOrderAmountInput = {
  checkoutId: Scalars['ID'];
  crypto?: InputMaybe<CryptoInput>;
  currency: AcceptedCurrencyCode;
  directDebit?: InputMaybe<DirectDebitInput>;
  isAmountPrivate: Scalars['Boolean'];
  isRecurring: Scalars['Boolean'];
  recurrenceDayOfMonth?: InputMaybe<Scalars['String']>;
  tipScheme: TipScheme;
  tipValue?: InputMaybe<Scalars['MonetaryValue']>;
  value: Scalars['MonetaryValue'];
};

export type UpdateCheckoutPaymentTypeInput = {
  checkoutId: Scalars['ID'];
  selectedPaymentType: PaymentType;
};

export type UpdateCheckoutPersonalisationInput = {
  checkoutId: Scalars['ID'];
  displayName?: InputMaybe<Scalars['String']>;
  isAnonymous: Scalars['Boolean'];
  message: Scalars['String'];
};

export type UpdateCheckoutSelectedCardInput = {
  checkoutId: Scalars['ID'];
  selectedPaymentCardId: Scalars['String'];
};

export type UpdateCheckoutTipValueInput = {
  checkoutId: Scalars['UUID'];
  tipValue?: InputMaybe<Scalars['MonetaryValue']>;
};

export type UpdateLivestreamInput = {
  pageId: Scalars['String'];
  positionId: Scalars['Int'];
  streamId: Scalars['String'];
  streamType: Scalars['String'];
};

export type UpdatePageAsStaffInput = {
  birthday?: InputMaybe<BirthdayInput>;
  expiryDate?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  image?: InputMaybe<UpdatePageCoverInput>;
  occasion?: InputMaybe<OccasionInput>;
  slug?: InputMaybe<Scalars['Slug']>;
  status?: InputMaybe<PageStatus>;
  story?: InputMaybe<StoryInput>;
  supportsGiftAid?: InputMaybe<Scalars['Boolean']>;
  target?: InputMaybe<MonetaryAmountInput>;
  title?: InputMaybe<Scalars['String']>;
  type: PageType;
};

export type UpdatePageCoverInput = {
  media: MediaItemInput;
  pageId: Scalars['String'];
  pageType?: InputMaybe<PageType>;
};

export type UpdatePageCoverPayload = {
  __typename?: 'UpdatePageCoverPayload';
  cover?: Maybe<Media>;
};

export type UpdatePageGalleryInput = {
  media: MediaCollectionInput;
  pageId: Scalars['String'];
  pageType?: InputMaybe<PageType>;
};

export type UpdatePageGalleryPayload = {
  __typename?: 'UpdatePageGalleryPayload';
  media: Array<Media>;
};

export type UpdatePageInput = {
  activity?: InputMaybe<UpdateActivityInput>;
  attribution?: InputMaybe<Scalars['String']>;
  birthday?: InputMaybe<BirthdayInput>;
  branding?: InputMaybe<BrandingInput>;
  coverMediaCollectionId?: InputMaybe<Scalars['ID']>;
  expiryDate?: InputMaybe<Scalars['DateTime']>;
  fundraisingSettings?: InputMaybe<FundraisingSettingsInput>;
  galleryMediaCollectionId?: InputMaybe<Scalars['UUID']>;
  id: Scalars['ID'];
  image?: InputMaybe<UpdatePageCoverInput>;
  launchDate?: InputMaybe<Scalars['DateTime']>;
  membershipType?: InputMaybe<PageMembership>;
  occasion?: InputMaybe<OccasionInput>;
  redirectionPageUrl?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['Slug']>;
  status?: InputMaybe<PageStatus>;
  story?: InputMaybe<StoryInput>;
  summary?: InputMaybe<Scalars['String']>;
  supportsGiftAid?: InputMaybe<Scalars['Boolean']>;
  target?: InputMaybe<MonetaryAmountInput>;
  thumbnailImage?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<PageType>;
};

export type UpdatePagePayload = {
  __typename?: 'UpdatePagePayload';
  page?: Maybe<Page>;
};

export type UpdatePreferenceServiceInput = {
  collectionLocationId?: InputMaybe<Scalars['String']>;
  consents?: InputMaybe<Array<InputMaybe<ConsentsInput>>>;
  transactionId?: InputMaybe<Scalars['String']>;
  transactionTypeId?: InputMaybe<Scalars['String']>;
};

export type UpdateRelationshipFields = {
  requiredForChildren: Scalars['Boolean'];
};

export type UpdateRelationshipInput = {
  pageId: Scalars['String'];
  productType: ProductType;
  updateFields: UpdateRelationshipFields;
};

export type UpdateRelationshipsInput = {
  pageId: Scalars['String'];
  relationships?: InputMaybe<Array<UpdateRelationshipInput>>;
};

export type User = Node & {
  __typename?: 'User';
  /** @deprecated Used only to aggregate requests for GG.Profile.Web; CF pages are no longer returned - use pages query instead */
  asks?: Maybe<Array<Maybe<AsksItem>>>;
  avatar?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['CountryCode']>;
  donationSummaries?: Maybe<Array<DonorDonationSummary>>;
  donationTotals?: Maybe<UserDonationSummary>;
  donations?: Maybe<DonationConnection>;
  eligibleChildPages?: Maybe<PageConnection>;
  emailAddress?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  legacyId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  /** Find an page by its login that the user has permission for. */
  page?: Maybe<Page>;
  /** A list of pages that the user owns. */
  pages?: Maybe<PageConnection>;
  thirdPartyApplications?: Maybe<ThirdPartyApplications>;
  userPreferences?: Maybe<UserPreferences>;
};

export type UserAsksArgs = {
  productNames?: InputMaybe<Array<ProductType>>;
  status?: InputMaybe<PageStatus>;
  statuses?: InputMaybe<Array<PageStatus>>;
};

export type UserDonationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<DateFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type UserEligibleChildPagesArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  parentPageId: Scalars['UUID'];
};

export type UserPageArgs = {
  id?: InputMaybe<Scalars['ID']>;
  slug?: InputMaybe<Scalars['Slug']>;
  type?: InputMaybe<PageType>;
};

export type UserPagesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  canParticipateIn?: InputMaybe<CanParticipateInInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  productNames?: InputMaybe<Array<ProductType>>;
  relationshipId?: InputMaybe<Scalars['ID']>;
  status?: InputMaybe<PageStatus>;
  statuses?: InputMaybe<Array<PageStatus>>;
  type?: InputMaybe<PageType>;
};

export type UserDonationSummary = {
  __typename?: 'UserDonationSummary';
  totalGivenAmount?: Maybe<MonetaryAmount>;
  totalReceivedAmount?: Maybe<MonetaryAmount>;
};

export type UserInput = {
  bio?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type UserPreferences = {
  __typename?: 'UserPreferences';
  allowCharityContact: Scalars['Boolean'];
};

export type VerifyCheckout3DsInput = {
  checkoutId: Scalars['ID'];
  nonce: Scalars['String'];
};

export type VerifyCheckoutWalletInput = {
  checkoutId: Scalars['ID'];
  paymentProviderDetails: Scalars['JSON'];
  paymentType: WalletPaymentType;
};

export type VideoMedia = Node & {
  __typename?: 'VideoMedia';
  embeddedVideoURL?: Maybe<Scalars['URL']>;
  id: Scalars['ID'];
  legacyId?: Maybe<Scalars['Int']>;
  /** @deprecated Use `videoPlatform` with the type VideoMediaPlatform */
  platform?: Maybe<Scalars['String']>;
  posterUrl?: Maybe<Scalars['URL']>;
  url?: Maybe<Scalars['URL']>;
  /** @deprecated Use `url` to send the whole URL */
  videoId?: Maybe<Scalars['String']>;
  /** @deprecated Use `url` to send the whole URL */
  videoPlatform?: Maybe<VideoMediaPlatform>;
};

export type VideoMediaInput = {
  id?: InputMaybe<Scalars['ID']>;
  isHero?: InputMaybe<Scalars['Boolean']>;
  platform?: InputMaybe<VideoMediaPlatform>;
  url?: InputMaybe<Scalars['URL']>;
  videoId?: InputMaybe<Scalars['ID']>;
  videoPlatform?: InputMaybe<VideoMediaPlatform>;
};

export enum VideoMediaPlatform {
  Vimeo = 'VIMEO',
  Youtube = 'YOUTUBE',
}

export type VideoStory = {
  __typename?: 'VideoStory';
  status: Scalars['String'];
  videoUrl?: Maybe<Scalars['String']>;
};

export type VoluntaryContributionConfig = {
  __typename?: 'VoluntaryContributionConfig';
  defaultValue: Scalars['MonetaryValue'];
  from: Scalars['MonetaryValue'];
  to: Scalars['MonetaryValue'];
  valueType: VoluntaryContributionConfigValueType;
  values: Array<Scalars['MonetaryValue']>;
};

export enum VoluntaryContributionConfigValueType {
  Amount = 'Amount',
  Percentage = 'Percentage',
}

export enum WalletPaymentType {
  BraintreeApplePay = 'BraintreeApplePay',
  BraintreeGooglePay = 'BraintreeGooglePay',
  BraintreePayPal = 'BraintreePayPal',
  BraintreeVenmo = 'BraintreeVenmo',
  JustGivingVoucher = 'JustGivingVoucher',
}

export type Withdrawal = {
  __typename?: 'Withdrawal';
  completionDate?: Maybe<Scalars['DateTime']>;
  gross: MonetaryAmount;
  net: MonetaryAmount;
  platformFee: MonetaryAmount;
  processingFee: MonetaryAmount;
  requestDate: Scalars['DateTime'];
  status: WithdrawalStatus;
};

export type WithdrawalConnection = {
  __typename?: 'WithdrawalConnection';
  availabilityDate?: Maybe<Scalars['DateTime']>;
  nodes?: Maybe<Array<Withdrawal>>;
  status?: Maybe<Scalars['String']>;
  totalAvailable: MonetaryAmount;
  totalWithdrawn: MonetaryAmount;
};

export enum WithdrawalStatus {
  Complete = 'COMPLETE',
  Failed = 'FAILED',
  Processing = 'PROCESSING',
}
