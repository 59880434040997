import React, { FunctionComponent } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { LineItemType } from '../../../../types/__generated-graphQL__';
import { AmountPageFormValues, TransactionTypes } from '../../../../types/formValues';
import { InfoBox } from '../../components/InfoBox/InfoBox';
import ButtonTextLink from '../../components/button/ButtonTextLink';
import { useFetchCheckoutQuery } from '../../redux/checkoutApiSlice/queryCheckoutApi';
import { getGQLPageOwnerName } from '../../redux/checkoutApiSlice/selectors/complex.selectors';
import { getGQLOrderType } from '../../redux/checkoutApiSlice/selectors/lineItem.selectors';
import * as styles from './RecurrenceUpgrade.css';
import { useIsRecurringPaymentAvailable } from './useRecurringAvailable';

const RecurrenceUpgrade: FunctionComponent = () => {
  const { orderType, pageOwnerName } = useFetchCheckoutQuery(undefined, {
    selectFromResult: ({ data }) => {
      return {
        orderType: getGQLOrderType(data),
        pageOwnerName: getGQLPageOwnerName(data),
      };
    },
  });
  const { isRecurringCardAvailable, isDirectDebitAvailable } = useIsRecurringPaymentAvailable();

  const { setValue } = useFormContext<AmountPageFormValues>();
  const transactionType = useWatch<AmountPageFormValues>({ name: 'transactionType' });

  const { t } = useTranslation();

  if (
    orderType !== LineItemType.DonationCharityDirect ||
    transactionType !== TransactionTypes.ONE_OFF ||
    !(isRecurringCardAvailable || isDirectDebitAvailable)
  )
    return null;

  return (
    <InfoBox>
      <p>
        {t('amount|upgrade', {
          name: pageOwnerName ?? 'your charity',
          ns: 'recurring',
        })}
      </p>
      <ButtonTextLink
        className={styles.link}
        onClick={() => {
          setValue('transactionType', TransactionTypes.RECURRING);
        }}
      >
        {t('amount|upgrade|link', { ns: 'recurring' })}
      </ButtonTextLink>
    </InfoBox>
  );
};

export default RecurrenceUpgrade;
