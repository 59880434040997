import React, { FunctionComponent, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AddPaymentCardPageFormValues } from '../../../../types/formValues';
import { sprinkles } from '../../../styles/sprinkles.css';
import Input from '../../components/Form/Input/Input';
import Label from '../../components/Form/Label/Label';
import Select from '../../components/Form/Select/Select';
import { FormError } from '../../components/FormError/FormError';
import { USStatesSelectList } from '../../config/country/USStates.ISO3166-2';
import { canadaProvincesSelectList } from '../../config/country/canadaProvinces.ISO3166-2';
import { useFetchCheckoutQuery } from '../../redux/checkoutApiSlice/queryCheckoutApi';
import { getGQLUserLocale } from '../../redux/checkoutApiSlice/selectors/root.selectors';
import { getCountryOptionsList } from '../../utils/getCountry';

type CountryData = { value: string; label: string };
type Countries = 'US' | 'CA';
type StateOrRegionRequiredCountries = Record<
  Countries,
  {
    error: string;
    label: string;
    dropdownValues: { value: string; label: string }[];
  }
>;

const BillingAddressForm: FunctionComponent = () => {
  const { t } = useTranslation();
  const formMethods = useFormContext<AddPaymentCardPageFormValues>();
  const {
    formState: { errors },
    setValue,
  } = formMethods;
  const { userLocale } = useFetchCheckoutQuery(undefined, {
    selectFromResult: ({ data }) => {
      return {
        userLocale: getGQLUserLocale(data),
      };
    },
  });

  const countryCodeDefaultValue = userLocale || '';
  const countryCode = useWatch<AddPaymentCardPageFormValues, 'countryCode'>({
    name: 'countryCode',
    defaultValue: countryCodeDefaultValue,
  });
  const countryList: CountryData[] = useMemo(() => getCountryOptionsList(), []);
  const countryStateOrProvince: StateOrRegionRequiredCountries = useMemo(
    () => ({
      US: {
        label: t('billingAddress|stateLabel', { ns: 'payment' }),
        error: t('billingAddress|state|mandatory', { ns: 'validation' }),
        dropdownValues: USStatesSelectList,
      },
      CA: {
        label: t('billingAddress|provinceLabel', { ns: 'payment' }),
        error: t('billingAddress|province|mandatory', { ns: 'validation' }),
        dropdownValues: canadaProvincesSelectList,
      },
    }),
    [t],
  );
  const countryStateOrProvinceData = countryStateOrProvince[countryCode as Countries];

  return (
    <>
      <div className={sprinkles({ marginBottom: { desktop: 'sizeSpacing05', mobile: 'sizeSpacing04' } })}>
        <Label htmlFor="addressLine1">{t('billingAddress|addressLine1Label', { ns: 'payment' })}</Label>
        <Input
          name="addressLine1"
          id="addressLine1"
          data-qa="billing_AddressLine1"
          autoComplete="billing address-line1"
          registerMethods={{
            setValueAs: value => value?.trim(),
            required: { value: true, message: t('billingAddress|addressLine1|mandatory', { ns: 'validation' }) },
            minLength: { value: 2, message: t('billingAddress|addressLine1|minLength', { ns: 'validation' }) },
            maxLength: { value: 50, message: t('billingAddress|addressLine1|maxLength', { ns: 'validation' }) },
          }}
          required
        />
        <FormError<AddPaymentCardPageFormValues> fieldName="addressLine1" />
      </div>
      <div className={sprinkles({ marginBottom: { desktop: 'sizeSpacing05', mobile: 'sizeSpacing04' } })}>
        <Label htmlFor="addressLine2">{t('billingAddress|addressLine2Label', { ns: 'payment' })}</Label>
        <Input
          name="addressLine2"
          id="addressLine2"
          data-qa="billing_AddressLine2"
          autoComplete="billing address-line2"
          registerMethods={{
            setValueAs: value => value?.trim(),
            maxLength: { value: 50, message: t('billingAddress|addressLine2|maxLength', { ns: 'validation' }) },
          }}
        />
        <FormError<AddPaymentCardPageFormValues> fieldName="addressLine2" />
      </div>
      <div className={sprinkles({ marginBottom: { desktop: 'sizeSpacing05', mobile: 'sizeSpacing04' } })}>
        <Label htmlFor="townOrCity">{t('billingAddress|townLabel', { ns: 'payment' })}</Label>
        <Input
          id="townOrCity"
          name="townOrCity"
          data-qa="billing_TownCity"
          autoComplete="billing locality"
          registerMethods={{
            setValueAs: value => value?.trim(),
            required: { value: true, message: t('billingAddress|townOrCity|mandatory', { ns: 'validation' }) },
            minLength: { value: 2, message: t('billingAddress|townOrCity|minLength', { ns: 'validation' }) },
            maxLength: { value: 50, message: t('billingAddress|townOrCity|maxLength', { ns: 'validation' }) },
          }}
          required
        />
        <FormError<AddPaymentCardPageFormValues> fieldName="townOrCity" />
      </div>
      <div className={sprinkles({ marginBottom: { desktop: 'sizeSpacing05', mobile: 'sizeSpacing04' } })}>
        <Label htmlFor="zipOrPostalCode">{t('billingAddress|internationalPostalCodeLabel', { ns: 'payment' })}</Label>
        <Input
          name="zipOrPostalCode"
          id="zipOrPostalCode"
          data-qa="billing_PostalCode"
          autoComplete="billing postal-code"
          registerMethods={{
            setValueAs: value => value?.trim(),
            required: { value: true, message: t('billingAddress|zipOrPostalCode|mandatory', { ns: 'validation' }) },
            minLength: { value: 2, message: t('billingAddress|zipOrPostalCode|minLength', { ns: 'validation' }) },
            maxLength: { value: 10, message: t('billingAddress|zipOrPostalCode|maxLength', { ns: 'validation' }) },
          }}
          required
        />
        <FormError<AddPaymentCardPageFormValues> fieldName="zipOrPostalCode" />
      </div>
      {countryStateOrProvinceData && (
        <div className={sprinkles({ marginBottom: { desktop: 'sizeSpacing05', mobile: 'sizeSpacing04' } })}>
          <Label htmlFor="countyStateRegion">{countryStateOrProvinceData.label}</Label>
          <Select
            name="countyStateRegion"
            id="countyStateRegion"
            data-qa="county-state-select"
            registerMethods={{
              required: { value: true, message: countryStateOrProvinceData.error },
            }}
            required
          >
            <option key="select-value" value="">
              {t('selectListPlaceholder')}
            </option>
            {countryStateOrProvinceData.dropdownValues.map((item, index) => (
              <option key={`${item.value}-${index}`} value={item.value}>
                {item.label}
              </option>
            ))}
          </Select>
          <FormError<AddPaymentCardPageFormValues> fieldName={'countyStateRegion'} />
        </div>
      )}
      <div className={sprinkles({ marginBottom: { desktop: 'sizeSpacing05', mobile: 'sizeSpacing04' } })}>
        <Label htmlFor="countryCode">{t('billingAddress|countryLabel', { ns: 'payment' })}</Label>
        <Select
          name="countryCode"
          registerMethods={{
            onChange: () => setValue('countyStateRegion', ''),
            required: { value: true, message: t('billingAddress|country|mandatory', { ns: 'validation' }) },
          }}
          defaultValue={countryCodeDefaultValue}
          id="countryCode"
          data-qa="country-select"
          required
          aria-invalid={Boolean(errors['countryCode'])}
        >
          <option key="select-value" value="">
            {t('selectListPlaceholder')}
          </option>
          {countryList.map((item, index) => (
            <option key={`${item.value}-${index}`} value={item.value}>
              {item.label}
            </option>
          ))}
        </Select>
        <FormError<AddPaymentCardPageFormValues> fieldName="countryCode" />
      </div>
    </>
  );
};

export default BillingAddressForm;
