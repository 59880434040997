import React, { FunctionComponent, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import classNames from 'classnames';

import { Cryptocurrency } from '../../../../types/__generated-graphQL__';
import { AmountPageFormValues } from '../../../../types/formValues';
import { sprinkles } from '../../../styles/sprinkles.css';
import { FormError } from '../../components/FormError/FormError';
import SkeletonLoader from '../../components/SkeletonLoader/SkeletonLoader';
import VisuallyHidden from '../../components/VisuallyHidden/VisuallyHidden';
import { CONSTANTS } from '../../config/constants';
import { useMinMaxDonationAmounts } from '../../hooks/useMinMaxDonationAmounts';
import { formatCurrencySymbol } from '../../utils/currency';
import { formatCurrency } from '../../utils/value';
import * as styles from './CryptoAmount.css';
import { calculateCryptoAmount, calculateFiatAmount } from './cryptoUtils';

type CryptoAmountProps = {
  cryptocurrencyData: Cryptocurrency[];
};

export const CryptoAmount: FunctionComponent<CryptoAmountProps> = ({ cryptocurrencyData }) => {
  const { t } = useTranslation();
  const [donationCurrencyCode, cryptocurrencyFullName, fiatAmount, cryptoAmount, exchangeRate] = useWatch<
    AmountPageFormValues,
    [
      'donationCurrencyCode',
      'crypto.currencyFullName',
      'crypto.fiatAmount',
      'crypto.cryptoAmount',
      'crypto.estimatedExchangeRate',
    ]
  >({
    name: [
      'donationCurrencyCode',
      'crypto.currencyFullName',
      'crypto.fiatAmount',
      'crypto.cryptoAmount',
      'crypto.estimatedExchangeRate',
    ],
  });
  const {
    register,
    setValue,
    trigger,
    formState: { errors, touchedFields },
  } = useFormContext<AmountPageFormValues>();
  const [editMode, setEditMode] = useState<'crypto' | 'currencyValue'>('crypto');

  const { shortCode: cryptoShortCode } =
    cryptocurrencyData.find(crypto => crypto.name === cryptocurrencyFullName) ?? {};

  const { min: fiatMin, max: fiatMax } = useMinMaxDonationAmounts();
  const cryptoMin = Number(calculateCryptoAmount(fiatMin, Number(exchangeRate)));
  const cryptoMax = Number(calculateCryptoAmount(fiatMax, Number(exchangeRate)));

  if (!cryptoShortCode) {
    return (
      <>
        <input type="hidden" {...register('crypto.fiatAmount')} />
        <CryptoAmountLoader isAnimated={false} />
      </>
    );
  }

  const currencySymbol = formatCurrencySymbol(donationCurrencyCode);
  const fiatCurrencyName = t(`currency|${donationCurrencyCode}`);

  return (
    <>
      <div className={styles.wrapper}>
        <button
          type="button"
          className={styles.switchButton}
          data-qa="crypto-switch-values-button"
          onClick={() => {
            if (editMode === 'crypto' && !Number(cryptoAmount)) {
              setValue('crypto.cryptoAmount', '0');
            } else if (!Number(fiatAmount)) {
              setValue('crypto.fiatAmount', '0');
            }
            setEditMode(current => (current === 'crypto' ? 'currencyValue' : 'crypto'));
          }}
          aria-label={
            editMode === 'crypto'
              ? t('switchToFiatAriaLabel', {
                  ns: 'crypto',
                  cryptocurrency: `${cryptoShortCode} (${cryptocurrencyFullName})`,
                  fiatCurrency: fiatCurrencyName,
                })
              : t('switchToCryptoAriaLabel', {
                  ns: 'crypto',
                  cryptocurrency: cryptocurrencyFullName,
                  fiatCurrency: fiatCurrencyName,
                })
          }
        >
          <span
            className={styles.buttonText}
            data-qa={editMode === 'crypto' ? 'selected-crypto-shortcode' : 'fiat-currency-shortcode'}
          >
            {editMode === 'crypto' ? cryptoShortCode : donationCurrencyCode}
          </span>
          <span
            className={styles.altValue}
            data-qa={editMode === 'crypto' ? 'fiat-currency-shortcode' : 'selected-crypto-shortcode'}
          >
            {editMode === 'crypto' ? donationCurrencyCode : cryptoShortCode}
          </span>
        </button>
        <div className={styles.inputWrapper}>
          {editMode === 'currencyValue' && (
            <div className={styles.currencySymbolWrapper} aria-hidden>
              {currencySymbol}
            </div>
          )}
          <input
            type={editMode === 'currencyValue' ? 'text' : 'hidden'}
            inputMode="decimal"
            pattern="[0-9]*"
            autoComplete="off"
            required
            aria-label={t('donationAmountInputLabel', {
              ns: 'crypto',
              currencyName: fiatCurrencyName,
            })}
            data-qa="crypto.fiatAmount"
            aria-invalid={Boolean(errors.crypto)}
            className={classNames(styles.input, styles.editCurrencyValueMode, {
              [styles.editCurrencyValueError]: Boolean(errors.crypto),
            })}
            {...register('crypto.fiatAmount', {
              onChange: event => {
                setValue('crypto.cryptoAmount', calculateCryptoAmount(event.target.value, Number(exchangeRate)));
                setValue('donationValue', event.target.value);
              },
              validate: {
                acceptableRange: (value, values) => {
                  const valueNumber = Number(value);
                  const message = t('crypto|amount-invalid', {
                    fiatMin: formatCurrency(donationCurrencyCode, fiatMin, false),
                    fiatMax: formatCurrency(donationCurrencyCode, fiatMax, false),
                    cryptoMin,
                    cryptoMax,
                    cryptoCurrency: values.crypto?.currencyShortName,
                    ns: 'validation',
                  });

                  if (
                    !value ||
                    isNaN(valueNumber) ||
                    valueNumber < fiatMin ||
                    valueNumber > fiatMax ||
                    !CONSTANTS.CURRENCY_REGEXP.test(value)
                  ) {
                    return message;
                  }
                  return true;
                },
              },
            })}
          />
          <input
            type={editMode === 'crypto' ? 'text' : 'hidden'}
            inputMode="decimal"
            pattern="[0-9]*"
            autoComplete="off"
            required
            aria-label={t('donationAmountInputLabel', {
              ns: 'crypto',
              currencyName: cryptocurrencyFullName,
            })}
            aria-invalid={Boolean(errors.crypto)}
            className={classNames(styles.input, {
              [styles.editCurrencyValueError]: Boolean(errors.crypto),
            })}
            {...register('crypto.cryptoAmount', {
              onChange: event => {
                const shouldValidate =
                  Boolean(errors.crypto?.fiatAmount) ||
                  ((isNaN(Number(event.target.value)) ||
                    event.target.value < cryptoMin ||
                    event.target.value > cryptoMax) &&
                    touchedFields.crypto?.cryptoAmount);
                setValue('crypto.fiatAmount', calculateFiatAmount(event.target.value, Number(exchangeRate)), {
                  shouldValidate,
                  shouldTouch: true,
                });
                setValue('donationValue', calculateFiatAmount(event.target.value, Number(exchangeRate)), {
                  shouldValidate,
                });
              },
              onBlur: event => {
                const shouldValidateFiat =
                  isNaN(Number(event.target.value)) || event.target.value < cryptoMin || event.target.value > cryptoMax;
                if (shouldValidateFiat) {
                  trigger('crypto.fiatAmount');
                }
              },
            })}
          />
          <span
            className={styles.altValue}
            aria-hidden
            data-qa={editMode === 'crypto' ? 'selected-crypto-amount' : 'fiat-currency-amount'}
          >
            {editMode === 'crypto' ? formatCurrency(donationCurrencyCode, fiatAmount ?? 0) : cryptoAmount}
          </span>
        </div>
        <VisuallyHidden>
          {t('accessibleCurrencyExchangeDetails', {
            ns: 'crypto',
            amount: editMode === 'crypto' ? formatCurrency(donationCurrencyCode, fiatAmount ?? 0) : cryptoAmount,
            currencyName: editMode === 'crypto' ? fiatCurrencyName : cryptocurrencyFullName,
          })}
        </VisuallyHidden>
      </div>
      <FormError fieldName="crypto.fiatAmount" />
    </>
  );
};

type CryptoAmountLoaderProps = {
  isAnimated?: boolean;
};

export const CryptoAmountLoader: FunctionComponent<CryptoAmountLoaderProps> = ({ isAnimated = true }) => {
  return (
    <div className={styles.loadingWrapper}>
      <div className={styles.loadingCurrencyWrapper}>
        <SkeletonLoader isAnimated={isAnimated} fontSize="24px" />
        <SkeletonLoader isAnimated={isAnimated} className={sprinkles({ marginTop: 'sizeSpacing02' })} />
      </div>
      <div className={styles.loadingAmountWrapper}>
        <SkeletonLoader isAnimated={isAnimated} fontSize="24px" animationDelay="0.15s" />
        <SkeletonLoader
          isAnimated={isAnimated}
          className={sprinkles({ marginTop: 'sizeSpacing02' })}
          animationDelay="0.15s"
        />
      </div>
    </div>
  );
};
