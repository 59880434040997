import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useMatch } from 'react-router';

import { PAGES } from '../../../config/pages';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { useFetchCheckoutQuery } from '../../../redux/checkoutApiSlice/queryCheckoutApi';
import { getGQLShouldRouteToPWPPage } from '../../../redux/checkoutApiSlice/selectors/complex.selectors';
import { getGQLCurrencyCode } from '../../../redux/checkoutApiSlice/selectors/order.selectors';
import { getGQLSelectedPaymentType } from '../../../redux/checkoutApiSlice/selectors/payment.selectors';
import { formatCurrency, formatPoints } from '../../../utils/value';
import { getAmexPWPAppliedPoints } from '../../AmexPayWithPoints/AmexPayWithPoints.selectors';
import { AccordionPageCurrentStep } from '../AccordionPageCurrentStep';
import { AccordionPageFilledStep } from '../AccordionPageFilledStep';
import { AccordionPageLoadingStep } from '../AccordionPageLoadingStep';
import { AccordionPageNewStep } from '../AccordionPageNewStep';
import { AccordionPagePreviewEmerge } from '../AccordionPagePreviewEmerge';
import { AccordionPageSectionWrapper } from '../AccordionPageSectionWrapper';
import type { PageSectionProps } from './AccordionSection';

const AmexPWPStepPreview: FunctionComponent = () => {
  const { t } = useTranslation();

  const { currency, selectedPaymentType } = useFetchCheckoutQuery(undefined, {
    selectFromResult: ({ data }) => {
      return {
        currency: getGQLCurrencyCode(data),
        selectedPaymentType: getGQLSelectedPaymentType(data),
      };
    },
  });
  const { appliedPoints = 0, pointCurrencyValue = 0 } = useAppSelector(getAmexPWPAppliedPoints) || {};

  if (selectedPaymentType) {
    return (
      <AccordionPageFilledStep
        title={t('thankyou|amexMemberRewards', { ns: 'amexPwp' })}
        routeTo={'AmexPayWithPointsYourPaymentPage'}
        a11yRouteText={t('thankyou|amexMemberRewards', { ns: 'amexPwp' })}
      >
        {`(${formatPoints(appliedPoints)} ${t('points', {
          ns: 'amexPwp',
          points: formatCurrency(currency, pointCurrencyValue),
        })})`}
      </AccordionPageFilledStep>
    );
  }

  return <AccordionPageNewStep>{t('thankyou|amexMemberRewards', { ns: 'amexPwp' })}</AccordionPageNewStep>;
};

const AmexPWPPageSection: FunctionComponent<PageSectionProps> = ({ children, currentPageCollapsed, isLoading }) => {
  const { shouldRouteToPWPPage } = useFetchCheckoutQuery(undefined, {
    selectFromResult: ({ data }) => {
      return {
        shouldRouteToPWPPage: getGQLShouldRouteToPWPPage(data),
      };
    },
  });

  const isCurrentPage = useMatch(`../${PAGES.AmexPayWithPointsYourPaymentPage}`);

  if (!shouldRouteToPWPPage) return null;

  if (isLoading) return <AccordionPageLoadingStep />;

  if (isCurrentPage) {
    return (
      <li>
        <AccordionPageSectionWrapper>
          {currentPageCollapsed && (
            <AccordionPagePreviewEmerge>
              <AmexPWPStepPreview />
            </AccordionPagePreviewEmerge>
          )}
          <AccordionPageCurrentStep collapsed={currentPageCollapsed}>{children}</AccordionPageCurrentStep>
        </AccordionPageSectionWrapper>
      </li>
    );
  }

  return (
    <li>
      <AmexPWPStepPreview />
    </li>
  );
};

export default AmexPWPPageSection;
