import { TipJarConfigSet } from '../../../types/tipjarConfig';
import { HACK_getGQLIsViralPageBySlug } from '../config/hacks';
import { tipJarConfigDefault17 } from '../config/tipjar/tipjarConfigDefault17';
import { tipJarConfigDefault18 } from '../config/tipjar/tipjarConfigDefault18';
import { tipJarConfigMacmillan } from '../config/tipjar/tipjarConfigMacmillan';
import { tipjarConfigViralPage } from '../config/tipjar/tipjarConfigViralPage';
import { useFetchCheckoutQuery } from '../redux/checkoutApiSlice/queryCheckoutApi';
import { getGQLLegacyCharityId } from '../redux/checkoutApiSlice/selectors/complex.selectors';
import { getGQLIsAmountLocked } from '../redux/checkoutApiSlice/selectors/customAttributes.selectors';
import { getGQLisTipJarEnabled } from '../redux/checkoutApiSlice/selectors/lineItem.selectors';
import { useTipJarAbTest } from './useTipJarAbTest';

export type ShowTipOnOptions = 'summary' | 'disabled';
export type ShowTipAsOptions = 'slider' | 'radios' | 'disabled';

type TipJarModeConfig = {
  showTipOn: ShowTipOnOptions;
  showTipAs: ShowTipAsOptions;
  tipJarValuesOverride?: TipJarConfigSet;
  isFlippedSummary?: boolean;
  showTotal?: boolean;
};

export const useTipJarModeConfig = (): TipJarModeConfig => {
  const { isViralPage, isAmountLocked, tipJarEnabled, charityId } = useFetchCheckoutQuery(undefined, {
    selectFromResult: ({ data }) => {
      return {
        isViralPage: HACK_getGQLIsViralPageBySlug(data),
        isAmountLocked: getGQLIsAmountLocked(data),
        tipJarEnabled: getGQLisTipJarEnabled(data),
        charityId: getGQLLegacyCharityId(data),
      };
    },
  });

  const [{ variationKey: standardVariationKey }] = useTipJarAbTest();

  if (!tipJarEnabled) {
    return { showTipOn: 'disabled', showTipAs: 'disabled' };
  }

  if (isAmountLocked) {
    return {
      showTipOn: 'summary',
      showTipAs: 'slider',
    };
  }

  if (isViralPage) {
    return {
      showTipOn: 'summary',
      showTipAs: 'radios',
      tipJarValuesOverride: tipjarConfigViralPage,
    };
  }

  // Macmillan behaviour
  if (charityId === '2116' || charityId === '3420646') {
    return {
      showTipOn: 'summary',
      showTipAs: 'radios',
      tipJarValuesOverride: tipJarConfigMacmillan,
    };
  }

  // Standard tipjar ab test start
  if (standardVariationKey === 'b') {
    return {
      showTipOn: 'summary',
      showTipAs: 'slider',
      tipJarValuesOverride: tipJarConfigDefault17,
      showTotal: true,
    };
  }
  if (standardVariationKey === 'c') {
    return {
      showTipOn: 'summary',
      showTipAs: 'slider',
      tipJarValuesOverride: tipJarConfigDefault18,
      showTotal: true,
    };
  }
  // Standard tipjar ab test end

  // Default behaviour
  return {
    showTipOn: 'summary',
    showTipAs: 'slider',
  };
};
