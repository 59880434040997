import { useContext, useEffect } from 'react';

import { enums, OptimizelyContext, OptimizelyDecision } from '@optimizely/react-sdk';

import { abTestKeys } from '../config/abTests';
import { updateEnrolledInOptimizely } from '../modules/ABTests/abTests.actions';
import { addHeapEventPropertiesWhenReady } from '../utils/heap';
import { useAppDispatch } from './reduxHooks';

export const optimizelyHeapPropertiesNotTracked: Record<string, string> = {};

const useOptimizelyListener = () => {
  const dispatch = useAppDispatch();

  const { optimizely } = useContext(OptimizelyContext);

  useEffect(() => {
    const processedTests: Record<string, string> = {};

    const optimizelyListener = optimizely?.notificationCenter.addNotificationListener<{
      type: string;
      decisionInfo: OptimizelyDecision;
      userId: string;
    }>(enums.NOTIFICATION_TYPES.DECISION, ({ type, decisionInfo }) => {
      if (type === 'flag' && decisionInfo.variationKey) {
        if (processedTests[decisionInfo.flagKey] !== decisionInfo.variationKey) {
          if (Object.values(abTestKeys).some(ab => ab.flagKey === decisionInfo.flagKey && ab.trackInHeap)) {
            if (window.Cookiebot?.consent?.marketing) {
              const eventProperties = {
                [decisionInfo.flagKey]: decisionInfo.variationKey,
              };
              addHeapEventPropertiesWhenReady(eventProperties);
            } else {
              optimizelyHeapPropertiesNotTracked[decisionInfo.flagKey] = decisionInfo.variationKey;
            }
          }
          window.Sentry?.addBreadcrumb({
            category: 'AbEnrolled',
            message: `Enrolled into ${decisionInfo.flagKey}`,
            level: 'info',
            data: {
              flagKey: decisionInfo.flagKey,
              variationKey: decisionInfo.variationKey,
              variables: decisionInfo.variables,
            },
          });
          processedTests[decisionInfo.flagKey] = decisionInfo.variationKey;
          dispatch(updateEnrolledInOptimizely(decisionInfo.flagKey, decisionInfo.variationKey, decisionInfo.variables));
        }
      }
    });
    return () => {
      if (!optimizelyListener) return;
      optimizely?.notificationCenter.removeNotificationListener(optimizelyListener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useOptimizelyListener;
