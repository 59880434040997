import React, { FunctionComponent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMatch } from 'react-router';

import classNames from 'classnames';

import { PaymentType } from '../../../../../types/__generated-graphQL__';
import { sprinkles } from '../../../../styles/sprinkles.css';
import { PAGES } from '../../../config/pages';
import { RECURRENCE_DAYS_OF_MONTH } from '../../../config/recurrenceDaysOfMonth';
import { useFetchCheckoutQuery } from '../../../redux/checkoutApiSlice/queryCheckoutApi';
import { getGQLIsRecurringOrDirectDebit } from '../../../redux/checkoutApiSlice/selectors/complex.selectors';
import { getGQLIsAmountLocked } from '../../../redux/checkoutApiSlice/selectors/customAttributes.selectors';
import {
  getGQLLineItemValueInPounds,
  getGQLRecurrenceDayOfMonth,
  getGQLRecurrenceNextPaymentDate,
} from '../../../redux/checkoutApiSlice/selectors/lineItem.selectors';
import { getGQLCurrencyCode } from '../../../redux/checkoutApiSlice/selectors/order.selectors';
import {
  getGQLCryptoDetails,
  getGQLSelectedPaymentType,
} from '../../../redux/checkoutApiSlice/selectors/payment.selectors';
import { humanizedDate } from '../../../utils/date';
import { formatCurrency } from '../../../utils/value';
import { calculateCryptoAmount } from '../../Crypto/cryptoUtils';
import { AccordionPageCurrentStep } from '../AccordionPageCurrentStep';
import { AccordionPageFilledStep } from '../AccordionPageFilledStep';
import { AccordionPageLoadingStep } from '../AccordionPageLoadingStep';
import { AccordionPageNewStep } from '../AccordionPageNewStep';
import { AccordionPagePreviewEmerge } from '../AccordionPagePreviewEmerge';
import { AccordionPageSectionWrapper } from '../AccordionPageSectionWrapper';
import type { PageSectionProps } from './AccordionSection';

const AmountStepPreview: FunctionComponent = () => {
  const { t } = useTranslation();

  const {
    currency,
    lineItemValueInPounds,
    isAmountLocked,
    selectedPaymentType,
    isRecurringOrDirectDebit,
    recurrenceDayOfMonth,
    recurrenceNextPaymentDate,
    estimatedExchangeRate,
    currencyShortName,
  } = useFetchCheckoutQuery(undefined, {
    selectFromResult: ({ data }) => {
      const cryptoDetails = getGQLCryptoDetails(data);
      return {
        currency: getGQLCurrencyCode(data),
        lineItemValueInPounds: getGQLLineItemValueInPounds(data),
        isAmountLocked: getGQLIsAmountLocked(data),
        selectedPaymentType: getGQLSelectedPaymentType(data),
        isRecurringOrDirectDebit: getGQLIsRecurringOrDirectDebit(data),
        recurrenceDayOfMonth: getGQLRecurrenceDayOfMonth(data),
        recurrenceNextPaymentDate: getGQLRecurrenceNextPaymentDate(data),
        estimatedExchangeRate: cryptoDetails?.estimatedExchangeRate,
        currencyShortName: cryptoDetails?.currencyShortName,
      };
    },
  });

  const nextDonationDate = useMemo(() => humanizedDate(recurrenceNextPaymentDate ?? null), [recurrenceNextPaymentDate]);
  const isCryptoSelected = selectedPaymentType === PaymentType.Crypto;
  const cryptoAmount = isCryptoSelected ? calculateCryptoAmount(lineItemValueInPounds, estimatedExchangeRate!) : null;

  if (lineItemValueInPounds && currency) {
    return (
      <AccordionPageFilledStep
        title={isAmountLocked ? t('label', { ns: 'fixedAmount' }) : t('amount|title', { ns: 'ab' })}
        routeTo={'AmountPage'}
        a11yRouteText={t('summary|amountEditScreenReader', { ns: 'amount' })}
        lockChange={isAmountLocked}
      >
        {isCryptoSelected && (
          <span>
            {cryptoAmount} {currencyShortName}
          </span>
        )}
        <span className={classNames({ [sprinkles({ color: 'light', display: 'block' })]: isCryptoSelected })}>
          {formatCurrency(currency, lineItemValueInPounds)}{' '}
        </span>
        {isRecurringOrDirectDebit && (
          <>
            ({t('monthly', { ns: 'recurring' })})
            <div className={sprinkles({ color: 'light' })}>
              {t('summary|monthlyDonationDetails', {
                dayOfMonth: RECURRENCE_DAYS_OF_MONTH.find(({ value }) => value === recurrenceDayOfMonth)?.label,
                date: nextDonationDate,
                ns: 'recurring',
              })}
            </div>
          </>
        )}
      </AccordionPageFilledStep>
    );
  }

  return <AccordionPageNewStep>{t('amount|title', { ns: 'ab' })}</AccordionPageNewStep>;
};

const AmountPageSection: FunctionComponent<PageSectionProps> = ({ children, currentPageCollapsed, isLoading }) => {
  const isCurrentPage = useMatch(`/:pageType?/:pageSlug?/${PAGES.AmountPage}`);

  if (isLoading) return <AccordionPageLoadingStep />;

  if (isCurrentPage) {
    return (
      <li>
        <AccordionPageSectionWrapper>
          {currentPageCollapsed && (
            <AccordionPagePreviewEmerge>
              <AmountStepPreview />
            </AccordionPagePreviewEmerge>
          )}
          <AccordionPageCurrentStep collapsed={currentPageCollapsed}>{children}</AccordionPageCurrentStep>
        </AccordionPageSectionWrapper>
      </li>
    );
  }

  return (
    <li>
      <AmountStepPreview />
    </li>
  );
};

export default AmountPageSection;
