import { useEffect } from 'react';

import { useFetchCheckoutQuery } from '../../redux/checkoutApiSlice/queryCheckoutApi';
import { getGQLRedirectUrl, getGQLReferrer } from '../../redux/checkoutApiSlice/selectors/additionalData.selectors';
import { getGQLDirectDebitMandateReference } from '../../redux/checkoutApiSlice/selectors/payment.selectors';
import { getGQLTransactionId } from '../../redux/checkoutApiSlice/selectors/transaction.selectors';
import { getValidUrl } from '../../utils/url';

export default function useRedirect(donationStatus: DonationStatus) {
  const { redirectUrl, referrer, transactionId, directDebitMandateReference } = useFetchCheckoutQuery(undefined, {
    selectFromResult: ({ data }) => {
      return {
        redirectUrl: getGQLRedirectUrl(data),
        referrer: getGQLReferrer(data),
        transactionId: getGQLTransactionId(data),
        directDebitMandateReference: getGQLDirectDebitMandateReference(data),
      };
    },
  });

  useEffect(() => {
    const donationId = transactionId ?? directDebitMandateReference;

    if (donationStatus === 'pending' || !redirectUrl || !donationId) return;

    if (referrer === 'kilter' || donationStatus !== 'invalid') {
      window.location.assign(getValidUrl(redirectUrl).replace(/JUSTGIVING-DONATION-ID/g, donationId));
    }
  }, [transactionId, donationStatus, redirectUrl, referrer, directDebitMandateReference]);
}
