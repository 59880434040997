import { useContext } from 'react';

import { OptimizelyContext, OptimizelyDecideOption } from '@optimizely/react-sdk';

import {
  AcceptedCurrencyCode,
  Checkout,
  LineItemType,
  MarketCode,
  PaymentType,
} from '../../../../types/__generated-graphQL__';
import { getAbTestKey, getAbTestOverrides } from '../../config/abTests';
import { HACK_getGQLIsViralPageBySlug } from '../../config/hacks';
import { getGQLCheckoutMode, getGQLReferrer } from '../../redux/checkoutApiSlice/selectors/additionalData.selectors';
import {
  getGQLDisplayGiftAid,
  getGQLLegacyCharityId,
  getGQLSavedOrDefaultCurrencyCode,
} from '../../redux/checkoutApiSlice/selectors/complex.selectors';
import {
  getGQLAbTestUserId,
  getGQLIsAmountLocked,
} from '../../redux/checkoutApiSlice/selectors/customAttributes.selectors';
import {
  getGQLCauseTipScheme,
  getGQLMarketCode,
  getGQLOrderType,
} from '../../redux/checkoutApiSlice/selectors/lineItem.selectors';
import {
  getGQLTipAmountInPounds,
  getGQLTotalAmountInPounds,
} from '../../redux/checkoutApiSlice/selectors/order.selectors';
import { getGQLSelectedPaymentType } from '../../redux/checkoutApiSlice/selectors/payment.selectors';
import { getDeviceTypeFromUserAgent } from '../../utils/getDeviceTypeFromUserAgent';
import { getLoggedOutGuid } from '../../utils/getLoggedOutGuid';
import { mapTipSchemeToLinkServiceFormat } from '../../utils/utilityMapperFunctions';
import { getIsGoCardlessInstantBankPaymentOutcome } from '../PayWithBank/payWithBank.utils';

// Optimizely Attributes must never be undefined - empty string should be used as a fallback when necessary
type OptimizelyAttributes = {
  causeType: LineItemType | '';
  tipScheme: string;
  marketCode: MarketCode | '';
  deviceType: DeviceType | '';
  charityId: string;
  currencyCode: AcceptedCurrencyCode;
  amount: number;
  tipValue: number;
  selectedPaymentType: PaymentType | '';
  isGiftAidSupported: boolean;
  checkoutMode: string;
  checkoutReferrer: string;
};

export const useSetAbTests = () => {
  const { optimizely } = useContext(OptimizelyContext);

  const setAbTests = async (data?: Checkout) => {
    const attributes: OptimizelyAttributes = {
      causeType: getGQLOrderType(data) ?? '',
      tipScheme: mapTipSchemeToLinkServiceFormat(getGQLCauseTipScheme(data)) ?? '',
      marketCode: getGQLMarketCode(data) ?? '',
      deviceType: getDeviceTypeFromUserAgent(),
      charityId: getGQLLegacyCharityId(data) ?? '',
      currencyCode: getGQLSavedOrDefaultCurrencyCode(data),
      amount: getGQLTotalAmountInPounds(data),
      tipValue: getGQLTipAmountInPounds(data),
      selectedPaymentType: getGQLSelectedPaymentType(data) ?? '',
      isGiftAidSupported: getGQLDisplayGiftAid(data),
      checkoutMode: getGQLCheckoutMode(data) ?? '',
      checkoutReferrer: getGQLReferrer(data) ?? '',
    };

    const isViralPage = HACK_getGQLIsViralPageBySlug(data);

    const isAmountLocked = getGQLIsAmountLocked(data);
    const referrer = getGQLReferrer(data);

    if (referrer === 'pingdom') return;

    const optimizelyUserId = getGQLAbTestUserId(data) || getLoggedOutGuid() || '';

    optimizely?.setUser({ id: optimizelyUserId, attributes });
    await optimizely?.onReady();

    if (getIsGoCardlessInstantBankPaymentOutcome()) return;

    const abTestOverrides = getAbTestOverrides();
    for (const experimentKey in abTestOverrides) {
      optimizely?.setForcedVariation(experimentKey, optimizelyUserId, abTestOverrides[experimentKey]);
    }

    const testKeys = [] as string[];
    if (!isAmountLocked && !isViralPage) testKeys.push(getAbTestKey({ abTest: 'TIP_JAR_DEFAULT_18' }));

    optimizely?.decideForKeys(testKeys, [OptimizelyDecideOption.ENABLED_FLAGS_ONLY]);
  };

  return { setAbTests };
};
