import { gql } from 'graphql-tag';

export const checkoutDetails = gql`
  fragment checkoutDetails on Checkout {
    __typename
    id
    checkoutId
    userLocale
    transactingUser {
      id
      transactingUserId
      type
      savedCards {
        id
        paymentCardId
        nameOnCard
        lastFourDigits
        expiryMonth
        expiryYear
        saved
        disabled
        cardType
        billingAddress {
          id
          line1
          line2
          townCity
          countyStateRegion
          country
          postalCode
        }
        points {
          conversionRate
          isEligible
          totalPoints
        }
        requires3DS
      }
    }
    additionalData
    customAttributes {
      currency
      tipScheme
      message
      skipGiftAid
      lockAmount
      skipMessage
      suggestedPaymentType
      successUrl
      redirectOnSuccess
      abTestUserId
    }
    transaction {
      id
      transactionId
      reference
      isComplete
      startedTime
    }
    giftAid {
      id
      taxPayerDetails {
        firstName
        lastName
        selectionHash
        address {
          id
          line1
          line2
          townCity
          countyStateRegion
          country
          postalCode
        }
      }
      ratePercent
      isClaimed
      isExternalGiftAidProviderEnabled
      isMatchedInExternalGiftAidProvider
    }
    order {
      id
      supportedCurrencies {
        GBP {
          __typename
          supportedPaymentTypes
          supportedCardTypes
          supportedPointsSchemes
          suggestedAmounts {
            value
            description
          }
          default
          minimum
          maximum
        }
        USD {
          __typename
          supportedPaymentTypes
          supportedCardTypes
          supportedPointsSchemes
          suggestedAmounts {
            value
            description
          }
          default
          minimum
          maximum
        }
        AUD {
          __typename
          supportedPaymentTypes
          supportedCardTypes
          supportedPointsSchemes
          suggestedAmounts {
            value
            description
          }
          default
          minimum
          maximum
        }
        CAD {
          __typename
          supportedPaymentTypes
          supportedCardTypes
          supportedPointsSchemes
          suggestedAmounts {
            value
            description
          }
          default
          minimum
          maximum
        }
        EUR {
          __typename
          supportedPaymentTypes
          supportedCardTypes
          supportedPointsSchemes
          suggestedAmounts {
            value
            description
          }
          default
          minimum
          maximum
        }
        NZD {
          __typename
          supportedPaymentTypes
          supportedCardTypes
          supportedPointsSchemes
          suggestedAmounts {
            value
            description
          }
          default
          minimum
          maximum
        }
      }
      currency
      appliedTipScheme
      lineItems {
        id
        lineItemId
        value
        name
        image
        type
        details {
          __typename
          ... on DonationItemDetails {
            cause {
              id
              causeId
              sourceRef
              marketCode
              settlementCurrency
              tipScheme
              supportsGiftAid
              isBbms
              displayHideAmountOption
              supportsRecurringPayments
              sourceUrl
              supportsGuest
              voluntaryContributionConfig {
                __typename
                defaultValue
                from
                to
                valueType
                values
              }
              isExternalGiftAidProviderEnabled
            }
            isAmountPrivate
            isRecurring
            recurrenceDayOfMonth
            recurrenceNextPaymentDate
            displayName
            isAnonymous
            message
            page {
              # Only put necessary for base checkout rendering values in here, everything that can be lazy loaded should go in additionalPageDataApiSlice
              type
              endDate
              occasion {
                type
              }
              slug
              title
              subtitle
              summary
              owner {
                name
              }
              targetWithCurrency {
                value
                currencyCode
              }
            }
          }
        }
      }
      tipAmount
      totalAmount
      fees {
        platformFeePercentage
      }
      voluntaryContributionConfig {
        __typename
        defaultValue
        from
        to
        valueType
        values
      }
      requiresFullBillingAddress
      supportedFeatures {
        crypto
        guest
        giftAid
      }
    }
    payment {
      id
      type
      details {
        __typename
        ... on PaymentCard {
          id
          paymentCardId
          nameOnCard
          lastFourDigits
          expiryMonth
          expiryYear
          saved
          disabled
          cardType
          billingAddress {
            id
            line1
            line2
            townCity
            countyStateRegion
            country
            postalCode
          }
          requires3DS
          points {
            conversionRate
            isEligible
            totalPoints
          }
        }
        ... on DirectDebit {
          id
          accountHolderName
          sortCode
          accountNumber
          dayOfMonth
          nextPaymentDate
          mandateReference
          cancelWhenCauseExpires
          address {
            line1
            line2
            postalCode
            countyStateRegion
            country
            townCity
          }
        }
        ... on Crypto {
          id
          currencyLogoUrl
          currencyFullName
          currencyShortName
          estimatedExchangeRate
        }
      }
    }
  }
`;
