import { useDecision } from '@optimizely/react-sdk';

import { abTestKeys, getAbTestKey } from '../config/abTests';
import { HACK_getGQLIsViralPageBySlug } from '../config/hacks';
import { useFetchCheckoutQuery } from '../redux/checkoutApiSlice/queryCheckoutApi';
import { getGQLIsAmountLocked } from '../redux/checkoutApiSlice/selectors/customAttributes.selectors';

export const useTipJarAbTest = () => {
  const { isAmountLocked, isViralPage } = useFetchCheckoutQuery(undefined, {
    selectFromResult: ({ data }) => {
      return {
        isAmountLocked: getGQLIsAmountLocked(data),
        isViralPage: HACK_getGQLIsViralPageBySlug(data),
      };
    },
  });

  const tipjarAbTestKey = 'TIP_JAR_DEFAULT_18';

  if (!abTestKeys[tipjarAbTestKey].trackInHeap) throw new Error('Tipjar test not measuring awareness in Heap');

  return useDecision(getAbTestKey({ abTest: tipjarAbTestKey, shouldEnrol: !isAmountLocked && !isViralPage }));
};
