import React, { FunctionComponent, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AmountPageFormValues } from '../../../../types/formValues';
import { sprinkles } from '../../../styles/sprinkles.css';
import { useFetchCheckoutQuery } from '../../redux/checkoutApiSlice/queryCheckoutApi';
import {
  getGQLIsDonorPaysFeesEnabled,
  getGQLisTipJarEnabled,
} from '../../redux/checkoutApiSlice/selectors/lineItem.selectors';
import { getGQLPlatformFeePercentage } from '../../redux/checkoutApiSlice/selectors/order.selectors';
import { formatCurrency } from '../../utils/value';
import { calculateTotalAmount } from '../DonorPaysFees/donorPaysFees.utils';

export const TotalAmountToPay: FunctionComponent = () => {
  const { platformFeePercentage, isDonorPaysFeesEnabled, isTipJarEnabled } = useFetchCheckoutQuery(undefined, {
    selectFromResult: ({ data }) => {
      return {
        platformFeePercentage: getGQLPlatformFeePercentage(data),
        isDonorPaysFeesEnabled: getGQLIsDonorPaysFeesEnabled(data),
        isTipJarEnabled: getGQLisTipJarEnabled(data),
      };
    },
  });

  const { t } = useTranslation();
  const { setValue, register } = useFormContext<AmountPageFormValues>();
  const [donationValue, totalAmountToPay, donationCurrencyCode, isDonorPayingFees, tipAmount] = useWatch<
    AmountPageFormValues,
    ['donationValue', 'totalAmountToPay', 'donationCurrencyCode', 'isDonorPayingFees', 'tipAmount']
  >({ name: ['donationValue', 'totalAmountToPay', 'donationCurrencyCode', 'isDonorPayingFees', 'tipAmount'] });

  useEffect(() => {
    if (typeof tipAmount !== 'undefined') {
      const actualTipValue = tipAmount || 0;
      const total = parseFloat(donationValue) + actualTipValue;
      setValue('totalAmountToPay', total);
    } else if (typeof isDonorPayingFees !== 'undefined') {
      const totalAmountToPay = isDonorPayingFees ? calculateTotalAmount(donationValue, platformFeePercentage) : null;
      setValue('totalAmountToPay', totalAmountToPay);
    } else {
      // When tip and dfp inputs are not present then total and donation value should be persisted with the same values
      setValue('totalAmountToPay', parseFloat(donationValue));
    }
  }, [donationValue, isDonorPayingFees, isTipJarEnabled, platformFeePercentage, setValue, tipAmount]);

  const valueOrNaN = totalAmountToPay || parseFloat(donationValue);
  const value = !isNaN(valueOrNaN) ? valueOrNaN : 0;

  return (
    <>
      <input {...register('totalAmountToPay', { valueAsNumber: true })} hidden data-qa="totalAmountToPay" />
      {isDonorPaysFeesEnabled && (
        <div
          className={sprinkles({ marginTop: 'sizeSpacing04', textAlign: 'center', color: 'light' })}
          aria-hidden="true"
          data-qa="total-amount"
          id="donation-total-amount"
        >
          {`${t('totalAmount', { ns: 'tipJar' })} ${formatCurrency(donationCurrencyCode, value)}`}
        </div>
      )}
    </>
  );
};

export default TotalAmountToPay;
