import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import RModal from 'react-modal';

import classNames from 'classnames';

import * as styles from './modal.css';

document.getElementById('donate-app') && RModal.setAppElement('#donate-app');

const Modal: FunctionComponent<ReactModal.Props & { contentLabel: string; modalClassName?: string }> = ({
  children,
  modalClassName,
  onAfterClose,
  ...props
}) => {
  const { t } = useTranslation();

  const preventBodyToScroll = (e: TouchEvent) => e.stopPropagation();

  return (
    <RModal
      {...props}
      overlayClassName={styles.overlay}
      className={classNames(styles.modal, modalClassName)}
      preventScroll={true}
      bodyOpenClassName={styles.bodyModalOpen}
      onAfterOpen={() => {
        // TODO: Review whether RemoveScroll can be used for this instead
        document.body.style.top = `-${window.scrollY}px`;
        document.body.style.position = 'fixed';
        document.body.addEventListener('touchmove', preventBodyToScroll);
      }}
      onAfterClose={() => {
        // TODO: Review whether RemoveScroll can be used for this instead
        const scrollY = document.body.style.top;
        document.body.style.position = '';
        document.body.style.top = '';
        window.scrollTo(0, parseInt(scrollY || '0') * -1);
        document.body.removeEventListener('touchmove', preventBodyToScroll);
        if (onAfterClose) {
          onAfterClose();
        }
      }}
    >
      {children}
      {Boolean(props.onRequestClose) && (
        <button className={styles.closeButton} onClick={props.onRequestClose} title={t('closeModal')}>
          {t('closeModal')}
        </button>
      )}
    </RModal>
  );
};

export default Modal;
