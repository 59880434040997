import { createSelector } from '@reduxjs/toolkit';

import { Checkout, MarketCode, PaymentType, TipScheme } from '../../../../../types/__generated-graphQL__';
import { isDonationDetails, isVoluntaryContributionConfig } from '../../../utils/typeGuards';
import { convertPenniesToPounds } from '../../../utils/utilityMapperFunctions';
import { validateSourcePagePath } from '../../../utils/validateSourcePagePath';
import { getGQLLineItems, getGQLVoluntaryContributionConfig } from './order.selectors';
import { getGQLSelectedPaymentType } from './payment.selectors';

const _getGQLFirstOrderLine = (data?: Checkout) => getGQLLineItems(data)?.[0];

export const getGQLOrderType = (data?: Checkout) => _getGQLFirstOrderLine(data)?.type;

export const getGQLLineItemValueInPounds = (data?: Checkout) =>
  convertPenniesToPounds(_getGQLFirstOrderLine(data)?.value);

export const getGQLDonationItemDetails = (data?: Checkout) => {
  const firstOrderLine = _getGQLFirstOrderLine(data);
  if (isDonationDetails(firstOrderLine?.details)) {
    return firstOrderLine?.details;
  }
  return;
};

export const getGQLPage = (data?: Checkout) => getGQLDonationItemDetails(data)?.page;

const _getGQLCause = (data?: Checkout) => getGQLDonationItemDetails(data)?.cause;

export const getGQLCauseId = (data?: Checkout) => _getGQLCause(data)?.causeId;

export const getGQLSourceUrl = (data?: Checkout) => _getGQLCause(data)?.sourceUrl;

export const getGQLSupportsGuest = (data?: Checkout) => _getGQLCause(data)?.supportsGuest;

export const getGQLSupportsGiftAid = (data?: Checkout) => _getGQLCause(data)?.supportsGiftAid;

export const getGQLCauseSourceRef = (data?: Checkout) => _getGQLCause(data)?.sourceRef;

export const getGQLMarketCode = (data?: Checkout) => _getGQLCause(data)?.marketCode;

export const getGQLCauseTipScheme = (data?: Checkout) => _getGQLCause(data)?.tipScheme;

export const getGQLisTipJarEnabled = createSelector(
  getGQLCauseTipScheme,
  getGQLVoluntaryContributionConfig,
  (causeTipScheme, voluntaryContributionConfig) =>
    isVoluntaryContributionConfig(voluntaryContributionConfig) && causeTipScheme
      ? new RegExp(/TipJar2/).test(causeTipScheme)
      : false,
);

export const getGQLDisplayHideAmountOption = (data?: Checkout) => _getGQLCause(data)?.displayHideAmountOption ?? true;

export const getGQLIsBBGF = (data?: Checkout) => {
  const cause = _getGQLCause(data);
  if (cause?.marketCode === MarketCode.Us && !cause?.isBbms) return true;
  return false;
};

export const getGQLCauseSettlementCurrency = (data?: Checkout) => _getGQLCause(data)?.settlementCurrency;

export const getGQLIsAnonymous = (data?: Checkout) => Boolean(getGQLDonationItemDetails(data)?.isAnonymous);

export const getGQLDonorDisplayName = (data?: Checkout) => getGQLDonationItemDetails(data)?.displayName;

export const getGQLMessage = (data?: Checkout) => getGQLDonationItemDetails(data)?.message;

export const getGQLIsRecurringNotDirectDebit = (data?: Checkout) =>
  Boolean(getGQLDonationItemDetails(data)?.isRecurring) && getGQLSelectedPaymentType(data) !== PaymentType.DirectDebit;

export const getGQLRecurrenceDayOfMonth = (data?: Checkout) => getGQLDonationItemDetails(data)?.recurrenceDayOfMonth;

export const getGQLRecurrenceNextPaymentDate = (data?: Checkout) =>
  getGQLDonationItemDetails(data)?.recurrenceNextPaymentDate;

export const getGQLIsAmountPrivate = (data?: Checkout) => Boolean(getGQLDonationItemDetails(data)?.isAmountPrivate);

export const getGQLIsDonorPaysFeesEnabled = (data?: Checkout) => getGQLCauseTipScheme(data) === TipScheme.Dpf1_2;

export const getGQLSourcePageRoute = (data?: Checkout) => {
  const sourceUrl = getGQLSourceUrl(data);
  const urlParts = sourceUrl?.split('justgiving.com');
  if (urlParts?.length !== 2) return '';
  return validateSourcePagePath(urlParts[1]);
};
