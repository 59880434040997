import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import classNames from 'classnames';

import { MarketCode } from '../../../../types/__generated-graphQL__';
import { AddPaymentCardPageFormValues } from '../../../../types/formValues';
import Input from '../../components/Form/Input/Input';
import Label from '../../components/Form/Label/Label';
import { FormError } from '../../components/FormError/FormError';
import { useFetchCheckoutQuery } from '../../redux/checkoutApiSlice/queryCheckoutApi';
import { getGQLMarketCode } from '../../redux/checkoutApiSlice/selectors/lineItem.selectors';

type Props = {
  className?: string;
};

const PaymentCardHouseNameNumberInput: FunctionComponent<Props> = ({ className }) => {
  const { t } = useTranslation();

  return (
    <div className={classNames(className)}>
      <Label htmlFor="houseNameOrNumber">{t('paymentCard|houseNameOrNumber|label', { ns: 'payment' })}</Label>
      <Input
        name="houseNameOrNumber"
        id="houseNameOrNumber"
        data-qa="houseNameOrNumber"
        registerMethods={{
          setValueAs: value => value?.trim(),
          required: { value: true, message: t('paymentCard|houseNameOrNumber|requiredError', { ns: 'validation' }) },
          maxLength: { value: 50, message: t('paymentCard|houseNameOrNumber|maxLengthError', { ns: 'validation' }) },
        }}
        required
      />
      <FormError<AddPaymentCardPageFormValues> fieldName="houseNameOrNumber" />
    </div>
  );
};

export const PaymentCardHouseNameNumberInputWrapper: FunctionComponent<Props> = props => {
  const { marketCode } = useFetchCheckoutQuery(undefined, {
    selectFromResult: ({ data }) => {
      return {
        marketCode: getGQLMarketCode(data),
      };
    },
  });

  return marketCode === MarketCode.Gb ? <PaymentCardHouseNameNumberInput {...props} /> : null;
};
